import React, { useCallback, useEffect, useState } from "react";
import { PlusSquare } from "lucide-react";
import { useForm } from "../../../helpers/useForm";
import { conceptSchema, serviceSchema } from "../../../helpers/schemas";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { parseCat, parseInvoiceErrors } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { Request } from "../../../helpers/api";
import { clientTypes, enumPaymentMethods } from "../../../helpers/enums";
import iconoPlantillaServices from "../../../css/img/IconoPlantillaServicio.svg";
import DgForm from "../../../components/DgForm";
import DgButton from "../../../components/Button";
import ButtonIcon from "../../../components/ButtonIcon";
import Swal from "sweetalert2";
import DgInput from "../../../components/DgInput";
import { Backdrop, CircularProgress } from "@mui/material";

const ModalRe = ({
  isOpen,
  toggle,
  data: serviceData = serviceSchema,
  fetchData: fetchListData,
}) => {
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...serviceSchema,
    idCatReasonInvoiceCancellation: "2",
    cancellationComments: "",
  });
  const [loading, setLoading] = useState(false);
  const { serviceConcepts: catConcepts, CatReasonInvoiceCancellation } =
    useSelector((s) => s.catalogues);
  const handleNestedChange = (e, ix) => {
    const { value, name } = e.target;
    const arr = data.serviceConcepts;
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    onDataChange({ target: { name: "serviceConcepts", value: arr } });
  };
  const handleNestedItems = (willDelete = false, ix = null) => {
    const arr = data.serviceConcepts;
    if (willDelete) {
      arr.splice(ix, 1);
    } else {
      arr.push({ ...conceptSchema });
    }
    onDataChange({ target: { name: "serviceConcepts", value: arr } });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nameClient, invoice } = serviceData;
    Swal.fire({
      title: "¿Deseas refacturar éste servicio?",
      text: `${nameClient}: ${invoice}`,
      icon: "warning",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        setLoading(true);
        const { objupdateafacturar } = await Request(
          `/invoice/rebill`,
          data,
          "POST"
        );
        setLoading(false);
        const errorsList = [];
        objupdateafacturar.forEach(
          ({ createdPdf, invoiced, remitted, nameService, msgInvoiced }) => {
            const completedInvoice = createdPdf && (invoiced || remitted);
            if (!completedInvoice) {
              errorsList.push({ nameService, msgInvoiced });
            }
          }
        );
        let completedServices = data.length - errorsList.length;
        const allInvoiced = errorsList.length === 0;
        Swal.fire({
          title: allInvoiced
            ? "Refacturación exitosa"
            : "Refacturación fallida",
          html: !allInvoiced
            ? `${errorsList.map(
                (e) =>
                  "<b>" +
                  e.nameService +
                  "</b>" +
                  ": " +
                  parseInvoiceErrors(e.msgInvoiced) +
                  "<br><br>"
              )}`
            : "",
          icon: allInvoiced ? "success" : "warning",
        }).then(() => {
          if (!errorsList.length) {
            fetchListData();
            toggle();
          }
        });
      }
    });
  };
  const getFields = () => {
    let fields = [];
    data.serviceConcepts.forEach((sc, ix) => {
      const onNestedChange = (e) => {
        handleNestedChange(e, ix);
      };
      fields = fields.concat([
        {
          label: "Concepto de factura",
          placeholder: "Concepto de factura",
          type: "select",
          options: [
            { value: "", label: "Elige un concepto" },
            ...parseCat(catConcepts, "name", "idCatServiceConcept"),
          ],
          name: "idCatServiceConcept",
          onInputChange: onNestedChange,
          required: true,
          iconName: "Files",
          containerClassName: "mt-0",
          value: sc.idCatServiceConcept,
          errorMessage: "Éste concepto de factura no es válido",
        },
        {
          label: "Cantidad",
          placeholder: "2",
          minLength: "1",
          maxLength: "2",
          type: "number",
          name: "quantity",
          onInputChange: onNestedChange,
          required: false,
          iconName: "Hash",
          containerClassName: "mt-0",
          value: sc.quantity,
          errorMessage: "Indique una cantidad en el rango 1 a 99",
        },
        {
          label: "Precio base individual",
          type: "number",
          name: "price",
          step: 0.1,
          required: false,
          onInputChange: onNestedChange,
          value: sc.price,
          iconName: "DollarSign",
          containerClassName: "mt-0",
        },
        {
          label: "Descuento por faltas",
          type: "number",
          name: "absenceDiscount",
          step: 0.1,
          min: 0,
          placeholder: "-",
          disabled: false, //!sc.applyAbsence,
          required: true,
          onInputChange: onNestedChange,
          value: sc.absenceDiscount,
          iconName: "UserMinus",
          containerClassName: "mt-0",
          defaultValue: 0,
        },
        {
          label: "Observaciones",
          type: "text",
          name: "description",
          onInputChange: onNestedChange,
          required: false,
          value: sc.description,
          iconName: "Files",
          containerClassName: "mt-0",
        },
        {
          element: (
            <ButtonIcon
              className="text-sesRed px-1"
              onClick={() => handleNestedItems(true, ix)}
              icon={faMinusSquare}
            />
          ),
        },
      ]);
    });
    return [
      {
        groupClassNameGrid: "grid-cols-2",
        fields: [
          {
            label: "Razón social",
            placeholder: "Guardia de seguridad",
            type: "text",
            name: "name",
            value: data.client?.name,
            containerClassName: "mt-0",
            minLength: "5",
            required: true,
            disabled: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
            errorMessage: "Éste nombre comercial no es válido",
          },
          {
            label: "Nombre comercial del servicio",
            placeholder: "Guardia de seguridad",
            type: "text",
            name: "name",
            value: data.name,
            containerClassName: "mt-0",
            minLength: "5",
            required: true,
            disabled: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
            errorMessage: "Éste nombre comercial no es válido",
          },
          {
            label: "Tipo de cliente",
            type: "select",
            name: "name",
            options: [
              {
                label: "Todos",
                value: "",
              },
              ...clientTypes,
            ],
            value: data.client?.clientType,
            containerClassName: "mt-0",
            minLength: "5",
            required: true,
            disabled: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
          },
          {
            label: "Número de factura",
            type: "text",
            name: "invoice",
            value: data.invoice,
            containerClassName: "mt-0",
            minLength: "5",
            required: true,
            disabled: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
          },
          {
            type: "select",
            label: "Método de pago",
            name: "paymentMethod",
            containerClassName: "mt-0",
            value: data.paymentMethod,
            onChange: onDataChange,
            options: [
              { value: "", label: "Selecciona un elemento" },
              ...enumPaymentMethods,
            ],
            iconName: "FormInput",
          },
        ],
      },
      {
        title: "",
        groupClassNameGrid: "grid-cols-6",
        //groupStyles: {gridTemplateColumns: "repeat(5, 1fr) 30px"},
        fields,
      },
    ];
  };
  const fetchData = useCallback(async (idService) => {
    const { ok, data } = await Request("/service/" + idService);
    if (ok) {
      const fetchedService = {
        ...data,
        serviceConcepts: data.serviceConcepts.map((sc) => ({
          ...sc,
          absenceDiscount: sc.absenceDiscount ? sc.absenceDiscount : 0,
        })),
      };
      setAllData({
        ...fetchedService,
        idCatReasonInvoiceCancellation: "2",
        cancellationComments: "",
      });
    }
  }, []);
  useEffect(() => {
    if (serviceData.idService && isOpen) {
      fetchData(serviceData.idService);
    }
  }, [serviceData]);
  return (
    <Modal size="xl" isOpen={isOpen} style={{ maxWidth: "90%" }}>
      <ModalHeader toggle={toggle}>
        <div
          style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}
        >
          REFACTURAR
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "3rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={iconoPlantillaServices} style={{ width: "5rem" }} />
            <div
              style={{
                marginLeft: "1rem",
                fontWeight: 600,
                color: "#2A2F99",
              }}
            >
              {serviceData.nameService}
            </div>
          </div>
          <div
            onClick={() => {
              handleNestedItems();
            }}
          >
            <PlusSquare
              fill="#2A2F99"
              color="white"
              size={35}
              style={{ display: "inline" }}
            />
            Agregar otro concepto
          </div>
        </div>
        <DgForm
          data={data}
          classNameGrid="grid grid-cols-2 gap-4"
          onChange={onDataChange}
          onSubmit={handleSubmit}
          groups={getFields()}
        >
          <ModalFooter>
            <div className="w-full grid grid-cols-2">
              <div>
                <DgInput
                  type="select"
                  label="Motivo de cancelación"
                  options={[
                    { value: "", label: "Elige un Motivo" },
                    ...parseCat(
                      CatReasonInvoiceCancellation,
                      "name",
                      "idCatReasonInvoiceCancellation"
                    ),
                  ]}
                  name="idCatReasonInvoiceCancellation"
                  value={data.idCatReasonInvoiceCancellation}
                  onChange={onDataChange}
                  required={true}
                  iconName={"XCircle"}
                />
                <DgInput
                  type="textarea"
                  label="Comentarios u observaciones de cancelación"
                  placeholder={"..."}
                  name="cancellationComments"
                  value={data.cancellationComments}
                  onChange={onDataChange}
                  iconName={"XCircle"}
                />
              </div>
            </div>
            <div className="w-full grid grid-cols-2">
              <div />
              <div className="grid grid-cols-2 gap-3">
                <DgButton type="submit" color="primary">
                  Refacturar
                </DgButton>
                <DgButton onClick={toggle} color="secondary">
                  Cerrar
                </DgButton>
              </div>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Modal>
  );
};
export default ModalRe;
