import React, { useEffect, useState } from "react";
import DgForm from "../../components/DgForm";
import Skinpage from "../../components/Skinpage";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import { useForm } from "../../helpers/useForm";
import { accountSchema, clientSchema } from "../../helpers/schemas";
import { Request } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientInputs } from "../../helpers/formUtils";
import { formatPhone, getGeolocationData, parseCat } from "../../helpers/utils";
import { Minus, Plus, Save } from "lucide-react";
import DgInput from "../../components/DgInput";
import FormTitle from "../../components/FormTitle";
import "./ClientInsert.css";

const NewClient = () => {
  // Hooks and vars
  const navigate = useNavigate();
  const {
    CatCfdi,
    CatRegime,
    CatPaymentWay,
    bank: CatBanks,
  } = useSelector((s) => s.catalogues);
  const [suburbs, setSuburbs] = useState([]);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...clientSchema,
  });

  // Logic
  const handleZipChange = async (value) => {
    const {
      localities = [],
      state = "",
      municipality = "",
    } = await getGeolocationData(value, data.country);
    setSuburbs(localities);
    setAllData({
      ...data,
      state,
      municipality,
      suburb: localities.length === 1 ? localities[0].value : data.suburb,
    });
  };

  const handlePhoneChange = async (e) => {
    const { value, name } = e.target;
    onDataChange({ target: { name, value: formatPhone(value) } });
  };

  const handleNestedChange = (ix) => (e) => {
    const { value, name } = e.target;
    const arr = data.clientAccounts;
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    onDataChange({ target: { name: "clientAccounts", value: arr } });
  };

  const manageAccounts = (willDelete, ix) => () => {
    const newAccounts = [...data.clientAccounts];
    if (willDelete) {
      newAccounts.splice(ix, 1);
    } else {
      newAccounts.push({ ...accountSchema });
    }
    setAllData({ ...data, clientAccounts: newAccounts });
  };

  // Requests
  const submitData = async (e) => {
    e.preventDefault();
    const res = await Request("/client", { ...data }, "POST");
    if (res.ok) {
      Swal.fire("Registro existoso", "", "success").then(() => {
        navigate("/clientes");
      });
    }
  };

  useEffect(() => {
    handleZipChange(data.postalCode);
  }, [data.postalCode]);

  return (
    <Skinpage pageTitle={"Alta de Cliente"}>
      <DgForm
        data={data}
        onChange={onDataChange}
        onSubmit={submitData}
        groups={clientInputs(
          data,
          { handlePhoneChange },
          { CatRegime, CatCfdi, CatPaymentWay, suburbs }
        )}
      >
        <div className="grid grid-cols-2">
          <div>
            <FormTitle title="Cuentas bancarias" />
            <table className="ses-client-accounts-table">
              <thead>
                <tr>
                  <th width="40%">Banco</th>
                  <th width="50%">Número de cuenta</th>
                  <th width="10%">
                    <Plus
                      onClick={manageAccounts()}
                      color="black"
                      size={28}
                      className="m-auto"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.clientAccounts?.map((a, ix) => (
                  <tr>
                    <td>
                      <DgInput
                        {...{
                          type: "select",
                          iconName: "Landmark",
                          required: true,
                          options: [
                            { label: "Elige un banco", value: "" },
                            ...parseCat(CatBanks, "name", "idBank"),
                          ],
                          name: "idBank",
                          value: a.idBank,
                          onChange: handleNestedChange(ix),
                        }}
                      />
                    </td>
                    <td>
                      <DgInput
                        {...{
                          type: "text",
                          minLength: 10,
                          maxLength: 25,
                          iconName: "DollarSign",
                          required: true,
                          name: "account",
                          value: a.account,
                          onChange: handleNestedChange(ix),
                        }}
                      />
                    </td>
                    <td>
                      <Minus
                        onClick={manageAccounts(true, ix)}
                        color="black"
                        size={28}
                        className="m-auto"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="grid grid-cols-3">
          <div />
          <div />
          <Button type="submit">Continuar</Button>
        </div>
      </DgForm>
    </Skinpage>
  );
};

export default NewClient;
