import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from "../../../helpers/useForm";
import { noop } from "../../../helpers/utils";
import { Request } from "../../../helpers/api";
import { useState } from "react";
import { invoiceCreditNoteSchema } from "../../../helpers/schemas";
import Button from "../../../components/Button";
import DgForm from "../../../components/DgForm";
import Swal from "sweetalert2";
import ModalDetails from "./ModalDetails";
import { Backdrop, CircularProgress } from "@mui/material";

const ModalCreditNote = ({
  isOpen,
  toggle,
  data: paymentData,
  fetchData = noop,
}) => {
  const [concepts, setConcepts] = useState([]);
  const [balance, setBalance] = useState(0);
  const [data, onDataChange, resetData, setAllData] = useForm(
    invoiceCreditNoteSchema
  );
  const [modalOpenDocs, setModalOpenDocs] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [loading, setLoading] = useState(false);
  const onSubmit = async (e) => {
    e.preventDefault();
    const { amount } = data;
    const { idService } = paymentData;
    if (amount <= balance || true) {
      setLoading(true);
      const res = await Request(
        "/invoice/creditnote",
        {
          idService,
          ...data,
        },
        "POST"
      );
      setLoading(false);
      if (res.ok) {
        Swal.fire("Registro exitoso", "", "success").then(() => {
          const { urlPdf: urlPaymentComplement } = res.objupdateafacturar[0];
          resetData();
          fetchData();
          setSingleData({
            title: "Nota de crédito",
            urlPaymentComplement,
            document: "credito",
          });
          setModalOpenDocs(true);
        });
      }
    }
  };
  const fetchServiceData = async () => {
    const { idService } = paymentData;
    if (idService && idService !== "") {
      const { ok, data } = await Request(
        `/service/conceptsdetail/${idService}`
      );
      if (ok) {
        setConcepts(
          data.serviceConcepts.map((c) => {
            return { ...c, selected: c.hasPaymentService ? true : false };
          })
        );
        setBalance(data.positiveBalance);
      }
    }
  };
  useEffect(() => {
    if (isOpen) {
      setAllData({ idService: paymentData.idService, comments: "" });
      //fetchServiceData();
    }
  }, [paymentData.idService, isOpen]);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={toggle}>Crear Nota de crédito</ModalHeader>
      <ModalBody>
        <div className="m-auto mx-2 mt-0">
          <DgForm
            data={data}
            onChange={onDataChange}
            onSubmit={onSubmit}
            groups={[
              {
                fields: [
                  {
                    type: "number",
                    label: "Monto de la nota",
                    min: "0.1",
                    step: "0.1",
                    required: true,
                    name: "amount",
                    value: data.amount,
                    iconName: "FormInput",
                  },
                  {
                    type: "text",
                    label: "Comentarios",
                    minLength: "1",
                    maxLength: "250",
                    required: false,
                    name: "description",
                    value: data.description,
                    iconName: "FormInput",
                  },
                ],
              },
            ]}
            classNameGrid="grid-cols-1"
          >
            <div className="grid grid-cols-2 gap-3">
              <Button type="submit">Aceptar</Button>
              <Button
                onClick={() => {
                  toggle();
                }}
                color="secondary"
              >
                Cancelar
              </Button>
            </div>
          </DgForm>
        </div>
      </ModalBody>
      <ModalDetails
        isOpen={modalOpenDocs}
        toggle={() => {
          setModalOpenDocs(!modalOpenDocs);
          toggle();
        }}
        data={singleData}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Modal>
  );
};

ModalCreditNote.propTypes = {};

export default ModalCreditNote;
