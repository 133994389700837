import React, { useEffect, useState } from "react";
import DgForm from "../../../components/DgForm";
import Button from "../../../components/Button";
import Swal from "sweetalert2";
import { useForm } from "../../../helpers/useForm";
import { Request } from "../../../helpers/api";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { clientInputs } from "../../../helpers/formUtils";
import {
  capitalize,
  getGeolocationData,
  lowerCaseExceptFirst,
  parseCat,
} from "../../../helpers/utils";
import { accountSchema } from "../../../helpers/schemas";
import FormTitle from "../../../components/FormTitle";
import { Minus, Plus } from "lucide-react";
import DgInput from "../../../components/DgInput";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";

const DetailClientInfo = ({
  clientData,
  setClientAllData,
  loading,
  setLoading,
}) => {
  const [inputType, setInputType] = useState("");
  const { id: idClient } = useParams();
  const [suburbs, setSuburbs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    CatCfdi,
    CatRegime,
    CatPaymentWay,
    bank: CatBanks,
  } = useSelector((s) => s.catalogues);
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...clientData,
  });

  const editClient = async (e) => {
    e.preventDefault();
    const res = await Request(`/client/${idClient}`, data, "PUT");
    if (res.ok) {
      Swal.fire({
        title: "Actualización exitosa",
        icon: "success",
      }).then(() => {
        setLoading(!loading);
        setClientAllData({ ...clientData, ...res.data });
      });
    }
  };

  const manageAccounts = (willDelete, ix) => () => {
    const newAccounts = [...data.clientAccounts];
    if (willDelete) {
      newAccounts.splice(ix, 1);
    } else {
      newAccounts.push({ ...accountSchema });
    }
    setAllData({ ...data, clientAccounts: newAccounts });
  };

  const handlePhoneChange = async (e) => {
    const { value, name } = e.target;
    const clearValue = value.replaceAll("-", "");
    const formattedValue =
      clearValue.length === 10
        ? [
            clearValue.substring(0, 2),
            clearValue.substring(2, 6),
            clearValue.substring(6, 10),
          ].join("-")
        : value;
    onDataChange({ target: { name, value: formattedValue } });
  };

  const handleZipChange = async (value) => {
    const {
      localities = [],
      state = data.state || "",
      municipality = data.municipality || "",
    } = await getGeolocationData(value, data.country);

    setSuburbs(localities);
    setAllData({
      ...data,
      state,
      municipality,
      suburb: localities.length === 1 ? localities[0].value : data.suburb,
    });
  };

  const handleNestedChange = (ix) => (e) => {
    const { value, name } = e.target;
    const arr = data.clientAccounts;
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;

    onDataChange({ target: { name: "clientAccounts", value: arr } });
  };

  useEffect(() => {
    setAllData({ ...clientData, municipality: lowerCaseExceptFirst (data.municipality) });
  }, []);

  useEffect(() => {
    if (inputType === "postalCode") {
      handleZipChange(data.postalCode);
    }
  }, [data.postalCode]);

  return (
    <>
      <div className="titulosDetalle">Editar Datos de Cliente</div>
      <DgForm
        data={data}
        classNameGrid="grid grid-cols-2 gap-4"
        onChange={(e) => {
          onDataChange(e);
          setInputType(e.target.name);
        }}
        onSubmit={editClient}
        groups={clientInputs(
          data,
          { handlePhoneChange },
          { CatRegime, CatCfdi, CatPaymentWay, suburbs }
        )}
      >
        <div className="grid grid-cols-2">
          <div>
            <FormTitle title="Cuentas bancarias" />
            <table className="ses-client-accounts-table">
              <thead>
                <tr>
                  <th width="40%">Banco</th>
                  <th width="50%">Número de cuenta</th>
                  <th width="10%">
                    <Plus
                      onClick={manageAccounts()}
                      color="black"
                      size={28}
                      className="m-auto"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.clientAccounts?.map((a, ix) => (
                  <tr>
                    <td>
                      <DgInput
                        {...{
                          type: "select",
                          iconName: "Landmark",
                          required: true,
                          options: [
                            { label: "Elige un banco", value: "" },
                            ...parseCat(CatBanks, "name", "idBank"),
                          ],
                          name: "idBank",
                          value: a.idBank,
                          onChange: handleNestedChange(ix),
                        }}
                      />
                    </td>
                    <td>
                      <DgInput
                        {...{
                          type: "text",
                          minLength: 10,
                          maxLength: 25,
                          iconName: "DollarSign",
                          required: true,
                          name: "account",
                          value: a.account,
                          onChange: handleNestedChange(ix),
                        }}
                      />
                    </td>
                    <td>
                      <Minus
                        onClick={manageAccounts(true, ix)}
                        color="black"
                        size={28}
                        className="m-auto"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div />
          <div />
          <Button disabled={isLoading} type="submit">
            Actualizar
          </Button>
        </div>
      </DgForm>
    </>
  );
};

export default DetailClientInfo;
