import React, { useState } from "react";
import PropTypes from "prop-types";
import iconoDetalleEmpleado from "../../../css/img/IconoDetalleEmpleado.svg";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DgButton from "../../../components/Button";
import DgInput from "../../../components/DgInput";
import { personSchema } from "../../../helpers/schemas";
import { useForm } from "../../../helpers/useForm";
import { useEffect } from "react";
import { Request } from "../../../helpers/api";
import { noop, parseCat } from "../../../helpers/utils";
import Swal from "sweetalert2";
import { employeeShiftCat } from "../../../helpers/enums";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ModalAssignService = ({
  isOpen,
  toggle = {},
  data: personData,
  fetchData = noop,
}) => {
  const [data, onDataChange, clearData, setAllData] = useForm({
    idCatServicePayroll: "",
    idCatZone: "",
    idCatSupervisor: "",
    shift: "12",
  });
  const {
    catalogues: { CatServicePayroll, CatSupervisor, CatZone },
  } = useSelector((state) => state);
  const navigate = useNavigate();
  const { idEmployee } = personData;

  const onSubmit = async () => {
    const res = await Request(`/employee/${idEmployee}/service`, data, "PUT");
    if (res.ok) {
      Swal.fire("Asignación exitosa", "", "success").then(() => {
        fetchData();
        toggle();
      });
    } else {
      Swal.fire("Los campos no pueden estar vacíos", "", "warning");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="text-center  justify-center">
        <div className="text-xl text-sesGray ml-2">ASIGNAR SERVICIO</div>
      </ModalHeader>
      <ModalBody style={{ padding: "1.5rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={iconoDetalleEmpleado} style={{ width: "3.5rem" }} />
            <div
              style={{
                marginLeft: "1rem",
                fontWeight: 600,
                color: "#2A2F99",
              }}
            >
              {personData.name}
              <br />
              {personData.employeeKey}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "end",
              flexDirection: "column",
            }}
            className="mt-2"
          >
            <div
              style={{
                marginLeft: "0.5rem",
                color: "#565656",
                fontWeight: 600,
              }}
            >
              Servicio actual:
            </div>
            <div
              style={{
                marginLeft: "0.5rem",
                color: "#565656",
                fontWeight: 700,
              }}
            >
              {personData.service}
            </div>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "row", gap: "3%" }}>
          <DgInput
            type="select"
            label="Servicio"
            options={[
              { label: "Elige un Servicio", value: "" },
              ...parseCat(CatServicePayroll, "name", "idCatServicePayroll"),
            ]}
            name="idCatServicePayroll"
            value={data.idCatServicePayroll}
            onChange={onDataChange}
            required={true}
            iconName={"MapPin"}
          />
          <DgInput
            type="select"
            label="Supervisor"
            options={[
              { label: "Elige un Supervisor", value: "" },
              ...parseCat(CatSupervisor, "name", "idCatSupervisor"),
            ]}
            name="idCatSupervisor"
            value={data.idCatSupervisor}
            onChange={onDataChange}
            required={true}
            iconName={"MapPin"}
          />
        </div>
        <div style={{ display: "flex", alignItems: "row", gap: "3%" }}>
          <DgInput
            type="select"
            label="Zona"
            options={[
              { label: "Elige una Zona", value: "" },
              ...parseCat(CatZone, "name", "idZoneTemplate"),
            ]}
            name="idCatZone"
            value={data.idCatZone}
            onChange={onDataChange}
            required={true}
            iconName={"MapPin"}
          />
          <DgInput
            type="select"
            label="Turno"
            options={employeeShiftCat}
            name="shift"
            value={data.shift}
            onChange={onDataChange}
            required={true}
            iconName={"MapPin"}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex space-between w-full mb-4">
          <DgButton
            type="submit"
            color="secondary"
            innerClassName="px-4 py-2 font-regular w-3/4 ml-2"
            onClick={toggle}
          >
            Cancelar
          </DgButton>
          <DgButton
            onClick={onSubmit}
            innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
          >
            Asignar servicio
          </DgButton>
        </div>
        <span
          onClick={() => {
            navigate("/historico-servicios/" + idEmployee);
          }}
          className="py-2 mb-4 font-regular mx-auto text-center text-sesBlue cursor-pointer underline"
        >
          Ver histórico de servicios
        </span>
      </ModalFooter>
    </Modal>
  );
};

ModalAssignService.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalAssignService;
