import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { doubletSchema } from "../../../helpers/schemas";
import { useForm } from "../../../helpers/useForm";
import { cataloguesSelector } from "../../../redux/selectors";
import {
  parseCat,
  parseObjectDate,
  sanitizeDate,
} from "../../../helpers/utils";
import { useSelector } from "react-redux";
import DgButton from "../../../components/Button";
import DgInput from "../../../components/DgInput";
import iconoDetalleEmpleado from "../../../css/img/IconoDetalleEmpleado.svg";
import { TODAY } from "../../../helpers/consts";
import { useEffect } from "react";
import { Request } from "../../../helpers/api";
import Swal from "sweetalert2";
import { useState } from "react";

const ModalDoubletRegister = ({ isOpen, toggle = {}, data: personalData }) => {
  const [services, setServices] = useState([]);
  const { CatReasonPaymentDoubleWork } = useSelector(cataloguesSelector);
  const [data, onDataChange, resetData, setAllData] = useForm({
    ...doubletSchema,
  });

  const { name, employeeKey, idEmployee, serviceName } = personalData;

  const fetchPersonalServices = async () => {
    if (idEmployee && idEmployee !== "") {
      const res = await Request("/employee/");
      if (res.ok) {
        setServices(res.data);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Request(
      `/employee/${idEmployee}/doublework/`,
      {
        ...data,
        doubleWorkDate: parseObjectDate(sanitizeDate(data.doubleWorkDate), 1),
      },
      "POST"
    );
    if (res.ok) {
      Swal.fire("Doblete registrado con éxito", "", "success").then(() => {
        resetData();
        toggle();
      });
    }
  };

  useEffect(() => {
    fetchPersonalServices();
  }, [isOpen, idEmployee]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="text-center  justify-center">
        <div className="text-xl text-sesGray ml-2">REGISTRO DE DOBLETE</div>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody
          style={{ padding: "1.5rem", overflowY: "auto", maxHeight: "520px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={iconoDetalleEmpleado} style={{ width: "3.5rem" }} />
              <div
                style={{
                  marginLeft: "1rem",
                  fontWeight: 600,
                  color: "#2A2F99",
                }}
              >
                {name}
                <br />
                {employeeKey}
              </div>
            </div>
          </div>
          <DgInput
            type="text"
            label="Servicio"
            name="service"
            value={serviceName}
            readOnly={true}
            iconName={"ClipboardList"}
            disabled
          />
          <DgInput
            type="date"
            label="Fecha"
            name="doubleWorkDate"
            value={data.doubleWorkDate}
            onChange={onDataChange}
            max={parseObjectDate(TODAY, 4)}
            required={true}
            iconName={"CalendarDays"}
          />
          <DgInput
            type="select"
            label="Turno laborado"
            options={[
              { label: "Selecciona un elemento", value: "" },
              { label: "12 horas", value: 12 },
              { label: "24 horas", value: 24 },
              { label: "FMF", value: "FMF" },
            ]}
            name="workingDayType"
            value={data.workingDayType}
            onChange={onDataChange}
            required={true}
            iconName={"FileClock"}
          />
          {/* <DgInput
            type="select"
            label="Motivo de pago"
            options={[
              { label: "Selecciona un elemento", value: "" },
              ...parseCat(
                CatReasonPaymentDoubleWork,
                "name",
                "idCatReasonPaymentDoubleWork"
              ),
            ]}
            name="idCatReasonPaymentDoubleWork"
            value={data.idCatReasonPaymentDoubleWork}
            onChange={onDataChange}
            required={true}
            iconName={"ClipboardList"}
          /> */}
          <DgInput
            type="number"
            label="Ayuda de comidas"
            name="foodHelpAmount"
            value={data.foodHelpAmount}
            onChange={onDataChange}
            min={1}
            step={0.1}
            iconName={"Drumstick"}
          />
          <DgInput
            type="textarea"
            label="Comentarios u observaciones"
            placeholder={"..."}
            name="commentDoubleWork"
            value={data.commentDoubleWork}
            onChange={onDataChange}
            iconName={"MessageSquare"}
          />
        </ModalBody>
        <ModalFooter>
          <div className="flex space-between w-full">
            <DgButton
              onClick={toggle}
              color="secondary"
              innerClassName="px-4 py-2 font-regular w-3/4 ml-2"
            >
              Cancelar
            </DgButton>
            <DgButton
              type="submit"
              innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
            >
              Registrar
            </DgButton>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ModalDoubletRegister.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalDoubletRegister;
