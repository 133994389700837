import React from "react";
import PropTypes from "prop-types";

const Title = ({ children = "" }) => {
  return (
    <div style={{ color: "#2A2F99", fontWeight: 700 }}>
      {children.toUpperCase}
    </div>
  );
};

Title.propTypes = {
  children: PropTypes.string,
};

export default Title;
