import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import {
  statusPaymentDoubleWorkSchema,
  statusPaymentRollSchema,
} from "../../../helpers/schemas";
import { useForm } from "../../../helpers/useForm";
import { Request } from "../../../helpers/api";
import { noop, parseCat } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import DgButton from "../../../components/Button";
import DgInput from "../../../components/DgInput";
import Swal from "sweetalert2";
import iconoDetalleEmpleado from "../../../css/img/IconoDetalleEmpleado.svg";

const ModalPaysheetStatus = ({
  isOpen,
  toggle = {},
  withBank = false, // FLAG TO DECIDE FROM EITHER PAYROLL OR DOUBLET, TODO: IMPROVE LOGIC
  data: personData = {},
  fetchData = noop,
}) => {
  const [data, onDataChange, resetData, setAllData] = useForm({
    ...(withBank ? statusPaymentRollSchema : statusPaymentDoubleWorkSchema),
  });
  const { bank } = useSelector((s) => s.catalogues);
  const { employeeName, employeeKey, idEmployeeDoubleWork, idPayroll } =
    personData;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Request(
      `/payroll/${
        withBank
          ? "employee/" + idPayroll
          : "doublework/" + idEmployeeDoubleWork
      }`,
      data,
      "PUT"
    );
    if (res.ok) {
      Swal.fire("Estatus cambiado exitosamente", "", "success").then(() => {
        resetData();
        fetchData();
        toggle();
      });
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="text-center  justify-center">
        <div className="text-xl text-sesGray ml-2">CAMBIAR ESTATUS DE PAGO</div>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody
          style={{ padding: "1.5rem", overflowY: "auto", maxHeight: "560px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={iconoDetalleEmpleado} style={{ width: "3.5rem" }} />
              <div
                style={{
                  marginLeft: "1rem",
                  fontWeight: 600,
                  color: "#2A2F99",
                }}
              >
                {employeeName}
                <br />
                {employeeKey}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
            className="mt-2"
          >
            <div
              style={{
                marginLeft: "0.5rem",
                color: "#2A2F99",
                fontWeight: 600,
              }}
            >
              ESTATUS ACTUAL
            </div>
            {personData.catStatusPaymentPayroll === "PAGADO" ? (
              <div
                style={{
                  marginLeft: "0.5rem",
                  color: "#FFFFFF",
                  fontWeight: "600",
                  backgroundColor: "green",
                }}
                className="px-4 rounded-md mt-1"
              >
                PAGADO
              </div>
            ) : (
              <div
                style={{
                  marginLeft: "0.5rem",
                  color: "#FFFFFF",
                  fontWeight: "600",
                  backgroundColor: "#F88F14",
                }}
                className="px-4 rounded-md mt-1"
              >
                PENDIENTE
              </div>
            )}
          </div>
          <DgInput
            type="select"
            label="Nuevo estatus del pago"
            disabled={true}
            options={[
              { label: "Pagado", value: 2 },
              { label: "Pendiente", value: 1 },
              { label: "Cancelado", value: 3 },
            ]}
            name="idCatStatusPaymentDoubleWork"
            value={data.idCatStatusPaymentDoubleWork}
            onChange={onDataChange}
            required={true}
            iconName={"DollarSign"}
          />
          {withBank ? (
            <DgInput
              type="select"
              label="Banco"
              options={[
                { label: "Selecciona un banco", value: "" },
                ...parseCat(bank, "name", "idBank"),
              ]}
              name="idBank"
              value={data.idBank}
              onChange={onDataChange}
              required={false}
              iconName={"Landmark"}
            />
          ) : null}
          <DgInput
            type="textarea"
            label="Comentarios"
            placeholder={"..."}
            name="commentStatusPaymentPayroll"
            value={data.commentStatusPaymentDoubleWork}
            onChange={onDataChange}
            iconName={"MessageSquare"}
          />
        </ModalBody>
        <ModalFooter>
          <div className="flex space-between w-full mb-4">
            <DgButton
              type="button"
              onClick={toggle}
              color="secondary"
              innerClassName="px-4 py-2 font-regular w-3/4 ml-2"
            >
              Cancelar
            </DgButton>
            <DgButton
              type="submit"
              innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
            >
              Cambiar estatus
            </DgButton>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ModalPaysheetStatus.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  withBank: PropTypes.bool,
  data: PropTypes.object,
  fetchData: PropTypes.func,
};

export default ModalPaysheetStatus;
