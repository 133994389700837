import React from "react";
import PropTypes from "prop-types";

const FormTitle = ({ title }) => {
  return <h2 className="text-sesBlue font-semibold text-lg mt-8">{title}</h2>;
};

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormTitle;
