import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Request } from "../../../helpers/api";
import { getColumnsCreditNotes } from "../../../helpers/datatableHelpers";
import DgButton from "../../../components/Button";
import Swal from "sweetalert2";
import InvoiceDetails from "./ModalDetails";
import DgTable from "../../../components/DgTable";
import "bootstrap/dist/css/bootstrap.min.css";

const ModalServiceCreditNotes = ({
  isOpen,
  toggle,
  data: serviceData,
  fetchData: fetchInvData,
}) => {
  const [payments, setPayments] = useState([]);
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const [singleData, setSingleData] = useState({});
  const fetchData = useCallback(async (idService) => {
    const { ok, data } = await Request("/service/creditnotes/" + idService);
    if (ok) {
      setPayments(data);
    }
  }, []);
  const handleDelete = (service) => {
    const { idCreditNoteService } = service;
    Swal.fire({
      title: "¿Deseas cancelar ésta nota?",
      text: `Ésta acción es irreversible`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok } = await Request(
          `/invoice/creditnote/${idCreditNoteService}`,
          {},
          "PATCH"
        );
        if (ok) {
          Swal.fire("Cancelación exitosa", "", "success").then(() => {
            fetchData(serviceData.idService);
            fetchInvData();
          });
        }
      }
    });
  };

  const dtFuncs = {
    paymentDocument: (row) => () => {
      setSingleData({
        ...row.paymentComplement,
        urlPaymentComplement: row.urlCreditNote,
        document: "payment-complement",
      });
      setModalOpenInvoice(true);
    },
    cancelCreditNote: (row) => () => {
      handleDelete(row);
    },
  };
  useEffect(() => {
    if (serviceData.idService && isOpen) {
      fetchData(serviceData.idService);
    }
  }, [serviceData]);
  return (
    <>
      <Modal size="lg" isOpen={isOpen}>
        <ModalHeader toggle={toggle}>
          <div
            style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}
          >
            Notas de crédito
          </div>
        </ModalHeader>
        <ModalBody style={{ padding: "3rem" }}>
          <DgTable
            columnsDef={getColumnsCreditNotes(dtFuncs)}
            data={payments}
          />
          <div className="w-full grid grid-cols-2 gap-4">
            <div />
            <DgButton color="secondary" onClick={toggle}>
              Cerrar
            </DgButton>
          </div>
        </ModalBody>
      </Modal>
      <InvoiceDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
        }}
        data={singleData}
      />
    </>
  );
};
export default ModalServiceCreditNotes;
