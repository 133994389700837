import React, { useState } from "react";
import DgInput from "../../../components/DgInput";
import { useForm } from "../../../helpers/useForm";
import DocumentCard from "../../../components/DocumentCard";
import "../../../App.css";
import { personSchema } from "../../../helpers/schemas";
import { getOperatingYearsCat } from "../../../helpers/utils";
import { monthsEs } from "../../../helpers/enums";
import Swal from "sweetalert2";
import ModalDocumentPersonDetail from "../modals/ModalDocumentPersonDetail";
import { Request } from "../../../helpers/api";
import { useSelector } from "react-redux";

const PersonIncidences = ({ data: personData, setAllPersonData }) => {
  let incidences = personData.employeeIncidences || [];
  const { CatEmployeeIncidence } = useSelector((s) => s.catalogues);
  const [isOpen, setIsOpen] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...personSchema,
  });

  const handlers = {
    handleDelete: (row, ix) => {
      Swal.fire({
        title: "¿Seguro que deseas eliminar ésta incidencia?",
        text: row.name,
        icon: "warning",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const { ok } = await Request(
            `/employee/incidence/${row.idEmployeeIncidence}`,
            { isActive: false },
            "PATCH"
          );
          if (ok) {
            Swal.fire("Incidencia eliminado con éxito", "", "success").then(
              () => {
                const newItems = [...incidences];
                newItems.splice(ix, 1);
                setAllPersonData({
                  ...personData,
                  employeeIncidences: newItems,
                });
              }
            );
          }
        }
      });
    },
    // handleDetail: (row) => {
    //   setSingleData({ ...row, url: row.urlDocumentIncidence });
    //   setIsOpen(true);
    // },
  };

  const mapIncidenceText = (data) => {
    const foundIncidence =
      CatEmployeeIncidence.find(
        (ci) => ci.idCatEmployeeIncidence === data.idCatEmployeeIncidence
      ) || {};
    return foundIncidence.name + " - $" + data.amountEmployeeIncidence;
  };

  return (
    <div className="grid grid-cols-2 gap-x-5 gap-y-0 mt-4">
      <div>
        <div
          className="ml-2 text-sesBlue"
          style={{ fontWeight: 500, fontSize: "1.1rem" }}
        >
          INCIDENCIAS POR PERIODO
        </div>
        <div className="personIncidencesFilters grid gap-2">
          {/* <DgInput
                        type="select"
                        label="Filtrar por periodo"
                        name="incidencePeriod"
                        options={[
                            { label: "Primera quincena", value: 1 },
                            { label: "Segunda quincena", value: 2 },
                        ]}
                        onChange={onDataChange}
                        iconName={"CalendarDays"}
                    /> */}
          <DgInput
            type="select"
            label="Año"
            name="incidenceYear"
            onChange={onDataChange}
            options={[{ label: "Todos", value: "" }, ...getOperatingYearsCat()]}
            className="pl-5"
          />
          <DgInput
            type="select"
            label="Mes"
            name="incidenceMonth"
            onChange={onDataChange}
            options={[{ label: "Todos", value: "" }, ...monthsEs]}
            className="pl-5"
          />
        </div>
        {personData.employeeIncidences?.map((item, index) => (
          <DocumentCard
            document={{
              name: `Incidencia #${item.idCatEmployeeIncidence}`,
              description: mapIncidenceText(item),
              ...item,
            }}
            handlers={handlers}
            ix={index}
          />
        ))}
      </div>
      <ModalDocumentPersonDetail
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        data={singleData}
      />
    </div>
  );
};

export default PersonIncidences;
