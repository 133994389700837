import { type } from "@testing-library/user-event/dist/type";
import { Request } from "./api";
import { googleGeoBaseApi, googleGeoKey, TODAY } from "./consts";
import { operatingYear } from "./enums";

export const noop = () => {};

export const parseCat = (arr, strTxt, strVal) => {
  if (arr) {
    return arr.map((n) => {
      return {
        label: n[strTxt],
        value: n[strVal],
      };
    });
  }
  return [];
};

export const sanitizeDate = (date) => {
  return date ? new Date(date.split("T")[0] + "T12:00:00") : null;
};

export const parseObjectDate = (date, format = 1) => {
  const monthsEs = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  let r;
  switch (format) {
    case 1: // DD/MM/YYYY
      r = [
        date.getDate().toString().padStart(2, "0"),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getFullYear(),
      ].join("/");
      break;
    case 2: // Dia Mes YY
      r = [
        date.getDate().toString().padStart(2, "0"),
        monthsEs[date.getMonth()],
        date.getFullYear().toString().substring(2, 4) + "'",
      ].join(" ");
      break;

    case 3: // DD/MM/YYYY
      r = [
        date.getDate().toString().padStart(2, "0"),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getFullYear(),
      ].join("/");
      break;
    case 4: // YYYY-MM-DD
      r = [
        date.getFullYear(),
        (date.getMonth() + 1).toString().padStart(2, "0"),
        date.getDate().toString().padStart(2, "0"),
      ].join("-");
      break;
    default:
  }
  return r;
};

export const getAge = (dateStr) => {
  var today = new Date();
  var birthDate = new Date(dateStr);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const downloadBlobFile = (blobRes, fileName = "untitled.txt") => {
  if (blobRes) {
    const url = window.URL.createObjectURL(new Blob([blobRes]));
    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.href = url;
    link.click();
  }
};

export const formatPhone = (phone) => {
  const clearValue = phone.replaceAll("-", "");
  const formattedValue =
    clearValue.length === 10
      ? [
          clearValue.substring(0, 2),
          clearValue.substring(2, 6),
          clearValue.substring(6, 10),
        ].join("-")
      : phone;
  return formattedValue;
};

export const getGeolocationData = async (zipCode, country) => {
  if (zipCode.length > 4) {
    const res = await (
      await fetch(
        `${googleGeoBaseApi}?address=${zipCode},${country}&key=${googleGeoKey}&language=es`,
        {
          method: "GET",
          redirect: "follow",
        }
      )
    ).json();
    if (res.status === "OK") {
      const { address_components = [], postcode_localities: localities = [] } =
        res.results[0];
      const state = address_components.find((ac) =>
        ac.types.find((t) => t === "administrative_area_level_1")
      );
      const municipality = address_components.find((ac) => {
        return ac.types.find((t) => /sublocality/.test(t));
      });

      return {
        address_components,
        localities: localities.map((l) => ({ label: l, value: l })),
        state: state ? state.long_name : null,
        municipality: municipality ? municipality.long_name : null,
      };
    }
  }
  return { address_components: [], localities: [], state: null, suburb: null };
};

export const searchInObjectArray = (searchValue, searchArray, searchFields) => {
  const searchLower = searchValue.toLowerCase();
  const filteredData = [];
  searchArray.forEach((d) => {
    let found = false;
    searchFields.forEach((k) => {
      const val = d[k] ? d[k].toLowerCase() : "";
      if (val.indexOf(searchLower) !== -1) {
        found = true;
      }
    });
    if (found) {
      filteredData.push(d);
    }
  });
  return filteredData;
};

export const getColorPayment = (pId) => {
  let color;
  switch (pId) {
    case 1:
      color = "#F88F14";
      break;
    case 2:
      color = "#C82B2B";
      break;
    case 3:
      color = "#0CBE13";
      break;
    case 5:
      color = "#C82B2B";
      break;
    case 6:
      color = "#DCD40A";
      break;
    default:
      color = "#000";
      break;
  }
  return color;
};

export const getColorInvoice = (pId) => {
  let color;
  switch (pId) {
    case 1:
      color = "#F88F14";
      break;
    case 2:
      color = "#30B6FF";
      break;
    case 3:
      color = "#30B6FF";
      break;
    case 4:
      color = "#C82B2B";
      break;
    default:
      color = "#000";
  }
  return color;
};

export const getColorPaymentComplement = (pId) => {
  let color;
  switch (pId) {
    case 1:
      color = "#333333";
      break;
    case 2:
      color = "#F88F14";
      break;
    case 3:
      color = "#30B6FF";
      break;
    default:
      color = "#000";
  }
  return color;
};

export const getOperatingYearsCat = () => {
  let r = [];
  for (let i = operatingYear; i <= TODAY.getFullYear(); i++) {
    r.push({ y: i });
  }
  return parseCat(r, "y", "y");
};

export const triggerPdfDownload = (urls = []) => {
  urls.forEach((fileURL) => {
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    } else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileURL);
      _window.close();
    }
  });
};

export const downloadBlobRes = async (url) => {
  const res = await Request(url, null, "GET", false, false, true);
  if (res) {
    downloadBlobFile(res, "Factura.pdf");
  }
};

export const parseInvoiceErrors = (errOb) => {
  let strRes;
  if (typeof errOb === "string") {
    strRes = errOb;
  } else {
    const { Message, ModelState } = errOb;
    strRes = Message;
    if (ModelState && typeof ModelState === "object") {
      Object.keys(ModelState).forEach((k) => {
        const obProp = ModelState[k];
        if (typeof obProp === "string") {
          strRes += ", " + obProp;
        } else if (Array.isArray(obProp)) {
          obProp.forEach((p) => {
            if (typeof p) {
              strRes += ", " + p;
            }
          });
        }
      });
    }
  }
  return strRes;
};

export const getPermissionsList = (selector) => {
  const {
    auth: {
      data: { idCatRole },
    },
    catalogues: { RoleActionsPermissions = [] },
  } = selector((s) => s);

  return RoleActionsPermissions.filter((p) => p.idCatRole === idCatRole).map(
    (p) => p.idCatAction
  );
};

export const searchArrayInArray = (needle, seed) => {
  let res = false;
  needle.forEach((n) => {
    res = res || seed.includes(n);
  });
};

export const jToFormData = (json) => {
  const r = new FormData();
  Object.keys(json).forEach((k) => {
    r.append(k, json[k]);
  });
  return r;
};

export const objetctToQueryStr = (ob = {}) => {
  return Object.keys(ob)
    .map((k) => `${k}=${ob[k]}`)
    .join("&");
};

export const getPermissions = (permissionsArray) => {
  const finalPermsArray = [];
  if (permissionsArray) {
    permissionsArray.forEach((permission) => {
      if (permission.visibility) {
        finalPermsArray.push(permission.idCatAction);
      }
    });
  }
  return finalPermsArray;
};

export const permissionCheck = (permissionsArray, permissionName) => {
  const filteredPermission = permissionsArray?.filter(
    (permission) => permission.name === permissionName
  );
  const visibility = filteredPermission[0]?.visibility;

  return visibility;
};

export const lowerCaseExceptFirst = (string)=> {
  const words = string.split(" ");

  const result = words.map(word => {
    if (word.length > 2) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    } else {
      return word.toLowerCase();
    }
  });

  return result.join(" ");
}