import React, { useState } from "react";
import PropTypes from "prop-types";
import iconoDetalleEmpleado from "../../../css/img/IconoDetalleEmpleado.svg";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import DgButton from "../../../components/Button";
import DgInput from "../../../components/DgInput";
import { compensationSchema, personSchema } from "../../../helpers/schemas";
import { useForm } from "../../../helpers/useForm";
import { Plus } from "lucide-react";
import { useSelector } from "react-redux";
import { cataloguesSelector } from "../../../redux/selectors";
import {
  jToFormData,
  noop,
  parseCat,
  parseObjectDate,
  sanitizeDate,
} from "../../../helpers/utils";
import { TODAY } from "../../../helpers/consts";
import { Request } from "../../../helpers/api";
import { compensationsCat } from "../../../helpers/enums";
import Swal from "sweetalert2";

const ModalCompensations = ({ isOpen, toggle = noop, data: personData }) => {
  const [file, setFile] = useState(null);
  const [data, onDataChange, resetData, setAllData] = useForm({
    ...compensationSchema,
  });

  const handleFileSelected = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { idEmployee } = personData;
    const res = await Request(
      `/employee/${idEmployee}/compensation`,
      {
        ...data,
        employeeCompensationDate: parseObjectDate(
          sanitizeDate(data.employeeCompensationDate)
        ),
      },
      "POST"
    );
    if (res.ok) {
      Swal.fire("Registro exitoso", "", "success").then(() => {
        resetData();
        toggle();
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="text-center  justify-center">
        <div className="text-xl text-sesGray ml-2">
          REGISTRO DE COMPENSACIONES
        </div>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody
          style={{ padding: "1.5rem", overflowY: "auto", maxHeight: "560px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={iconoDetalleEmpleado} style={{ width: "3.5rem" }} />
              <div
                style={{
                  marginLeft: "1rem",
                  fontWeight: 600,
                  color: "#2A2F99",
                }}
              >
                {personData.name}
                <br />
                {personData.employeeKey}
              </div>
            </div>
          </div>

          <DgInput
            type="select"
            label="Tipo de compensación"
            options={[
              { label: "Selecciona un elemento", value: "" },
              ...compensationsCat,
            ]}
            value={data.employeeCompensationType}
            name="employeeCompensationType"
            onChange={onDataChange}
            required={true}
            iconName={"ClipboardList"}
          />
          <DgInput
            type="date"
            label="Fecha"
            name="employeeCompensationDate"
            value={data.employeeCompensationDate}
            max={parseObjectDate(TODAY, 4)}
            onChange={onDataChange}
            required={true}
            iconName={"CalendarDays"}
          />
          <DgInput
            type="number"
            label="Monto del compensación"
            name="employeeCompensationAmount"
            value={data.employeeCompensationAmount}
            min={1}
            step={0.1}
            onChange={onDataChange}
            required={true}
            iconName={"Coins"}
          />
          <DgInput
            type="textarea"
            label="Comentarios u observaciones"
            placeholder="..."
            name="commentEmployeeCompensation"
            value={data.commentEmployeeCompensation}
            onChange={onDataChange}
            iconName="MessageSquare"
          />
          {/* <div className="text-sesBlue text-left text-base p-2 font-bold h-9 mt-2">
            <input
              onChange={handleFileSelected}
              type="file"
              className="fileLinkModal cursor-pointer"
            />
            <div className="textFileModal">
              <div className="w-6 h-6 rounded-md bg-sesBlue relative float-left mr-2 cursor-pointer">
                <Plus
                  color="#FFFFFF"
                  size={22}
                  style={{ cursor: "pointer" }}
                  className="m-auto"
                />
              </div>
              Adjuntar archivo (opcional) <br />
              {file ? file.name : ""}
            </div>
          </div> */}
        </ModalBody>
        <ModalFooter>
          <div className="flex space-between w-full">
            <DgButton
              type="button"
              onClick={toggle}
              color="secondary"
              innerClassName="px-4 py-2 font-regular w-3/4 ml-2"
            >
              Cancelar
            </DgButton>
            <DgButton
              type="submit"
              innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
            >
              Registrar
            </DgButton>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ModalCompensations.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalCompensations;
