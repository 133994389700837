import React, { useCallback, useEffect, useState } from "react";
import DgForm from "../../../components/DgForm";
import { useForm } from "../../../helpers/useForm";
import Button from "../../../components/Button";
import { conceptSchema, serviceSchema } from "../../../helpers/schemas";
import ButtonIcon from "../../../components/ButtonIcon";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { PlusSquare } from "lucide-react";
import { Request } from "../../../helpers/api";
import "./NewService.css";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { getGeolocationData, parseCat } from "../../../helpers/utils";
import { useParams } from "react-router-dom";
import { addressInputs } from "../../../helpers/formUtils";

const NewService = ({setLoading}) => {
  const [fiscalPeriodList, setFiscalPeriodList] = useState([]);

  const { id: idClient } = useParams();
  const { serviceConcepts: catConcepts, catRegions: regions } = useSelector(
    (s) => s.catalogues
  );
  const [data, onDataChange, clearData, setAllData] = useForm({
    ...serviceSchema,
  });
  const [suburbs, setSuburbs] = useState([]);

  const handleZipChange = async (value) => {
    const {
      localities = [],
      state: stateService = "",
      municipality: municipalityService = "",
    } = await getGeolocationData(value, data.countryService);
    

    setSuburbs(localities);
    setAllData({
      ...data,
      stateService,
      municipalityService,
      suburbService:
        localities.length === 1 ? localities[0].value : data.suburbService,
    });
  };

  const fetchFiscalPeriods = useCallback(async () => {
    const res = await Request(`/user/fiscalPeriods?limit=4&all=false`);
    if (res.ok) {
      setFiscalPeriodList(res.data);
    }
  }, []);

  const handleNestedChange = (e, ix) => {
    const { value, name } = e.target;
    const arr = data.serviceConcepts;
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    onDataChange({ target: { name: "serviceConcepts", value: arr } });
  };

  const handleNestedItems = (willDelete = false, ix = null) => {
    const arr = data.serviceConcepts;
    if (willDelete) {
      arr.splice(ix, 1);
    } else {
      arr.push({ ...conceptSchema });
    }
    onDataChange({ target: { name: "serviceConcepts", value: arr } });
  };

  const getFields = () => {
    const getConceptFields = () => {
      let fields = [];
      data.serviceConcepts.forEach((sc, ix) => {
        const onNestedChange = (e) => {
          handleNestedChange(e, ix);
        };
        fields = fields.concat([
          {
            label: "Concepto de factura",
            placeholder: "Concepto de factura",
            type: "select",
            options: [
              { value: "", label: "Elige un concepto" },
              ...parseCat(catConcepts, "name", "idCatServiceConcept"),
            ],
            name: "idCatServiceConcept",
            required: true,
            iconName: "Files",
            value: sc.idCatServiceConcept,
            onInputChange: onNestedChange,
            errorMessage: "Éste concepto de factura no es válido",
          },
          {
            label: "Cantidad",
            placeholder: "2",
            minLength: "1",
            maxLength: "2",
            type: "number",
            name: "quantity",
            required: false,
            iconName: "Hash",
            value: sc.quantity,
            onInputChange: onNestedChange,
            errorMessage: "Indique una cantidad en el rango 1 a 99",
          },
          {
            label: "Descripción",
            type: "text",
            name: "description",
            required: false,
            value: sc.description,
            onInputChange: onNestedChange,
            iconName: "Files",
          },
          {
            label: "Precio base individual por servicio",
            type: "money",
            name: "price",
            required: false,
            value: sc.price,
            onInputChange: onNestedChange,
            iconName: "DollarSign",
          },
          {
            element: (
              <ButtonIcon
                className="px-1"
                onClick={() => handleNestedItems(true, ix)}
                icon={faMinusSquare}
              />
            ),
          },
        ]);
      });
      return fields;
    };

    return [
      {
        fields: [
          {
            label: "Nombre comercial del servicio",
            placeholder: "Guardia de seguridad",
            type: "text",
            name: "name",
            value: data.name,
            minLength: "5",
            required: true,
            iconName: "ClipboardList",
            errorMessage: "Éste nombre comercial no es válido",
          },
          {
            label: "Periodicidad del pago",
            placeholder: "Periodicidad del pago",
            type: "select",
            name: "paymentPeriod",
            value: data.paymentPeriod,
            options: [
              { label: "Selecciona un elemento", value: "" },
              { label: "Pago Quincenal", value: "BIWEEKLY" },
              { label: "Mensual", value: "MONTHLY" },
            ],
            required: true,
            iconName: "DollarSign",
            errorMessage: "Seleccione una opción",
          },
          {
            label: "Periodo inicial",
            placeholder: "Periodo inicial",
            type: "select",
            name: "idFiscalPeriod",
            value: data.idFiscalPeriod,
            required: true,
            options: [
              { value: "", label: "Selecciona un elemento" },
              ...parseCat(fiscalPeriodList, "name", "idFiscalPeriod"),
            ],
            iconName: "Calendar",
            errorMessage: "Seleccione una opción",
          },
        ],
      },
      {
        title: "CONCEPTOS",
        withAdd: true,
        addElement: (
          <div
            onClick={() => {
              handleNestedItems();
            }}
          >
            <PlusSquare
              fill="#2A2F99"
              color="white"
              size={35}
              style={{ display: "inline" }}
            />
            Agregar otro concepto
          </div>
        ),
        groupClassNameGrid: "grid-cols-5 conceptsList",
        fields: getConceptFields(),
      },
      {
        title: "DOMICILIO",
        fields: [
          ...addressInputs(data, suburbs, "Service"),
          {
            label: "Región",
            placeholder: "Región",
            type: "select",
            name: "idCatRegion",
            required: true,
            value: data.idCatRegion,
            options: [
              { value: "", label: "Elige una región" },
              ...parseCat(regions, "name", "idCatRegion"),
            ],
            iconName: "MapPin",
          },
        ],
      },
    ];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Request("/service", { ...data, idClient }, "POST");
    if (res.ok) {
      Swal.fire("Registro exitoso", "", "success").then(() => {});
      setLoading(false)
    }
  };

  useEffect(() => {
    handleZipChange(data.postalCodeService);
  }, [data.postalCodeService]);

  useEffect(() => {
    fetchFiscalPeriods();
  }, []);

  return (
    <>
      <div className="titulosDetalle">Alta de Servicio</div>
      <DgForm
        data={data}
        classNameGrid="grid grid-cols-3 gap-4"
        onChange={onDataChange}
        onSubmit={handleSubmit}
        groups={getFields()}
      >
        <div className="grid grid-cols-3 gap-4">
          <div />
          <div />
          <Button type="submit">Dar de Alta</Button>
        </div>
      </DgForm>
    </>
  );
};

export default NewService;
