import React from "react";

function DgContainer({ children, className, style }) {
  return (
    <div
      style={{ ...styles.modalDataContainer, ...style }}
      className={className}
    >
      {children}
    </div>
  );
}

export default DgContainer;

const styles = {
  modalDataContainer: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    minWidth: "40%",
    width: "fit-content",
    background: "lavender",
    borderRadius: "20px",
    alignItems: "center",
    gap: "5px",
  },
};
