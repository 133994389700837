import React, { useCallback, useEffect, useState } from "react";
import { getColumnsUsers } from "../../helpers/datatableHelpers";
import { useNavigate } from "react-router-dom";
import { Request } from "../../helpers/api";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import DgTable from "../../components/DgTable";
import ModalUserDetails from "./AdminUserDetails";
import ModalPermissions from "./ModalPermissions";
import { useForm } from "../../helpers/useForm";

const UserList = (/*{isLoading, setIsLoading}*/) => {
  const navigate = useNavigate();
  const [modalOpenUserDetails, setModalOpenUserDetails] = useState(false);
  const [modalPermissions, setModalPermissions] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useForm({
    userName: "",
    role: "",
  });
  const patchActive = async (idUser, newStatus) => {
    await Request(`/user/${idUser}`, { idCatStatusUser: newStatus }, "PATCH");
    fetchData();
  };

  const activeChange = (row) => async (e) => {
    const newStatus = e.target.checked ? "1" : "2";
    patchActive(row.idUser, newStatus);
  };
  const funcs = {
    edit: (row) => () => {
      setSingleData(row);
      setModalOpenUserDetails(true);
      
    },
    block: (row) => () => {
      
      const { name, idUser } = row;
      Swal.fire({
        title: `¿Deseas bloquear a ${name}?`,
        text: "Ésta acción es irreversible",
        icon: "warning",
        showCancelButton: true,
      }).then(({ isConfirmed }) => {
        if (isConfirmed) {
          patchActive(idUser, 3);
        }
      });
    },
    activeChange,
    modalPermissions: (row) => {
      setModalPermissions(true);
    },
  };
  const handleSearch = (field, value) => {
    setSearch(value);
    const searchLower = value.toLowerCase();
    const filteredData = [];
    const searchFields = field === "all" ? ["rol", "email", "name"] : [field];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const fetchData = useCallback(async () => {
    const { ok, data } = await Request(`/user`);
    
    if (ok) {
      setAllData(data);
      setData(data);
    }
  }, []);
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Skinpage pageTitle={"LISTA DE USUARIOS"}>
      <div className="grid grid-cols-4 gap-3" style={{ alignItems: "end" }}>
        <DgInput
          type="text"
          label="Búsqueda"
          onChange={(e) => {
            handleSearch("all", e.target.value);
          }}
          value={search}
          placeholder="Buscar usuario"
          iconName="Search"
        />
        <div />
        <div />
        <Button
          style={{ margin: "0.5rem" }}
          onClick={() => {
            navigate("/nuevo-usuario");
          }}
        >
          Nuevo usuario
        </Button>
      </div>
      <DgTable
        data={data}
        columnsDef={getColumnsUsers(funcs)}
        className="mt-8 tableHeaderPersonal"
      />

      <ModalUserDetails
        isOpen={modalOpenUserDetails}
        toggle={() => {
          setModalOpenUserDetails(!modalOpenUserDetails);
        }}
        data={singleData}
        // isLoading={isLoading}
        // setIsLoading={setIsLoading}
      />
      <ModalPermissions
        isOpen={modalPermissions}
        toggle={() => {
          setModalPermissions(!modalPermissions);
        }}
      />
    </Skinpage>
  );
};

export default UserList;
