import * as icons from "lucide-react";

const InputIcon = ({ name, color, size }) => {
  const LucideIcon = icons[name];

  return (
    <LucideIcon color={color} size={size} className="iconInput ml-2 mr-4" />
  );
};

export default InputIcon;
