import React, { useCallback, useEffect, useState } from "react";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import DgTable from "../../components/DgTable";
import { getColumnsClients } from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import ModalPlantillaServices from "./ModalPlantillaServices";
import { downloadBlobFile } from "../../helpers/utils";
import { useForm } from "../../helpers/useForm";
import { clientTypes } from "../../helpers/enums";

const ListClient = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [filters, setFilters] = useForm({
    paymentStatus: "",
    clientType: "",
  });

  const [singleData, setSingleData] = useState({});
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const funcs = {
    service: () => {},
    details: ({ idClient }) => {
      navigate("/detalle-cliente/" + idClient);
    },
    serviceDetails: (row) => {
      setSingleData(row);
      setModalOpen(true);
    },
    mail: (row) => {
      Swal.fire({
        title: "Estado de cuenta",
        //showDenyButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Enviar",
        //denyButtonText: `Descargar reporte`,
        confirmButtonColor: "#7066e0",
        denyButtonColor: "#7066e0",
        cancelButtonColor: "#d33",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await Request(
            "/report/sendAccountStatusEmail/" + row.idClient
          );
          if (res.ok) {
            Swal.fire("El reporte se envio por correo", "", "success");
          }
        } else if (result.isDenied) {
          // const res = await Request(
          //   "/report/serviceDetail/" + row.idService,
          //   null,
          //   "GET",
          //   true,
          //   false,
          //   true
          // );
          // if (res) {
          //   downloadBlobFile(res, `Detalle de Servicio.xlsx`);
          // }
        }
      });
    },
  };
  const handleSearch = (field, value) => {
    setSearch(value);
    const searchLower = value.toLowerCase();
    const filteredData = [];
    const searchFields =
      field === "all"
        ? ["businessName", "serviceNumber", "tradeName"]
        : [field];
    allData.forEach((d) => {
      let found = false;
      searchFields.forEach((k) => {
        const val = d[k] ? d[k].toLowerCase() : "";
        if (val.indexOf(searchLower) !== -1) {
          found = true;
        }
      });
      if (found) {
        filteredData.push(d);
      }
    });
    setData(filteredData);
  };
  const fetchData = useCallback(async (filters) => {
    const res = await Request(`/client/service`);
    if (res.ok) {
      const allClients = res.data;
      setData(allClients);
      setAllData(allClients);
    }
  }, []);
  useEffect(() => {
    fetchData({});
  }, [fetchData]);

  useEffect(() => {
    const filteredClientsList = allData.filter((client) => {
      return (
        (!filters.clientType || filters.clientType === client.clientType) &&
        (!filters.paymentStatus ||
          filters.paymentStatus === client.catStatusPaymentService)
      );
    });
    setData(filteredClientsList);
  }, [filters.clientType, filters.paymentStatus]);

  return (
    <Skinpage pageTitle={"Seguimiento de Clientes"}>
      <div className="grid grid-cols-4 gap-5" style={{ alignItems: "end" }}>
        <DgInput
          type="text"
          label="Búsqueda"
          onChange={(e) => {
            handleSearch("all", e.target.value);
          }}
          value={search}
          placeholder="Buscar cliente"
          iconName="Search"
        />
        <DgInput
          type="select"
          label="Saldo"
          options={[
            {
              label: "Todos",
              value: "",
            },
            {
              label: "Pendiente",
              value: "Pendiente",
            },
            {
              label: "Al corriente",
              value: "Pagado",
            },
            {
              label: "Vencido",
              value: "Vencido",
            },
          ]}
          onChange={setFilters}
          name="paymentStatus"
          value={filters.paymentStatus}
          iconName="DollarSign"
        />
        <DgInput
          type="select"
          label="Tipo de cliente"
          options={[
            {
              label: "Todos",
              value: "",
            },
            ...clientTypes,
          ]}
          onChange={setFilters}
          name="clientType"
          value={filters.clientType}
          iconName="Users"
        />
        <Button
          onClick={() => {
            navigate("/inicio");
          }}
        >
          Nuevo Cliente
        </Button>
      </div>
      <br />
      <DgTable data={data} columnsDef={getColumnsClients(funcs)} />
      <ModalPlantillaServices
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
        singleData={singleData}
      />
    </Skinpage>
  );
};

export default ListClient;
