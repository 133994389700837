import React, { useState } from "react";
import DgInput from "../../../components/DgInput";
import { personSchema } from "../../../helpers/schemas";
import { useForm } from "../../../helpers/useForm";
import { Document, Page } from "react-pdf";
import DocumentCard from "../../../components/DocumentCard";
import "../../../App.css";
import ModalDocumentPersonDetail from "../modals/ModalDocumentPersonDetail";
import DgInputFile from "../../../components/DgInputFile";
import Swal from "sweetalert2";
import { Request } from "../../../helpers/api";

const PersonDocuments = ({ data: personData, setAllPersonData }) => {
  let documents = personData.employeeDocuments || [];
  const [singleData, setSingleData] = useState({});
  const [modalDocumentPersonDetail, setModalDocumentPersonDetail] =
    useState(false);

  const docHandlers = {
    handleDetail: (doc) => {
      setSingleData(doc);
      setModalDocumentPersonDetail(true);
    },
    handleDelete: (doc, ix) => {
      const { idEmployee } = personData;
      const { idEmployeeDocument } = doc;
      Swal.fire({
        title: "¿Desas cancelar éste documento?",
        text: "Ésta acción es irreversible",
        icon: "warning",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request(
            "/employee/document/" + idEmployeeDocument,
            {},
            "PATCH"
          );
          if (res.ok) {
            Swal.fire("Documento cancelado", "", "success").then(() => {
              const newDocs = [...documents];
              newDocs.splice(ix, 1);
              setAllPersonData({ ...personData, documents: newDocs });
            });
          }
        }
      });
    },
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-x-5 gap-y-0 mt-4">
        <div>
          <div
            className="ml-2 text-sesBlue"
            style={{ fontWeight: 500, fontSize: "1.1rem" }}
          >
            DOCUMENTOS DE EMPLEADO
          </div>
          {/* <DgInputFile
            type="text"
            placeholder="Buscar archivo"
            name="searchFile"
            onChange={onDataChange}
            iconName={"Search"}
          />*/}
          {documents.length > 0 ? (
            documents.map((doc, ix) => (
              <DocumentCard
                document={{ ...doc, name: doc.employeeDocumentType }}
                handlers={docHandlers}
                ix={ix}
              />
            ))
          ) : (
            <i>No hay documentos para mostrar</i>
          )}
        </div>
      </div>
      <ModalDocumentPersonDetail
        isOpen={modalDocumentPersonDetail}
        data={singleData}
        toggle={() => {
          setModalDocumentPersonDetail(!modalDocumentPersonDetail);
        }}
      />
    </>
  );
};

export default PersonDocuments;
