import React, { useState } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import Skinpage from "../../components/Skinpage";
import classnames from "classnames";
import AccountState from "./client-reports-tabs/AccountState";
import CollectionProjection from "./client-reports-tabs/CollectionProjection";
import Payments from "./client-reports-tabs/Payments";
import "./CasingReports.css";

const CashingReports = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabs = [
    { name: "Estado de cuenta por cliente", component: <AccountState /> },
    // { name: "Relación de pagos", component: <Payments /> },
    // { name: "Proyección de cobranza", component: <CollectionProjection /> },
  ];
  return (
    <Skinpage pageTitle={"Reportes de Cobranza"}>
      <Nav tabs>
        {tabs.map((t, ix) => (
          <NavItem>
            <NavLink
              className={classnames(
                {
                  active: activeTab === ix,
                },
                `text-md font-semibold text-sesBlue sesTab ${
                  activeTab === ix ? "activeSesTab" : ""
                }`
              )}
              onClick={() => {
                setActiveTab(ix);
              }}
            >
              {t.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabs.map((t, ix) => (
          <TabPane tabId={ix}>{t.component}</TabPane>
        ))}
      </TabContent>
    </Skinpage>
  );
};

CashingReports.propTypes = {};

export default CashingReports;
