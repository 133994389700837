import React, { useEffect, useState } from "react";
import { Request } from "../../helpers/api";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { parseCat, parseInvoiceErrors } from "../../helpers/utils";
import ClientCard from "../../components/ClientCard";
import Skinpage from "../../components/Skinpage";
import DgInput from "../../components/DgInput";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import ModalDetails from "./modals/ModalDetails";
import Loader from "../../components/Loader";
import { enumPaymentMethods } from "../../helpers/enums";

const InvoiceAnticipate = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const { serviceConcepts: catConcepts } = useSelector((s) => s.catalogues);
  const [idFiscalPeriod, setIdFiscalPeriod] = useState("");
  const { state: services = [] } = useLocation();
  const [fiscalPeriodList, setFiscalPeriodList] = useState([]);
  const fetchData = useCallback(async (ix, ac) => {
    const newAc = [...ac];
    const s = services[ix];
    if (s) {
      let service = {};
      const res = await Request("/service/" + s.idService);
      if (res.ok) {
        const { data } = res;
        const { serviceConcepts } = data;
        service = {
          ...data,
          serviceConcepts: serviceConcepts.map((sc) => ({
            ...sc,
            absenceDiscount: sc.absenceDiscount || 0,
          })),
        };
        const resC = await Request("/client/" + s.idClient);
        if (resC.ok) {
          service.client = resC.data;
          service.paymentMethod = resC.data.paymentMethod;
          newAc.push(service);
          const newIx = ix + 1;
          if (newIx < services.length) {
            fetchData(newIx, newAc);
          } else {
            setData(newAc);
          }
        }
      }
    }
  }, []);
  const fetchFiscalPeriods = useCallback(async () => {
    const { ok, data } = await Request(`/user/fiscalPeriods?limit=4&all=false`);
    if (ok) {
      setFiscalPeriodList(data);
    }
  }, []);
  const onNestedChange = (sIx, ix) => (e) => {
    const { value, name } = e.target;
    const newData = [...data];
    const service = newData[sIx];
    const item = service.serviceConcepts[ix];
    item[name] = value;
    newData[sIx] = service;
    setData(newData);
  };
  const onChange = (ix) => (e) => {
    const newData = [...data];
    const service = newData[ix];
    service[e.target.name] = e.target.value;
    setData(newData);
  };
  const createSelector = (c, ix) => {
    return (
      <DgInput
        type="select"
        name="paymentMethod"
        containerClassName={"my-0"}
        value={c.paymentMethod}
        onChange={onChange(ix)}
        options={[
          { value: "", label: "Selecciona un elemento" },
          ...enumPaymentMethods,
        ]}
        iconName="FormInput"
      />
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await Request(
      "/invoice/advanceinvoice",
      {
        idFiscalPeriod,
        invoices: data.map(({ idService, serviceConcepts }) => ({
          idService,
          serviceConcepts,
        })),
      },
      "POST"
    );
    setIsLoading(false);
    const { objupdateafacturar } = res;
    const errorsList = [];
    objupdateafacturar.forEach(
      ({ createdPdf, invoiced, remitted, nameService, msgInvoiced }) => {
        const completedInvoice = createdPdf && (invoiced || remitted);
        if (!completedInvoice) {
          errorsList.push({ nameService, msgInvoiced });
        }
      }
    );
    let completedServices = data.length - errorsList.length;
    const allInvoiced = errorsList.length === 0;
    Swal.fire({
      title: allInvoiced ? "Facturación exitosa" : "Facturación incompleta",
      html: !allInvoiced
        ? `${errorsList.map(
            (e) =>
              "<b>" +
              e.nameService +
              "</b>" +
              ": " +
              parseInvoiceErrors(e.msgInvoiced) +
              "<br><br>"
          )}`
        : "",
      icon: allInvoiced ? "success" : "warning",
    }).then(() => {
      if (completedServices === 1 && completedServices === data.length) {
        setCompleted(true);
        setSingleData(objupdateafacturar[0]);
        setModalOpenInvoice(true);
      } else {
        navigate("/facturacion");
      }
    });
  };
  useEffect(() => {
    fetchFiscalPeriods();
  }, []);
  useEffect(() => {
    fetchData(0, []);
  }, [fetchData]);
  return (
    <Skinpage pageTitle={"Adelantar factura"}>
      <div className="relative top-8">
        <form className="w-full" onSubmit={handleSubmit}>
          <div className="grid">
            <div className="pl-8">
              <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
                PERIODO
              </h2>
              {/*<DgInput
                {...{
                  type: "radio",
                  options: [
                    { value: "MONTHLY", label: "Mensual" },
                    { value: "BYWEEKLY", label: "Quincenal" },
                  ],
                  onNestedChange,
                  containerClassName:
                    "col-span-2 -top-4 relative radioButtonsInvoice",
                }}
              />*/}
              <DgInput
                {...{
                  type: "select",
                  options: [
                    { value: "", label: "Seleccione un periodo" },
                    ...parseCat(fiscalPeriodList, "name", "idFiscalPeriod"),
                  ],
                  onChange: (e) => {
                    setIdFiscalPeriod(e.target.value);
                  },
                  name: "idFiscalPeriod",
                  placeholder: "Seleccionar periodo",
                  iconName: "Calendar",
                  containerClassName:
                    "w-2/5 mx-4 relative float-left pr-12 mb-8 mt-0",
                }}
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gridInvoiceAnticipate">
            <div className="pl-8">
              <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
                CLIENTES A FACTURAR ANTICIPADAMENTE
              </h2>
            </div>
            <div className="mx-4">
              <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
                CONCEPTOS
              </h2>
            </div>
          </div>

          <div className="grid grid-cols-2 gridInvoiceAnticipate">
            {data.map((s, sIx) => (
              <>
                <ClientCard
                  md="12"
                  client={s.client}
                  bottomComponent={createSelector(s, sIx)}
                />
                <div>
                  {s.serviceConcepts?.map((sc, ix) => (
                    <div className="grid grid-cols-4 gap-x-4 gap-y-px mx-4 relative -top-2">
                      <DgInput
                        {...{
                          label: "Concepto de factura",
                          placeholder: "Concepto de factura",
                          type: "select",
                          options: [
                            { value: "", label: "Elige un concepto" },
                            ...parseCat(
                              catConcepts,
                              "name",
                              "idCatServiceConcept"
                            ),
                          ],
                          name: "idCatServiceConcept",
                          required: true,
                          iconName: "Files",
                          onInputChange: onNestedChange,
                          value: sc.idCatServiceConcept,
                          errorMessage: "Éste concepto de factura no es válido",
                        }}
                      />
                      <DgInput
                        {...{
                          label: "Cantidad",
                          placeholder: "2",
                          minLength: "1",
                          maxLength: "2",
                          type: "number",
                          required: true,
                          iconName: "Hash",
                          value: sc.quantity,
                          onInputChange: onNestedChange,
                          errorMessage:
                            "Indique una cantidad en el rango 1 a 99",
                        }}
                      />
                      <DgInput
                        {...{
                          label: "Precio base",
                          type: "money",
                          required: true,
                          value: sc.price,
                          onInputChange: onNestedChange,
                          iconName: "DollarSign",
                          containerClassName: "relative",
                        }}
                      />
                      <DgInput
                        {...{
                          label: "Observaciones",
                          type: "text",
                          disabled: false,
                          name: "...",
                          required: false,
                          onInputChange: onNestedChange(sIx, ix),
                          value: sc.description,
                          iconName: "FormInput",
                        }}
                      />
                    </div>
                  ))}
                </div>
              </>
            ))}
          </div>

          <div className="grid grid-cols-4 gap-4 mx-8 gridInvoiceAnticipateButton">
            <div />
            <div />
            <div />
            <Button
              disabled={isLoading || completed}
              type="submit"
              innerClassName="relative right-16"
            >
              Adelantar factura
            </Button>
          </div>
        </form>
      </div>
      <ModalDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
          navigate("/facturacion");
        }}
        data={{ ...singleData, urlInvoice: singleData.urlPdf }}
      />
      <Loader isLoading={isLoading} />
    </Skinpage>
  );
};

InvoiceAnticipate.propTypes = {};

export default InvoiceAnticipate;
