import React, { useCallback, useEffect, useState } from "react";
import Skinpage from "../../components/Skinpage";
import Grid from "@mui/material/Grid";
import {
  PlusCircle,
  ClipboardList,
  Paperclip,
  Edit,
  MessageSquare,
  Mail,
  Landmark,
} from "lucide-react";
import iconoDetalle from "../../css/img/IconoDetalleCliente.svg";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import NewService from "./client-detail-tabs/NewService";
import ListServices from "./client-detail-tabs/ServicesList";
import Documents from "./client-detail-tabs/Documents";
import DetailClientInfo from "./client-detail-tabs/DetailClientInfo";
import Comments from "./client-detail-tabs/Comments";
import SesTabPanel from "../../components/SesTabPanel";
import ClientSendEmail from "./client-detail-tabs/ClientSendEmail";
import { useNavigate, useParams } from "react-router-dom";
import { Request } from "../../helpers/api";
import { clientDetailSchema } from "../../helpers/schemas";
import { parseObjectDate } from "../../helpers/utils";
import { Tooltip } from "@mui/material";
import ClientDeposit from "./client-detail-tabs/ClientDeposit";

const DetailClient = () => {
  const { id: idClient } = useParams();
  const [data, setData] = useState(clientDetailSchema);
  const [loading, setLoading] = useState(true);
  const [loadingComment, setLoadingComment] = useState(true);

  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchData = useCallback(async () => {
    if (idClient) {
      const res = await Request("/client/" + idClient);
      if (res.ok) {
        setData(res.data);
      }
    } else {
      navigate.goBack();
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, loadingComment]);

  useEffect(() => {
    fetchData();
  }, [fetchData, loading]);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  return (
    <Skinpage>
      <div style={{ color: "#2A2F99", fontWeight: 700 }}>
        DETALLE DE CLIENTE
      </div>
      <Grid container spacing={2} style={{ marginTop: "0.3vw" }}>
        <Grid item xs={6}>
          <div
            style={{
              width: "100%",
              height: "18rem",
              boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
              border: "solid 0.5px lightgrey",
              borderRadius: "1vw",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "3rem",
                backgroundColor: "#2A2F99",
                borderTopLeftRadius: "1vw",
                borderBottomLeftRadius: "1vw",
              }}
            ></div>
            <Grid
              container
              spacing={2}
              style={{
                height: "fit-content",
                marginTop: "0.01rem",
                marginLeft: "0.01rem",
              }}
            >
              <Grid item xs={4} style={{ padding: 0 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "1vw",
                  }}
                >
                  {/* <Skeleton variant="circular" width={80} height={80} /> */}
                  <img src={iconoDetalle} style={{ width: "7rem" }} />
                </div>
              </Grid>
              <Grid
                item
                xs={8}
                style={{ padding: 0, display: "flex", alignItems: "center" }}
              >
                <div
                  style={{
                    color: "#2A2F99",
                    padding: "1rem",
                    fontSize: "1.2rem",
                    fontWeight: 500,
                  }}
                >
                  {data.name || " - "}
                </div>
              </Grid>
              <Grid item xs={6} style={{ padding: "1rem" }}>
                <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
                  RFC: {data.rfc || " - "}
                </div>
                <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}></div>
                <div
                  style={{
                    fontWeight: 500,
                    fontSize: "0.9rem",
                    textAlign: "justify",
                  }}
                >
                  {`${data.street}, ${data.outdoorNumber} ${data.internalNumber}, Col: ${data.suburb}, C.P. ${data.postalCode}, ${data.municipality}, ${data.state}`}
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ padding: "1rem", borderLeft: "solid 2px lightgrey" }}
              >
                <div style={{ fontWeight: 500, marginBottom: "0.2rem" }}></div>
                <div style={{ fontWeight: 500, marginBottom: "0.2rem" }}></div>
                <div
                  style={{
                    fontWeight: 500,
                    marginBottom: "1.5rem",
                    opacity: 0.5,
                  }}
                ></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Secciones de detalle de cliente"
                  >
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Alta de servicio">
                          <PlusCircle
                            color="black"
                            size={28}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Lista de servicios">
                          <ClipboardList
                            color="black"
                            size={28}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Documentos de cliente">
                          <Paperclip
                            color="black"
                            size={28}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                      {...a11yProps(2)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Editar datos de cliente">
                          <Edit color="black" size={28} className="m-auto" />
                        </Tooltip>
                      }
                      {...a11yProps(3)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Comentarios">
                          <MessageSquare
                            color="black"
                            size={28}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                      {...a11yProps(4)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Envio de correo">
                          <Mail color="black" size={28} className="m-auto" />
                        </Tooltip>
                      }
                      {...a11yProps(5)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      onClick={() => {
                   
                      }}
                      label={
                        <Tooltip title="Registrar pago">
                          <Landmark
                            color="black"
                            size={28}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                    />
                  </Tabs>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6} style={{ display: "flex" }}>
          <Grid item xs={6} style={{ padding: "1rem" }}>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
              }}
            >
              Régimen fiscal
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {/* Régimen de Actividades */}
              {data.regime?.name || " - "}
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {/* Empresariales y Profesionales */}
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
                marginTop: "1.5rem",
              }}
            >
              Precio de venta por concepto
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                fontStyle: "italic",
              }}
            >
              No especificado
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
                marginTop: "1.5rem",
              }}
            >
              Fecha de alta o contratación
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {parseObjectDate(new Date(data.createdAt), 3)}
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
                marginTop: "1.5rem",
              }}
            >
              Saldo a favor
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#85BB65",
              }}
            >
              $ {data.positiveBalance}
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ padding: "1rem", borderLeft: "solid 2px lightgrey" }}
          >
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              DATOS DEL ADMINISTRADOR
            </div>
            <div
              style={{
                fontWeight: "bolder",
                marginBottom: "0.5rem",
                color: "#2A2F99",
              }}
            >
              {data.administratorName}
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {data.administratorEmail}
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {data.administratorPhone}
            </div>
            <div
              style={{
                fontWeight: "bolder",
                marginBottom: "0.5rem",
                marginTop: "1.5rem",
              }}
            >
              DATOS DEL CONTADOR
            </div>
            <div
              style={{
                fontWeight: 500,
                marginBottom: "0.5rem",
                color: "#2A2F99",
              }}
            >
              {data.counterName}
            </div>
            <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
              {data.counterEmail}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <SesTabPanel value={activeTab} index={0}>
        <NewService setLoading={setLoading} />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={1}>
        <ListServices
          clientServices={data.serviceTemplates || []}
          clickNewService={() => {
            setActiveTab(0);
          }}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={2}>
        <Documents clientData={data} setClientAllData={setData} />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={3}>
        <DetailClientInfo
          clientData={data}
          loading={loading}
          setLoading={setLoading}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={4}>
        <Comments
          clientData={data}
          setClientAllData={setData}
          setLoading={setLoadingComment}
          loading={loadingComment}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={5}>
        <ClientSendEmail clientData={data} />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={6}>
        <ClientDeposit clientData={data} fetchData={fetchData} />
      </SesTabPanel>
    </Skinpage>
  );
};

export default DetailClient;
