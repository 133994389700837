import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "../../components/Button";

const DocumentDetails = ({ document, isOpen, toggle }) => {
  const { url, name } = document;
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader>{name}</ModalHeader>
      <ModalBody>
        <embed
          className="w-full"
          height={700}
          src={url}
          type="application/pdf"
        />
      </ModalBody>
      <ModalFooter>
        <div className="flex flex-row-reverse">
          <Button
            onClick={() => {
              toggle();
            }}
          >
            Aceptar
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

DocumentDetails.propTypes = {};

export default DocumentDetails;
