import React from "react";
import { Modal } from "@mui/material";
import Button from "./Button";
import PropTypes from "prop-types";
import DgContainer from "./DgContainer";

//HOLA, ESTE MODAL SÓLO SIRVE PARA MOSTRAR INFORMACIÓN DETALLADA, COMO SE PUEDE VER, SE LE PASA COMO PROP UN ESTADO Y LA FUNCIÓN PARA MODIFICAR EL MISMO. ESE ESTADO SE USA PARA ABRIR Y CERRAR EL MODAL, SU VALOR INICIAL DEBE SER FALSE.

function DgModal({ modalState, setModalState, children }) {
  return (
    <Modal
      open={modalState}
      style={styles.modalComponentStyle}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          setModalState(false);
        }
      }}
    >
      <DgContainer>
        <div style={styles.modalAestheticLine} />
        {children}
        <div style={{ width: "50%", paddingBottom: "2%" }}>
          <Button
            onClick={() => {
              setModalState(false);
            }}
          >
            Cerrar
          </Button>
        </div>
      </DgContainer>
    </Modal>
  );
}

DgModal.propTypes = {
  modalState: PropTypes.bool.isRequired,
  setModalState: PropTypes.func.isRequired,
};

const styles = {
  modalComponentStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalAestheticLine: {
    width: "100%",
    height: "1rem",
    background: "rgb(42, 47, 153)",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    marginBottom: "1%",
  },
};

export default DgModal;
