import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { FileInput } from "lucide-react";

const ModalDetails = ({ isOpen, toggle, data = {} }) => {
  const {
    urlInvoice,
    invoice,
    urlRemission,
    document = "invoice",
    urlPaymentComplement,
    isInvoice = document === "invoice",
    title = isInvoice ? `Factura: ${invoice}` : `Complemento de Pago`,
  } = data;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>
        <FileInput color={"#565656"} size={40} className="inline-block" />{" "}
        {title}
      </ModalHeader>
      <ModalBody>
        <div className="grid grid-cols-1">
          <embed
            className="w-full"
            height={700}
            src={isInvoice ? urlInvoice || urlRemission : urlPaymentComplement}
            type="application/pdf"
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

ModalDetails.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  invice: PropTypes.object,
};

export default ModalDetails;
