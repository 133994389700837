import { TODAY } from "./consts";
import { parseObjectDate } from "./utils";

export const accountSchema = {
  idBank: "",
  account: "",
  idClientAccount: "",
};
export const clientSchema = {
  companyName: "",
  rfc: "",
  phone: "",
  street: "",
  internalNumber: "",
  outdoorNumber: "",
  suburb: "",
  postalCode: "",
  municipality: "",
  state: "",
  country: "MX",
  idCatCfdiUsage: "",
  idCatPaymentMethod: "",
  idCatRegime: "",
  clientType: "NONFISCAL",
  counterName: "",
  counterEmail: "",
  administratorName: "",
  administratorEmail: "",
  administratorPhone: "",
  paymentMethod: "",
  clientAccounts: [], // TODO: REVIEW KEY
};
export const clientDetailSchema = {
  ...clientSchema,
  services: [],
  documents: [],
  comments: [],
  regime: [],
};
export const serviceSchema = {
  idClient: "",
  idCatRegion: "",
  streetService: "",
  internalNumberService: "",
  outdoorNumberService: "",
  suburbService: "",
  postalCodeService: "",
  name: "",
  municipalityService: "",
  stateService: "",
  countryService: "MX",
  idFiscalPeriod: "",
  paymentPeriod: "",
  serviceConcepts: [],
};
export const conceptSchema = {
  idService: "",
  idCatServiceConcept: "",
  quantity: "",
  description: "",
  price: "",
  absenceDiscount: 0,
};
export const commentSchema = {
  idComment: 0,
  idClient: 0,
  idUser: "",
  title: "",
  description: "",
};
export const invoiceSchema = {
  street: "",
  internalNumber: "",
  outdoorNumber: "",
  suburb: "",
  postalCode: "",
  name: "",
  rfc: "",
  municipality: "",
  state: "",
  country: "MX",
  amount: "",
  idCatCfdiUsage: "",
  idCatPaymentMethod: "",
  idCatRegime: "",
};
export const invoicePaymentSchema = {
  comments: "",
  paymentDate: "",
  amount: "",
};
export const invoiceCreditNoteSchema = {
  description: "",
  amount: "",
};
export const userSchema = {
  name: "",
  idCatRole: "",
  email: "",
  password: "",
  passwordConfirm: "",
  permissions: [],
};
export const invoicePaymentComplementSchema = {
  idClientAccount: "",
  idSesAccount: "",
};
export const personSchema = {
  // First toggle
  personNames: "",
  paternalLastName: "",
  maternalLastName: "",
  employeeKey: "",
  birthDate: "",
  age: "100",
  gender: "",
  phone: "",
  secondaryPhone: "",
  hasAbsorptionDate: "",
  absorptionDate: "",
  // Second toggle
  birthPlace: "",
  weight: "",
  height: "",
  idCatEducationalLevel: "",
  idCatMaritalStatus: "",
  antidopingDate: "",
  // Third toggle
  street: "",
  postalCode: "",
  outdoorNumber: "",
  internalNumber: "",
  suburb: "",
  municipality: "",
  state: "",
  country: "MX",
  // Fourth toggle
  rfc: "",
  curp: "",
  imssNumber: "",
  imssComments: "",
  hasInfonavit: "",
  givesAlimony: "",
  militarService: "", // TODO: MISSING BE
  votingLisenseNumber: "",
  idCatProofResidence: "",
  idBank: "",
  cardNumber: "",
  interbankKey: "",
  file: "", // TODO: MISSING BE
  emergencyContacts: [],
  beneficiaries: [],
};
export const emergencyContactSchema = {
  // First toggle
  fullName: "",
  relationship: "",
  phone: "",
};
export const beneficiariesSchema = {
  // First toggle
  fullName: "",
  phone: "",
  address: "",
  percentage: "",
};
export const incidenceSchema = {
  idCatEmployeeIncidence: "",
  dateIncidence: parseObjectDate(TODAY, 4),
  commentIncidence: "",
  idFiscalPeriod: "",
};

export const compensationSchema = {
  employeeCompensationType: "",
  employeeCompensationDate: parseObjectDate(TODAY, 4),
  employeeCompensationAmount: "",
  commentEmployeeCompensation: "",
};

export const doubletSchema = {
  idService: "",
  doubletDate: "",
  turn: "",
  idCatReasonPaymentDoubleWork: "",
  commentDoublet: "",
};

export const vacationsSchema = {
  idEmployee: "",
  idEmployeeEntry: "",
  daysNumber: "",
  startDate: "",
  endDate: "",
  returnDate: "",
  comments: "",
};

export const egressSchema = {
  idCatReasonEmployeeInactive: "",
  commentSuspension: "",
  suspensionDate: "",
};

export const reingressSchema = {
  idCatReasonEmployeeActive: "",
  commentEmployeeEntry: "",
  dateEntry: "",
};

export const statusPaymentDoubleWorkSchema = {
  idCatStatusPaymentDoubleWork: "2",
  commentStatusPaymentDoubleWork: "",
};

export const statusPaymentRollSchema = {
  idCatStatusPaymentPayroll: "2",
  idBank: "",
  commentStatusPaymentPayroll: "",
};
