import React, { useCallback, useEffect } from "react";
import iconoPlantillaServices from "../../../css/img/IconoPlantillaServicio.svg";
import { PlusSquare } from "lucide-react";
import { useForm } from "../../../helpers/useForm";
import { conceptSchema, serviceSchema } from "../../../helpers/schemas";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { noop, parseCat } from "../../../helpers/utils";
import { useSelector } from "react-redux";
import { faMinusSquare } from "@fortawesome/free-solid-svg-icons";
import { Request } from "../../../helpers/api";
import DgForm from "../../../components/DgForm";
import DgButton from "../../../components/Button";
import ButtonIcon from "../../../components/ButtonIcon";
import Swal from "sweetalert2";

const ModalServiceDetails = ({
  isOpen,
  toggle,
  onConfirm = () => {},
  data: serviceData = serviceSchema,
}) => {
  const [data, onDataChange, clearData, setAllData] = useForm(serviceSchema);
  const { serviceConcepts: catConcepts } = useSelector((s) => s.catalogues);

  const handleNestedChange = (e, ix) => {
    const { value, name } = e.target;
    const arr = data.serviceConcepts;
    const item = arr[ix];
    item[name] = value;
    arr[ix] = item;
    onDataChange({ target: { name: "serviceConcepts", value: arr } });
  };
  const handleNestedItems = (willDelete = false, ix = null) => {
    const arr = data.serviceConcepts;
    if (willDelete) {
      arr.splice(ix, 1);
    } else {
      arr.push({ ...conceptSchema });
    }
    onDataChange({ target: { name: "serviceConcepts", value: arr } });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Request("/service/" + serviceData.idService, data, "PUT");
    if (res.ok) {
      Swal.fire("Modificación de servicio exitosa", "", "success").then(noop);
    }
  };
  const getFields = () => {
    let fields = [];
    data.serviceConcepts.forEach((sc, ix) => {
      const onNestedChange = (e) => {
        handleNestedChange(e, ix);
      };
      fields = fields.concat([
        {
          label: "Concepto de factura",
          placeholder: "Concepto de factura",
          type: "select",
          options: [
            { value: "", label: "Elige un concepto" },
            ...parseCat(catConcepts, "name", "idCatServiceConcept"),
          ],
          name: "idCatServiceConcept",
          onInputChange: onNestedChange,
          required: true,
          iconName: "Files",
          containerClassName: "mt-0",
          value: sc.idCatServiceConcept,
          errorMessage: "Éste concepto de factura no es válido",
        },
        {
          label: "Cantidad",
          placeholder: "2",
          minLength: "1",
          maxLength: "2",
          type: "number",
          name: "quantity",
          onInputChange: onNestedChange,
          required: false,
          iconName: "Hash",
          containerClassName: "mt-0",
          value: sc.quantity,
          errorMessage: "Indique una cantidad en el rango 1 a 99",
        },
        {
          label: "Precio base individual",
          type: "number",
          name: "price",
          step: 0.1,
          required: false,
          onInputChange: onNestedChange,
          value: sc.price,
          iconName: "DollarSign",
          containerClassName: "mt-0",
        },
        {
          label: "Descuento por faltas",
          type: "number",
          name: "absenceDiscount",
          step: 0.1,
          min: 0,
          placeholder: "-",
          disabled: false, //!sc.applyAbsence,
          required: true,
          onInputChange: onNestedChange,
          value: sc.absenceDiscount,
          iconName: "UserMinus",
          containerClassName: "mt-0",
          defaultValue: 0,
        },
        {
          label: "Observaciones",
          type: "text",
          name: "description",
          onInputChange: onNestedChange,
          required: false,
          value: sc.description,
          iconName: "Files",
          containerClassName: "mt-0",
        },
        {
          element: (
            <ButtonIcon
              className="text-sesRed px-1"
              onClick={() => handleNestedItems(true, ix)}
              icon={faMinusSquare}
            />
          ),
        },
      ]);
    });
    return [
      {
        title: "",
        groupClassNameGrid: "grid-cols-6",
        fields,
      },
    ];
  };
  const fetchData = useCallback(async (idService) => {
    const { ok, data } = await Request("/service/" + idService);
    if (ok) {
      const fetchedService = {
        ...data,
        serviceConcepts: data.serviceConcepts.map((sc) => ({
          ...sc,
          absenceDiscount: sc.absenceDiscount ? sc.absenceDiscount : 0,
        })),
      };
      setAllData(fetchedService);
    }
  }, []);
  useEffect(() => {
    if (serviceData.idService && isOpen) {
      fetchData(serviceData.idService);
    }
  }, [serviceData]);
  return (
    <Modal size="xl" isOpen={isOpen} style={{ maxWidth: "90%" }}>
      <ModalHeader toggle={toggle}>
        <div
          style={{ textAlign: "center", fontWeight: 700, fontSize: "1.5rem" }}
        >
          EDICIÓN DE SERVICIO
        </div>
      </ModalHeader>
      <ModalBody style={{ padding: "3rem" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={iconoPlantillaServices} style={{ width: "5rem" }} />
            <div
              style={{
                marginLeft: "1rem",
                fontWeight: 600,
                color: "#2A2F99",
              }}
            >
              {serviceData.nameService}
            </div>
          </div>
          <div
            onClick={() => {
              handleNestedItems();
            }}
          >
            <PlusSquare
              fill="#2A2F99"
              color="white"
              size={35}
              style={{ display: "inline" }}
            />
            Agregar otro concepto
          </div>
        </div>
        <DgForm
          data={data}
          classNameGrid="grid grid-cols-2 gap-4"
          onChange={onDataChange}
          onSubmit={handleSubmit}
          groups={getFields()}
        >
          <ModalFooter>
            <div className="grid grid-cols-3 gap-5">
              <div />
              <DgButton type="submit" color="primary" onClick={onConfirm}>
                Actualizar
              </DgButton>
              <DgButton onClick={toggle}>Cerrar</DgButton>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
    </Modal>
  );
};
export default ModalServiceDetails;
