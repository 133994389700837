import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import iconoDetalle from "../css/img/IconoDetalleCliente.svg";

const ClientCard = ({
  client = {},
  selectable = false,
  onTap = () => {},
  md = "3",
  sm = "4",
  xs = "6",
  bottomComponent = null,
}) => {
  const { selected = false, ix = 0 } = client;
  return (
    <Grid
      container
      spacing={2}
      xs={xs}
      sm={sm}
      md={md}
      style={{
        marginTop: "0.3vw",
        display: "inline-block",
        margin: "8px 0",
        padding: "0 20px",
        borderRadius: "20px",
        cursor: selectable ? "pointer" : "",
      }}
      onClick={onTap(ix)}
    >
      <Grid>
        <div
          style={{
            width: "100%",
            border: "solid 0.5px lightgrey",
            borderRadius: "1vw",
            display: "flex",
            flexDirection: "row",
            border: selected ? "3px solid #2A2F99" : "",
            boxShadow: selected
              ? "0px 2px 3px #2A2F99"
              : "2px 3px 3px 0px #00000040",
            transition: "all 0.15s linear",
          }}
        >
          <div
            style={{
              width: "3rem",
              backgroundColor: "#2A2F99",
              borderTopLeftRadius: "1vw",
              borderBottomLeftRadius: "1vw",
            }}
          ></div>
          <Grid
            container
            spacing={2}
            style={{
              height: "330px",
              marginTop: "0.01rem",
              marginLeft: "0.01rem",
            }}
          >
            <Grid item xs={4} style={{ padding: 0 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1vw",
                }}
              >
                <img src={iconoDetalle} style={{ width: "7rem" }} />
              </div>
            </Grid>
            <Grid
              item
              xs={8}
              style={{ padding: 0, display: "flex", alignItems: "center" }}
            >
              <div
                style={{
                  color: "#2A2F99",
                  padding: "1rem",
                  fontSize: "1.2rem",
                  fontWeight: 500,
                }}
              >
                {client.name || " - "}
              </div>
            </Grid>
            <Grid item xs={6} style={{ padding: "1rem" }}>
              <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}>
                RFC: {client.rfc || " - "}
              </div>
              <div style={{ fontWeight: 500, marginBottom: "0.5rem" }}></div>
              <div
                style={{
                  fontWeight: 500,
                  fontSize: "0.9rem",
                  textAlign: "justify",
                }}
              >
                {`${client.street}, ${client.outdoorNumber} ${client.internalNumber}, Col: ${client.suburb}, C.P. ${client.postalCode}, ${client.municipality}, ${client.state}`}
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ padding: "1rem", borderLeft: "solid 2px lightgrey" }}
            >
              <div style={{ fontWeight: 500, marginBottom: "0.2rem" }}></div>
              <div style={{ fontWeight: 500, marginBottom: "0.2rem" }}></div>
              <div
                style={{
                  fontWeight: 500,
                  marginBottom: "1.5rem",
                  opacity: 0.5,
                }}
              ></div>
              <div style={{ display: "flex", flexDirection: "row" }}></div>
            </Grid>
            <Grid item xs={12} style={{ padding: "0 10px" }}>
              {bottomComponent}
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

ClientCard.propTypes = {};

export default ClientCard;
