import React, { useState } from "react";
import PropTypes from "prop-types";
import InputIcon from "./InputIcon";
import "./DgInput.css";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import DgInputFile from "./DgInputFile";

const DgInput = (props) => {
  const {
    containerClassName = "",
    className = "",
    label = "",
    placeholder = label,
    type,
    iconName,
    required = false,
    errorMessage = "Dato requerido o inválido",
    name,
    disabled,
    value,
    onChange,
    onInputChange,
    options = [],
    ...inputProps
  } = props;

  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleChange = (e) => {
    onInputChange ? onInputChange(e) : onChange(e);
  };

  const renderElement = () => {
    if (type === "select") {
      return (
        <>
          {/*<Select
            className={`cssInput text-sesGray placeholder-textGray py-3 pr-8 pl-14 my-2 w-full ${className}`}
            {...{
              name,
              onBlur: handleFocus,
              isDisabled: disabled,
              isRequired: required,
            }}
            value={{ value, label: selectLabel }}
            onChange={(e) => {
              onChange({ target: { ...e, name } });
            }}
            options={options}
          />*/}
          <select
            className={`cssInput text-sesGray placeholder-textGray py-3 pr-8 pl-14 my-2 w-full ${className} `}
            onBlur={handleFocus}
            focused={focused.toString()}
            onChange={handleChange}
            {...{
              ...inputProps,
              placeholder,
              type,
              required,
              name,
              value,
              disabled,
            }}
            containerClassName=""
          >
            {options.map((o) => (
              <option value={o.value}>{o.label}</option>
            ))}
          </select>
        </>
      );
    } else if (type === "radio") {
      return (
        <RadioGroup
          row
          aria-labelledby="demo-controlled-radio-buttons-group"
          name={name}
          value={value}
          onChange={(e) => {
            const { target } = e;
            let { value, name } = target;
            value = ["true", "false"].includes(target.value)
              ? target.value === "true"
              : target.value;
            handleChange({ target: { name, value } });
          }}
        >
          {options.map((o) => (
            <FormControlLabel
              value={o.value}
              control={<Radio />}
              label={o.label}
            />
          ))}
        </RadioGroup>
      );
    } else if (type === "checkbox") {
    } else if (type === "file") {
      return (
        <DgInputFile
          onChange={handleChange}
          {...{
            ...inputProps,
            required,
            name,
            value,
            disabled,
          }}
        />
      );
    }
    const Element = type === "textarea" ? type : "input";
    return (
      <Element
        className={`cssInput text-sesGray placeholder-textGray py-3 pr-8 pl-14 my-2 w-full ${className} `}
        onBlur={handleFocus}
        focused={focused.toString()}
        onChange={handleChange}
        containerClassName=""
        {...{
          ...inputProps,
          placeholder,
          type,
          required,
          name,
          value,
          disabled,
        }}
      />
    );
  };

  return (
    <div className={`mx-auto w-full mt-6 dg-input ${containerClassName}`}>
      {label && (
        <label className="text-sesBlue text-left text-base labelForm p-2">
          {required && "*"} {label}
        </label>
      )}
      {iconName && <InputIcon name={iconName} color="#565656" size={42} />}
      {renderElement()}
      <span className="dg-input-error">{errorMessage}</span>
    </div>
  );
};

DgInput.propTypes = {
  containerClassName: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    "password",
    "text",
    "email",
    "number",
    "date",
    "tel",
    "select",
    "textarea",
    "radio",
    "checkbox",
    "file",
  ]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  onDataChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  step: PropTypes.number,
  options: PropTypes.array,
  iconName: PropTypes.string,
  placeholder: PropTypes.string,
};

export default DgInput;
