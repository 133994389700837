import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "../../helpers/useForm";
import { getColumnsInvoices } from "../../helpers/datatableHelpers";
import { Request } from "../../helpers/api";
import { useNavigate } from "react-router-dom";
import {
  downloadBlobRes,
  getOperatingYearsCat,
  parseCat,
} from "../../helpers/utils";
import { useSelector } from "react-redux";
import { TODAY } from "../../helpers/consts";
import { clientTypes, monthsEs } from "../../helpers/enums";
import { FileDigit, FileInput, Files, FileText } from "lucide-react";
import { Tooltip } from "@mui/material";
import DgInput from "../../components/DgInput";
import Skinpage from "../../components/Skinpage";
import Button from "../../components/Button";
import DgTable from "../../components/DgTable";
import Swal from "sweetalert2";
import ModalPaymentStatus from "./modals/ModalPaymentStatus";
import ModalDocumentDetails from "./modals/ModalDoumentDetails";
import ModalServiceDetails from "./modals/ModalServiceDetails";
import ModalDetails from "./modals/ModalDetails";
import ModalCancel from "./modals/ModalCancel";
import ModalRe from "./modals/ModalRe";
import ModalPaymentComplement from "./modals/ModalPaymentComplement";
import ModalFilterClients from "./modals/ModalFilterClients";
import ModalRemissionInvoice from "./modals/ModalRemissionInvoice";
import ModalServicePayments from "./modals/ModalServicePayments";
import ModalServiceCreditNotes from "./modals/ModalServiceCreditNotes";
import ModalCreditNote from "./modals/ModalCreditNote";
const localPrefix = "invoicing";
const filtersSchema = {
  year: TODAY.getFullYear(),
  month: (TODAY.getMonth() + 1 + "").padStart(2, "0"),
  clients: "",
  idCatStatusPaymentService: "",
  idCatStatusInvoiceService: "",
  idFiscalPeriod: "",
  clientType: "",
  idCatRegion: "",
};

const setStoredFilters = () => {
  const storedOb = {};
  Object.keys(filtersSchema).forEach((k) => {
    let storedVal = localStorage.getItem(`${localPrefix}_${k}`);
    if (storedVal && k !== "clients") {
      storedOb[k] = storedVal;
    }
  });
  return { ...filtersSchema, ...storedOb };
};

const Invoicing = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [selList, setSelList] = useState({});
  const [clients, setClients] = useState([]);
  const [modalOpenPayment, setModalOpenPayment] = useState(false);
  const [modalOpenDocs, setModalOpenDocs] = useState(false);
  const [modalOpenServ, setModalOpenServ] = useState(false);
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const [modalOpenCancel, setModalOpenCancel] = useState(false);
  const [modalOpenRe, setModalOpenRe] = useState(false);
  const [modalOpenRemissionInvoice, setModalOpenRemissionInvoice] =
    useState(false);
  const [modalOpenServicePayments, setModalOpenServicePayments] =
    useState(false);
  const [modalOpenServiceCreditNotes, setModalOpenServiceCreditNotes] =
    useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [modalOpenPaymentComplement, setModalOpenPaymentComplement] =
    useState(false);
  const [modalOpenFilterClients, setModalOpenFilterClients] = useState(false);
  const [modalOpenNewCreditNote, setModalOpenNewCreditNote] = useState(false);
  const [fiscalPeriodList, setFiscalPeriodList] = useState([]);
  const [filters, setFilters, clearFilters, setAllFilters] =
    useForm(setStoredFilters);
  const { CatStatusPaymentService, CatStatusInvoiceService, catRegions } =
    useSelector((s) => s.catalogues);

  /*Logical funcs*/
  const funcs = {
    documents: (row) => () => {
      setSingleData(row);
      setModalOpenDocs(true);
    },
    details:
      ({ idClient }) =>
      () => {
        navigate("/detalle-cliente/" + idClient);
      },
    edit: (row) => () => {
      setSingleData(row);
      setModalOpenServ(true);
    },
    payment: (row) => () => {
      setSingleData(row);
      setModalOpenPayment(true);
    },
    listPayments: (row) => () => {
      setSingleData(row);
      setModalOpenServicePayments(true);
    },
    onSelectCheck: (row) => () => {
      const { idService, idClient } = row;
      let ix;
      data.find(({ idService: idS, idClient: idC }, i) => {
        ix = i;
        return idS === idService && idC === idClient;
      });
      const newData = [...data];
      const client = newData[ix];
      client.selected = !client.selected;
      newData[ix] = client;
      setData([...newData]);
    },
    invoiceDetails: (row) => () => {
      setSingleData(row);
      setModalOpenInvoice(true);
    },
    cancel: (row) => () => {
      setSingleData(row);
      setModalOpenCancel(true);
    },
    cancelGeneral: (row) => () => {
      handleGeneralInvoiceDelete(row);
    },
    rebill: (row) => () => {
      setSingleData(row);
      setModalOpenRe(true);
    },
    download: (row) => () => {
      downloadBlobRes(row.urlInvoice);
    },
    cancelPayment: (row) => () => {
      handlePaymentDelete(row);
    },
    checkAll: () => {
      const newSel = !allSelected;
      const newData = data.map((d) => ({ ...d, selected: newSel }));
      setAllSelected(newSel);
      setData(newData);
    },
    remissionInvoice: (row) => () => {
      setSingleData(row);
      setModalOpenRemissionInvoice(true);
    },
    openCreditNote: (row) => () => {
      const { urlCreditNote } = row;
      setSingleData({
        ...row,
        isInvoice: true,
        urlInvoice: urlCreditNote,
        name: "Nota de crédito",
      });
      setModalOpenInvoice(true);
    },
    creditNote: (row) => () => {
      setSingleData(row);
      setModalOpenNewCreditNote(true);
    },
    cancelCreditNote: (row) => () => {
      let { missingAmount, amount, idCreditNoteService } = row;
      missingAmount = missingAmount === null ? amount : missingAmount;
      Swal.fire({
        title: "¿Deseas cancelar ésta Nota de crédito?",
        text: `Ésta acción es irreversible`,
        icon: "warning",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const { ok } = await Request(
            `/invoice/creditnote/${idCreditNoteService}`,
            {},
            "PATCH"
          );
          if (ok) {
            Swal.fire("Cancelación exitosa", "", "success").then(() => {
              fetchData(filters);
            });
          }
        }
      });
    },
    openCreditNotes: (row) => () => {
      setSingleData(row);
      setModalOpenServiceCreditNotes(true);
    },
    cancelRemission: (row) => () => {
      setSingleData(row);
      const { idService } = row;
      Swal.fire({
        title: "¿Deseas cancelar ésta remisión?",
        text: `Ésta acción es irreversible`,
        icon: "warning",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request(
            `/invoice/remission/${idService}`,
            {},
            "PATCH"
          );

          if (res.ok) {
            Swal.fire("Cancelación exitosa", res.msg, "success").then(() => {
              fetchData(filters);
            });
          } else {
            Swal.fire("Error", res.msg, "error");
          }
        }
      });
    },
  };
  const fetchFiscalPeriods = async (filters) => {
    const { year, month } = filters;
    const skipValues = ["", "all"];
    if (!skipValues.includes(year) && !skipValues.includes(month)) {
      const { ok, data } = await Request(
        `/user/fiscalPeriods?limit=3&all=true&year=${year}&month=${month}`
      );
      if (ok) {
        setFiscalPeriodList(data);

        const localidFiscalPeriod = localStorage.getItem(
          `${localPrefix}_idFiscalPeriod`
        );
        if (!localidFiscalPeriod || localidFiscalPeriod === "") {
          const lastPeriod = data[1].idFiscalPeriod;
          setFilters({ target: { name: "idFiscalPeriod", value: lastPeriod } });
        }
      }
    } else {
      setFiscalPeriodList([]);
    }
  };
  const fetchData = useCallback(async (filters) => {
    if (filters.idFiscalPeriod !== "") {
      const filterStr = Object.keys(filters)
        .map((k) => `${k}=${filters[k]}`)
        .join("&");
      const res = await Request(`/invoice/service?${filterStr}`);
      if (res.ok) {
        const invoiceList = res.data.map((f) => ({ ...f, selected: false }));
        setData(invoiceList);
      }
    }
  }, []);
  const fetchClients = async () => {
    const resC = await Request(`/client`);
    if (resC.ok) {
      setClients(resC.data.map((c) => ({ ...c, selected: false })));
    }
  };
  const getSelectedServicesToInvoice = () => {
    return data.filter(
      (d) => d.selected && [1, 4].includes(d.idCatStatusInvoiceService)
    );
  };
  const getSelectedServicesToAdvance = () => {
    return data.filter((d) => d.selected);
  };
  const getSelectedServicesToComplement = () => {
    const auxClients = [];
    const filtered = data.filter((d) => {
      if (d.selected && !auxClients.includes(d.idClient))
        auxClients.push(d.idClient);
      return (
        d.selected &&
        d.idCatStatusInvoiceService === 2 &&
        [3, 6].includes(d.idCatStatusPaymentService) &&
        d.PaymentMethod === "PPD" &&
        !d.urlPaymentComplement
      );
    });
    return auxClients.length > 1 ? [] : filtered;
  };
  const getSelectedServicesToGeneral = () => {
    return data.filter(
      (d) =>
        d.selected &&
        d.clientType === "NONFISCAL" &&
        d.idCatStatusInvoiceService === 3 &&
        !d.urlNonFiscalInvoice &&
        [3, 4].includes(d.idCatStatusPaymentService)
    );
  };
  const filterMonths = () => {
    const currYear = TODAY.getFullYear();
    const currMonth = TODAY.getMonth();
    return monthsEs.filter(({ value }) =>
      parseInt(filters.year) === currYear
        ? parseInt(value) - 1 <= currMonth
        : true
    );
  };
  const storeFilters = () => {
    Object.keys(filters).forEach((k) => {
      localStorage.setItem(`${localPrefix}_${k}`, filters[k]);
    });
  };
  const handleGeneralInvoice = (services) => {
    Swal.fire({
      title: "¿Deseas facturar los servicios seleccionados?",
      text: `Se generará una factura general para todos ellos`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok, objupdateafacturar } = await Request(
          `/invoice/generalinvoice`,
          {
            services: services.map(({ idService }) => ({ idService })),
          },
          "POST"
        );
        if (ok) {
          Swal.fire("Facturación exitosa", "", "success").then(() => {
            const { urlPdf: urlPaymentComplement } = objupdateafacturar[0];
            fetchData(filters);
            setSingleData({
              urlPaymentComplement,
              title: "Factura General",
              document: "general",
            });
            setModalOpenInvoice(true);
          });
        }
      }
    });
  };
  /*Modal handlers*/
  const handleCancelInvoice = () => {
    const { nameClient, invoice, idService } = singleData;
    Swal.fire({
      title: "¿Deseas cancelar ésta factura?",
      text: `${nameClient}: ${invoice}`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok } = await Request(`/invoice/${idService}`, {}, "PATCH");
        if (ok) {
          Swal.fire("Cancelación exitosa", "", "success").then(() => {
            fetchData(filters);
            setModalOpenCancel(false);
          });
        }
      }
    });
  };
  const handlePaymentComplement = (services) => {
    setSelList(services);
    setModalOpenPaymentComplement(true);
    /*Swal.fire({
      title: "¿Deseas generar el complemento de pago?",
      text: "",
      icon: "question",
      showCancelButton: true,
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        const { ok, data } = await Request(
          "/invoice/paymentcomplement/",
          { services: services.map(({ idService }) => ({ idService })) },
          "POST"
        );
        if (ok) {
          Swal.fire("Generación exitosa", "", "success").then(() => {
            fetchData(filters);
          });
        }
      }
    });*/
  };
  const handlePaymentDelete = (service) => {
    const { idService } = service;
    Swal.fire({
      title: "¿Deseas cancelar éste pago?",
      text: `Ésta acción es irreversible`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok } = await Request(
          `/service/statuspayment/${idService}`,
          {},
          "PATCH"
        );
        if (ok) {
          Swal.fire("Cancelación exitosa", "", "success").then(() => {
            fetchData(filters);
          });
        }
      }
    });
  };
  const handleGeneralInvoiceDelete = (service) => {
    const { idService } = service;
    Swal.fire({
      title: "¿Deseas cancelar ésta factura general?",
      text: `Ésta acción es irreversible`,
      icon: "warning",
      showCancelButton: true,
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok } = await Request(
          `/invoice/generalinvoice/${idService}`,
          {},
          "PATCH"
        );
        if (ok) {
          Swal.fire("Cancelación exitosa", "", "success").then(() => {
            fetchData(filters);
          });
        }
      }
    });
  };
  /* State hooks */
  useEffect(() => {
    fetchData(filters);
  }, [filters]);
  useEffect(() => {
    fetchFiscalPeriods(filters);
    const { year, month, idFiscalPeriod } = filters;
    if (year === "all" && (month !== "all" || idFiscalPeriod !== "all")) {
      setAllFilters({ ...filters, month: "all", idFiscalPeriod: "all" });
    }
    if (month === "all" && idFiscalPeriod !== "all") {
      setAllFilters({ ...filters, idFiscalPeriod: "all" });
    }
  }, [filters.year, filters.month]);
  useEffect(() => {
    setAllFilters({ ...filters, idFiscalPeriod: "all" });
  }, [filters.month]);
  useEffect(() => {
    storeFilters();
  }, [filters]);
  useEffect(() => {
    setStoredFilters();
    fetchClients();
  }, []);
  // Render Consts
  const servicesToComplement = getSelectedServicesToComplement();
  const servicesToInvoice = getSelectedServicesToInvoice();
  const servicesToAdvance = getSelectedServicesToAdvance();
  const servicesToGeneral = getSelectedServicesToGeneral();
  const selectedLength = data.filter((d) => d.selected).length;
  return (
    <Skinpage pageTitle={"Facturación"}>
      <div className="grid grid-cols-5 gap-4" style={{ alignItems: "end" }}>
        <DgInput
          type="select"
          label="Año de consulta"
          onChange={setFilters}
          options={[
            { value: "all", label: "Todos" },
            ...getOperatingYearsCat(),
          ]}
          name="year"
          value={filters.year}
          placeholder="Seleccionar año"
          iconName="Calendar"
        />
        <DgInput
          type="select"
          label="Mes de consulta"
          onChange={setFilters}
          options={[{ value: "all", label: "Todos" }, ...filterMonths()]}
          name="month"
          value={filters.month}
          placeholder="Seleccionar mes"
          iconName="Calendar"
        />
        <DgInput
          type="select"
          label="Periodo"
          onChange={setFilters}
          options={[
            { value: "all", label: "Todos" },
            ...parseCat(fiscalPeriodList, "name", "idFiscalPeriod"),
          ]}
          name="idFiscalPeriod"
          value={filters.idFiscalPeriod}
          placeholder="Seleccionar periodo"
          iconName="Calendar"
        />
        <DgInput
          type="select"
          label="Estatus Facturación"
          options={[
            { value: "", label: "Todos" },
            ...parseCat(
              CatStatusInvoiceService,
              "name",
              "idCatStatusInvoiceService"
            ),
          ]}
          onChange={setFilters}
          name="idCatStatusInvoiceService"
          value={filters.idCatStatusInvoiceService}
          placeholder="Seleccionar estatus"
          iconName="Files"
        />
        <DgInput
          type="select"
          label="Estatus Pago"
          options={[
            { value: "", label: "Todos" },
            ...parseCat(
              CatStatusPaymentService,
              "name",
              "idCatStatusPaymentService"
            ),
          ]}
          onChange={setFilters}
          name="idCatStatusPaymentService"
          value={filters.idCatStatusPaymentService}
          placeholder="Seleccionar estatus"
          iconName="Files"
        />
      </div>
      <div className="grid grid-cols-2 gap-4" style={{ alignItems: "end" }}>
        <div className="grid grid-cols-3 gap-4">
          <DgInput
            type="text"
            label="Filtrar Clientes"
            name="clientsList"
            readOnly={true}
            value={
              clients
                .filter((c) => c.selected)
                .map((c) => c.name)
                .join(", ") || ""
            }
            placeholder="Filtrar Clientes"
            iconName="Users"
            onClick={() => {
              setModalOpenFilterClients(true);
            }}
          />
          <DgInput
            type="select"
            label="Tipo de cliente"
            onChange={setFilters}
            options={[{ value: "all", label: "Todos" }, ...clientTypes]}
            name="clientType"
            value={filters.clientType}
            placeholder="Seleccionar tipo de cliente"
            iconName="User"
          />
          <DgInput
            type="select"
            label="Región"
            onChange={setFilters}
            options={[
              { value: "all", label: "Todos" },
              ...parseCat(catRegions, "name", "idCatRegion"),
            ]}
            name="idCatRegion"
            value={filters.idCatRegion}
            placeholder="Seleccionar región"
            iconName="Map"
          />
        </div>
        <div className="grid grid-cols-4 gap-4">
          <Button
            disabled={servicesToInvoice.length === 0}
            onClick={() => {
              const services = servicesToInvoice;
              services.length > 0
                ? navigate("/facturar", { state: services })
                : navigate("/facturar");
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title={"Facturar / remisionar"}>
              <Files
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>
          <Button
            disabled={servicesToGeneral.length === 0 || selectedLength === 0}
            innerClassName="relative -top-2 p-0"
            onClick={() => {
              handleGeneralInvoice(servicesToGeneral);
            }}
          >
            <Tooltip title="Factura general">
              <FileText
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>
          <Button
            disabled={servicesToAdvance.length === 0}
            onClick={() => {
              navigate("/adelantar-factura", {
                state: servicesToAdvance,
              });
            }}
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title="Adelantar Facturas">
              <FileInput
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>
          <Button
            disabled={
              servicesToComplement.length === 0 ||
              servicesToComplement.length !== selectedLength
            }
            onClick={() => {
              const services = servicesToComplement;
              if (services.length > 0) {
                handlePaymentComplement(services);
              }
            }}
            color="secondary"
            innerClassName="relative -top-2 p-0"
          >
            <Tooltip title="Complemento de pago">
              <FileDigit
                size={30}
                className="m-auto action-table w-full"
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          </Button>
        </div>
      </div>
      <br />
      <DgTable
        data={data}
        columnsDef={getColumnsInvoices(funcs, { allSelected })}
      />
      <ModalPaymentStatus
        isOpen={modalOpenPayment}
        toggle={() => {
          setModalOpenPayment(!modalOpenPayment);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalDocumentDetails
        isOpen={modalOpenDocs}
        toggle={() => {
          setModalOpenDocs(!modalOpenDocs);
        }}
        data={singleData}
      />
      <ModalServiceDetails
        isOpen={modalOpenServ}
        toggle={() => {
          setModalOpenServ(!modalOpenServ);
        }}
        data={singleData}
      />
      <ModalDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
        }}
        data={singleData}
      />
      <ModalCancel
        isOpen={modalOpenCancel}
        toggle={() => {
          setModalOpenCancel(!modalOpenCancel);
        }}
        onConfirm={handleCancelInvoice}
        data={singleData}
      />
      <ModalRe
        isOpen={modalOpenRe}
        toggle={() => {
          setModalOpenRe(!modalOpenRe);
        }}
        fetchData={() => {
          fetchData(filters);
        }}
        data={singleData}
      />
      <ModalPaymentComplement
        isOpen={modalOpenPaymentComplement}
        toggle={() => {
          setModalOpenPaymentComplement(!modalOpenPaymentComplement);
        }}
        fetchData={() => {
          fetchData(filters);
        }}
        selList={selList}
      />
      <ModalFilterClients
        isOpen={modalOpenFilterClients}
        toggle={() => {
          setModalOpenFilterClients(!modalOpenFilterClients);
        }}
        data={clients}
        setFilters={setFilters}
        setClients={setClients}
      />
      <ModalRemissionInvoice
        isOpen={modalOpenRemissionInvoice}
        toggle={() => {
          setModalOpenRemissionInvoice(!modalOpenRemissionInvoice);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalServicePayments
        isOpen={modalOpenServicePayments}
        toggle={() => {
          setModalOpenServicePayments(!modalOpenServicePayments);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalServiceCreditNotes
        isOpen={modalOpenServiceCreditNotes}
        toggle={() => {
          setModalOpenServiceCreditNotes(!modalOpenServiceCreditNotes);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
      <ModalCreditNote
        isOpen={modalOpenNewCreditNote}
        toggle={() => {
          setModalOpenNewCreditNote(!modalOpenNewCreditNote);
        }}
        data={singleData}
        fetchData={() => {
          fetchData(filters);
        }}
      />
    </Skinpage>
  );
};

export default Invoicing;
