import React from "react";
import "./Button.css";

const Button = ({
  children,
  onClick,
  type = "button",
  style = [],
  disabled = false,
  innerClassName = "",
  color = "primary",
}) => {
  return (
    <div className="mx-auto w-full mt-6">
      <button
        {...{
          style,
          onClick,
          type,
          disabled,
        }}
        className={
          innerClassName +
          " dgButton px-8 w-full mx-auto text-center " +
          (!innerClassName.includes("py") ? "py-3 " : " ") +
          color
        }
      >
        {children}
      </button>
    </div>
  );
};

export default Button;
