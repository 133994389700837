import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useForm } from "../../../helpers/useForm";
import { useSelector } from "react-redux";
import { parseCat } from "../../../helpers/utils";
import { Request } from "../../../helpers/api";
import { invoicePaymentComplementSchema } from "../../../helpers/schemas";
import Button from "../../../components/Button";
import DgForm from "../../../components/DgForm";
import Swal from "sweetalert2";
import Title from "../../../components/Title";
import ModalDetails from "./ModalDetails";
import { Backdrop, CircularProgress } from "@mui/material";

const ModalPaymentComplement = ({
  isOpen,
  toggle,
  selList: paymentData = [],
  fetchData,
}) => {
  const [data, onDataChange, clearData, setAllData] = useForm(
    invoicePaymentComplementSchema
  );
  const [modalOpenDocs, setModalOpenDocs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [clientAccounts, setClientAccounts] = useState([]);
  const { SesAccount = [], bank: CatBanks = [] } = useSelector(
    (s) => s.catalogues
  );
  const fetchClientAccounts = useCallback(async (idClient) => {
    const { ok, data } = await Request(`/client/${idClient}`);
    if (ok) {
      setClientAccounts(data.clientAccounts);
    }
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    let createdAccount = "";
    const { idClientAccount, idBank, account } = data;
    const idClient = paymentData[0].idClient;
    if (idClientAccount === "NEW_ACCOUNT") {
      const res = await Request(
        `/client/${idClient}/account/`,
        {
          accounts: [
            {
              idBank,
              account,
            },
          ],
        },
        "POST"
      );
      createdAccount = res.idClientAccount;
    }
    setIsLoading(true);
    const res = await Request(
      "/invoice/paymentcomplement/",
      {
        ...data,
        idClientAccount:
          idClientAccount === "NEW_ACCOUNT"
            ? createdAccount
            : data.idClientAccount,
        services: paymentData.map(({ idService }) => ({ idService })),
      },
      "POST"
    );
    if (res.ok) {
      Swal.fire("Generación exitosa", "", "success").then(() => {
        const { objupdateafacturar } = res;
        fetchData();
        //toggle();
        if (objupdateafacturar.length === 1) {
          const { urlPaymentComplement } = objupdateafacturar[0];
          setSingleData({
            title: "Complemento de pago",
            urlPaymentComplement,
            document: "complement",
          });
          setModalOpenDocs(true);
        }
      });
    }
    setIsLoading(false);
  };
  const getNewAccFields = () => {
    const { idClientAccount } = data;
    if (idClientAccount === "NEW_ACCOUNT") {
      return [
        {
          type: "select",
          iconName: "Landmark",
          required: true,
          options: [
            { label: "Elige un banco", value: "" },
            ...parseCat(CatBanks, "name", "idBank"),
          ],
          name: "idBank",
        },
        {
          type: "text",
          minLength: 10,
          maxLength: 25,
          iconName: "DollarSign",
          required: true,
          name: "account",
        },
      ];
    }
    return [];
  };
  useEffect(() => {
    const { idClient } = paymentData[0] || {};
    if (idClient && idClient !== "" && isOpen) {
      fetchClientAccounts(idClient);
    }
  }, [paymentData, fetchClientAccounts]);
  useEffect(() => {}, [data.idClientAccount]);
  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={toggle}>GENERAR COMPLEMENTO DE PAGO</ModalHeader>
      <ModalBody>
        <Title>Cliente: {paymentData[0]?.name || " - "}</Title>
        <div className="m-auto mx-2">
          <DgForm
            data={data}
            onChange={onDataChange}
            onSubmit={onSubmit}
            groups={[
              {
                fields: [
                  {
                    type: "select",
                    label: "Cuenta beneficiaria",
                    required: true,
                    iconName: "Landmark",
                    name: "idSesAccount",
                    options: [
                      { label: "Elige una cuenta", value: "" },
                      ...parseCat(
                        SesAccount.map((sa) => ({
                          ...sa,
                          label: `${sa.bank} - ${sa.account}`,
                        })),
                        "label",
                        "idSesAccount"
                      ),
                    ],
                  },
                  {
                    type: "select",
                    label: "Cuenta ordenante",
                    iconName: "Landmark",
                    required: true,
                    name: "idClientAccount",
                    options: [
                      { label: "Elige una cuenta", value: "" },
                      ...parseCat(clientAccounts, "account", "idClientAccount"),
                      { label: "Nueva cuenta", value: "NEW_ACCOUNT" },
                    ],
                  },
                  ...getNewAccFields(),
                ],
              },
            ]}
            classNameGrid="grid-cols-1"
          >
            <div className="grid grid-cols-2 gap-3">
              <Button type="submit">Aceptar</Button>
              <Button
                onClick={() => {
                  toggle();
                }}
                color="secondary"
              >
                Cancelar
              </Button>
            </div>
          </DgForm>
        </div>
      </ModalBody>
      <ModalDetails
        isOpen={modalOpenDocs}
        toggle={() => {
          setModalOpenDocs(!modalOpenDocs);
          toggle();
        }}
        data={singleData}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Modal>
  );
};

ModalPaymentComplement.propTypes = {};

export default ModalPaymentComplement;
