import React, { useEffect, useRef, useState } from "react";
import iconPhoto from "../css/img/IconoFotoPerfil.svg";

const DgInputFile = ({ value: selectedFile, onChange, accept }) => {
  const inputFileRef = useRef(null);
  const [preview, setPreview] = useState();
  const triggerClick = () => {
    inputFileRef.current.click();
  };
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);
  return (
    <div className="w-full" onClick={triggerClick}>
      <div className="uploadProfilePicture w-full text-center border-2 rounded-xl pt-1 pb-4 mt-2 cursor-pointer">
        <div className="block">
          <input
            type="file"
            ref={inputFileRef}
            className="inputFile"
            onChange={onChange}
            accept={accept}
          />
          {selectedFile ? (
            <img className="mx-auto" width="240" src={preview} />
          ) : (
            <>
              <img src={iconPhoto} width="120" className="mx-auto my-0 p-0" />
            </>
          )}
          <span className="text-sesGray text-base font-semibold block">
            Arrastre su archivo
          </span>
          <span className="text-sesGray text-sm block my-2">
            o busque en sus archivos locales
          </span>
          <span className="text-sesGray text-xs block font-thin">
            Sólo archivos JPG, PNG o GIF de máximo 2 MB
          </span>
        </div>
      </div>
    </div>
  );
};

export default DgInputFile;
