import React from "react";
import PropTypes from "prop-types";
import TopBar from "./TopBar";
import Menu from "./Menu";
import { useSelector } from "react-redux";
import { getPermissions } from "../helpers/utils";

const Skinpage = ({ children, pageTitle }) => {
  const { permissions } = useSelector((state) => state);
  return (
    <div>
      <div className="container">
        <TopBar pageTitle={pageTitle} />
        <Menu userPermissions={getPermissions(permissions)} />
      </div>
      <div className="container mt-20 mb-10">{children}</div>
    </div>
  );
};

Skinpage.propTypes = {
  children: PropTypes.element,
};

export default Skinpage;
