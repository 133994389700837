import React from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { egressSchema, personSchema } from "../../../helpers/schemas";
import { useForm } from "../../../helpers/useForm";
import DgButton from "../../../components/Button";
import DgInput from "../../../components/DgInput";
import iconoDetalleEmpleado from "../../../css/img/IconoDetalleEmpleado.svg";
import { Request } from "../../../helpers/api";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  parseCat,
  parseObjectDate,
  sanitizeDate,
} from "../../../helpers/utils";
import { TODAY } from "../../../helpers/consts";

const ModalEgressPerson = ({
  isOpen,
  toggle = {},
  data: personData,
  fetchAllData,
}) => {
  const [data, onDataChange, resetData] = useForm({
    ...egressSchema,
  });

  const { CatReasonEmployeeInactive } = useSelector((s) => s.catalogues);

  const { idEmployee, name, employeeKey } = personData;

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { suspensionDate } = data;
    const res = await Request(
      `/employee/${idEmployee}`,
      {
        ...data,
        suspensionDate: parseObjectDate(sanitizeDate(suspensionDate)),
      },
      "PATCH"
    );
    if (res.ok) {
      Swal.fire("Baja realizada con éxito", "", "success").then(() => {
        resetData();
        fetchAllData();
        toggle();
      });
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="text-center  justify-center">
        <div className="text-xl text-sesGray ml-2">BAJA DE PERSONAL</div>
      </ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody style={{ padding: "1.5rem" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={iconoDetalleEmpleado} style={{ width: "3.5rem" }} />
              <div
                style={{
                  marginLeft: "1rem",
                  fontWeight: 600,
                  color: "#2A2F99",
                }}
              >
                {name}
                <br />
                {employeeKey}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
            }}
            className="mt-2"
          >
            <div
              style={{
                marginLeft: "0.5rem",
                color: "#2A2F99",
                fontWeight: 600,
              }}
            >
              ESTATUS ACTUAL
            </div>
            <div
              style={{
                marginLeft: "0.5rem",
                color: "#FFFFFF",
                fontWeight: "600",
                backgroundColor: "#0CBE13",
              }}
              className="px-4 rounded-md mt-1"
            >
              ACTIVO
            </div>
          </div>
          <DgInput
            type="select"
            label="Motivo de la baja"
            options={[
              { label: "Selecciona un motivo", value: "" },
              ...parseCat(
                CatReasonEmployeeInactive,
                "name",
                "idCatReasonEmployeeInactive"
              ),
            ]}
            name="idCatReasonEmployeeInactive"
            onChange={onDataChange}
            required={true}
            iconName={"CreditCard"}
          />
          <DgInput
            type="textarea"
            label="Comentarios u observaciones de la baja"
            placeholder={"..."}
            name="commentSuspension"
            onChange={onDataChange}
            iconName={"XCircle"}
          />
          <DgInput
            label="Fecha de baja"
            type="date"
            name="suspensionDate"
            max={parseObjectDate(TODAY, 4)}
            onChange={onDataChange}
            iconName={"Calendar"}
          />
        </ModalBody>
        <ModalFooter>
          <div className="flex space-between w-full mb-4">
            <DgButton
              onClick={toggle}
              color="secondary"
              innerClassName="px-4 py-2 font-regular w-3/4 ml-2"
            >
              Cancelar
            </DgButton>
            <DgButton
              type="submit"
              innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
            >
              Dar de baja
            </DgButton>
          </div>
        </ModalFooter>
      </form>
    </Modal>
  );
};

ModalEgressPerson.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalEgressPerson;
