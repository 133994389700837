import React from "react";
import {
  getColorInvoice,
  getColorPayment,
  getColorPaymentComplement,
  parseObjectDate,
} from "./utils";
import {
  Wallet,
  Edit,
  Paperclip,
  Mail,
  Eye,
  Banknote,
  Contact,
  PlusCircle,
  Trash2,
  CheckSquare,
  Slash,
  XCircle,
  FileDigit,
  Landmark,
  ShieldCheck,
  Luggage,
  CalendarDays,
  FilePlus2,
  Info,
  CheckCircle2,
  FileX2,
  Files,
  View,
  FileX,
  FileOutput,
  ToggleLeft,
  FileMinus,
  CreditCard,
  StickyNote,
  ClipboardList,
  Gift,
} from "lucide-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Checkbox, Switch, Tooltip } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import PaymentLabel from "../components/PaymentLabel";

const customCell = (
  selector,
  alignment = "left",
  renderText = (text) => {
    if (text === 0) return 0;
    if (!text) return "-";
    return text;
  }
) => {
  return (row) => (
    <div style={{ textAlign: alignment, width: "100%" }}>
      {renderText(row[selector])}
    </div>
  );
};

export const getColumnsClients = (funcs) => [
  {
    name: "NO.",
    center: true,
    width: "5%",
    key: "serviceNumber",
    cell: customCell("serviceNumber", "center"),
  },
  {
    name: "NOMBRE COMERCIAL",
    width: "16%",
    center: true,
    key: "tradeName",
    cell: customCell("tradeName", "center"),
  },
  {
    name: "RAZÓN SOCIAL",
    width: "12%",
    center: true,
    key: "businessName",
    cell: customCell("businessName"),
  },
  {
    name: "RFC",
    width: "14%",
    center: true,
    key: "rfc",
    cell: customCell("rfc", "center"),
  },
  {
    name: "DIRECCIÓN",
    width: "14%",
    center: true,
    key: "address",
    cell: customCell("address"),
  },
  {
    name: "ES FISCAL",
    width: "12%",
    center: true,
    key: "conceptsServices",
    cell: (row) => {
      return row.clientType === "FISCAL" ? (
        <FontAwesomeIcon icon={faCheck} size={"2x"} color="green" />
      ) : (
        <FontAwesomeIcon icon={faTimes} size={"2x"} color="orange" />
      );
    },
  },
  {
    name: "FECHA DE PAGO",
    width: "10%",
    center: true,
    key: "estimatedPaymentDate",
    //cell: customCell("estimatedPaymentDate", "left", (t) => (t ? parseObjectDate(new Date(t), 3) : '' )),
    cell: (row) => (
      <>
        {row.balance != null ? (
          row.idCatStatusPaymentService == 2 ? (
            <span className={`text-sesRed `} color="#d9534f" size={20}>
              {parseObjectDate(new Date(row.estimatedPaymentDate), 3)}
            </span>
          ) : (
            <span color="#d9534f" size={20}>
              {parseObjectDate(new Date(row.estimatedPaymentDate), 3)}
            </span>
          )
        ) : (
          ""
        )}
      </>
    ),
  },
  {
    name: "SALDO",
    width: "8%",
    center: true,
    key: "balance",
    //cell: customCell("balance", "left", (t) => (t ? "$ "+t : "$ -")),
    cell: (row) => (
      <>
        {row.balance != null ? (
          row.idCatStatusPaymentService == 2 ? (
            <span className={`text-sesRed `} color="#d9534f" size={20}>
              $ {row.balance}
            </span>
          ) : (
            <span color="#d9534f" size={20}>
              $ {row.balance}
            </span>
          )
        ) : (
          ""
        )}
      </>
    ),
  },
  {
    name: "ACCIONES",
    width: "9%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        {/*<ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.service(row);
          }}
          icon={faMoneyBill}
        />
         <ButtonIcon
          className="px-2"
          onClick={() => {
            funcs.mail(row);
          }}
          icon={faMailBulk}
        />*/}
        <Tooltip title="Ver detalle de cliente">
          <Eye
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={() => {
              funcs.details(row);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        {/* <ButtonIcon
          className="px-1"
          onClick={() => {
            funcs.details(row);
          }}
          icon={AlertCircle}
        /> */}
        {row.idService && (
          <Tooltip title="Ver detalle de Servicio">
            <Wallet
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.serviceDetails(row);
              }}
            />
          </Tooltip>
          // <ButtonIcon
          //   className="px-1"
          //   onClick={() => {
          //     funcs.serviceDetails(row);
          //   }}
          //   icon={faFileLines}
          // />
        )}
        {row.idService && (
          <Tooltip title="Enviar Correo">
            <Mail
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.mail(row);
              }}
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const getColumnsInvoices = (funcs, { allSelected }) => [
  {
    name: <Checkbox checked={allSelected} onChange={funcs.checkAll} />,
    width: "4%",
    center: true,
    cell: (row) => {
      return (
        <Checkbox checked={row.selected} onChange={funcs.onSelectCheck(row)} />
      );
    },
  },
  {
    name: "RAZÓN SOCIAL / SERVICIO",
    width: "15%",
    center: true,
    key: "nameClient",
    cell: (row) => {
      return (
        <div className="w-full">
          <span className="text-sm">{row.nameClient}</span>
          <br />
          <b className="text-md">{row.nameService}</b>
        </div>
      );
    },
  },
  {
    name: "TIPO",
    width: "6%",
    center: true,
    key: "clientType",
    cell: customCell("clientType", "center", (t) => (
      <span className="text-xs">
        {t === "NONFISCAL" ? "NO FISCAL" : "FISCAL"}
      </span>
    )),
  },
  {
    name: "FACTURA",
    width: "9%",
    center: true,
    key: "invoiceFolioSes",
    cell: customCell("invoiceFolioSes", "center", (t) => (t ? t : "-")),
  },
  {
    name: "PERIODO",
    width: "10%",
    center: true,
    key: "fiscalPeriod",
    cell: customCell("fiscalPeriod"),
  },
  {
    name: "IMPORTE X PAGAR",
    width: "10%",
    center: true,
    key: "amount",
    cell: (row) => {
      let { amount, missingAmount } = row;
      missingAmount = missingAmount === null ? amount : missingAmount;
      const percentage = Math.floor((missingAmount * 100) / amount);
      return (
        <div className="w-full">
          <div>
            <ProgressBar
              className="w-full payment-progress"
              completed={percentage}
            />
          </div>
          <div className="text-center">
            <span className="test-xs">$ {missingAmount}</span>
          </div>
        </div>
      );
    },
  },
  {
    name: "ESTATUS FACT",
    width: "12%",
    center: true,
    key: "catStatusInvoiceService",
    cell: (row) => {
      return (
        <PaymentLabel
          text={row.catStatusInvoiceService}
          id={row.idCatStatusInvoiceService}
          parserColor={getColorInvoice}
        />
      );
    },
  },
  {
    name: "ESTATUS PAGO",
    width: "10%",
    center: true,
    key: "catStatusPaymentService",
    cell: (row) => {
      return (
        <PaymentLabel
          text={row.catStatusPaymentService}
          id={row.idCatStatusPaymentService}
          parserColor={getColorPayment}
        />
      );
    },
  },
  {
    name: "COMPLEMENTO",
    width: "10%",
    center: true,
    key: "catStatusPaymentComplementService",
    cell: (row) => {
      return (
        <PaymentLabel
          text={row.catStatusPaymentComplementService}
          id={row.idCatStatusPaymentComplementService}
          parserColor={getColorPaymentComplement}
        />
      );
    },
  },
  {
    name: "ACCIONES",
    width: "14%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        <Tooltip title="Ver detalle de Cliene">
          <Contact
            color="#2A2F99"
            size={20}
            className="m-auto action-table"
            style={{ cursor: "pointer" }}
            onClick={funcs.details(row)}
          />
        </Tooltip>
        {[1, 4].includes(row.idCatStatusInvoiceService) && (
          <Tooltip title="Editar Servicio">
            <Edit
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.edit(row)}
            />
          </Tooltip>
        )}
        {[2, 3].includes(row.idCatStatusInvoiceService) && (
          <Tooltip
            title={`Ver ${
              row.clientType === "FISCAL" ? "Factura" : "Remisión"
            }`}
          >
            <Eye
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              onClick={funcs.invoiceDetails(row)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        )}
        {row.urlNonFiscalInvoice && (
          <Tooltip title={`Ver Factura`}>
            <View
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              onClick={funcs.invoiceDetails({
                ...row,
                urlInvoice: row.urlNonFiscalInvoice,
              })}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        )}
        {![3, 4].includes(row.idCatStatusPaymentService) ? (
          <Tooltip title="Registrar pago">
            <Banknote
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.payment(row)}
            />
          </Tooltip>
        ) : null}

        <Tooltip title="Ver pagos">
          <Landmark
            color="#2A2F99"
            size={20}
            className="m-auto action-table"
            style={{ cursor: "pointer" }}
            onClick={funcs.listPayments(row)}
          />
        </Tooltip>

        {![3, 4].includes(row.idCatStatusPaymentService) &&
        row.clientType === "FISCAL" ? (
          <Tooltip title="Registrar nota de crédito">
            <StickyNote
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.creditNote(row)}
            />
          </Tooltip>
        ) : null}
        {row.clientType === "FISCAL" && (
          <Tooltip title="Ver notas de crédito">
            <ClipboardList
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.openCreditNotes(row)}
            />
          </Tooltip>
        )}

        {row.clientType === "FISCAL" &&
          row.idCatStatusInvoiceService === 2 &&
          !row.urlPaymentComplement && (
            <Tooltip title="Cancelar factura">
              <FileX
                color="#2A2F99"
                size={20}
                className="m-auto action-table"
                style={{ cursor: "pointer" }}
                onClick={funcs.cancel(row)}
              />
            </Tooltip>
          )}

        {row.clientType === "NONFISCAL" && row.urlNonFiscalInvoice && (
          <Tooltip title="Cancelar factura">
            <FileX
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.cancelGeneral(row)}
            />
          </Tooltip>
        )}

        {[2, 3, 4].includes(row.idCatStatusInvoiceService) &&
          [1, 2, 5].includes(row.idCatStatusPaymentService) &&
          !row.urlPaymentComplement &&
          row.clientType === "FISCAL" && (
            <Tooltip title={"Refacturar"}>
              <FileOutput
                color="#2A2F99"
                size={20}
                className="m-auto action-table"
                style={{ cursor: "pointer" }}
                onClick={funcs.rebill(row)}
              />
            </Tooltip>
          )}

        {row.clientType === "NONFISCAL" &&
          row.idCatStatusInvoiceService === 3 &&
          !row.urlNonFiscalInvoice &&
          [3, 4].includes(row.idCatStatusPaymentService) && (
            <Tooltip title={"Facturar"}>
              <Files
                color="#2A2F99"
                size={20}
                className="m-auto action-table"
                style={{ cursor: "pointer" }}
                onClick={funcs.remissionInvoice(row)}
              />
            </Tooltip>
          )}
        {row.clientType === "NONFISCAL" &&
          row.idCatStatusInvoiceService === 3 && (
            <Tooltip title={"Cancelar Remisión"}>
              <FileX
                color="#2A2F99"
                size={20}
                className="m-auto action-table"
                style={{ cursor: "pointer" }}
                onClick={funcs.cancelRemission(row)}
              />
            </Tooltip>
          )}
      </>
    ),
  },
];

export const getColumnsNewUser = (funcs) => [
  {
    name: "MÓDULO",
    center: true,
    width: "25%",
    key: "idFunction",
    cell: (row) => <div>{row.module}</div>,
  },
  {
    name: "SUBMÓDULO",
    center: true,
    width: "25%",
    key: "idFunction",
    cell: (row) => <div>{row.submodule}</div>,
  },
  {
    name: "PERMISO",
    center: true,
    width: "35%",
    key: "name",
    cell: (row) => <div>{row.name}</div>,
  },
  {
    name: <ToggleLeft color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "createFunction",
    cell: (row) => {
      return (
        <Switch
          inputProps={{ "aria-label": "Switch demo" }}
          onChange={funcs.handleActiveChange(row)}
          disabled={funcs.disabledSwitchs}
          checked={row.visibility}
        />
      );
    },
  },
];

export const getColumnsNewUserSubmodule = (funcs) => [
  {
    name: "",
    center: true,
    width: "8.4%",
    key: "idSpatial",
    cell: (row) => <div></div>,
  },
  {
    name: "FUNCIONALIDAD",
    center: true,
    width: "30%",
    key: "idFunction",
    cell: (row) => (
      <div>
        <span
          className="ml-1"
          style={{ opacity: row.isActiveUnit === 0 ? 0.3 : 1 }}
        >
          {row.idFunction}
        </span>
      </div>
    ),
  },
  {
    name: <Eye color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "watchFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: <PlusCircle color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "createFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: (
      <Tooltip title="Editar Usuario">
        <Edit color="white" size={28} className="m-auto" />
      </Tooltip>
    ),
    width: "15%",
    center: true,
    key: "editFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: <Trash2 color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "deleteFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
];

export const getColumnsEditUser = (funcs) => [
  {
    name: "FUNCIONALIDAD",
    center: true,
    width: "30%",
    key: "idFunction",
    cell: (row) => (
      <div>
        <span
          className="ml-1"
          style={{ opacity: row.isActiveUnit === 0 ? 0.3 : 1 }}
        >
          {row.idFunction}
        </span>
      </div>
    ),
  },
  {
    name: <Eye color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "watchFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: <PlusCircle color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "createFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: (
      <Tooltip title="Editar Usuario">
        <Edit color="white" size={28} className="m-auto" />
      </Tooltip>
    ),
    width: "15%",
    center: true,
    key: "editFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
  {
    name: <Trash2 color="white" size={28} className="m-auto" />,
    width: "15%",
    center: true,
    key: "deleteFunction",
    cell: (row) => {
      return (
        <Checkbox
          checked
          icon={<Slash color="red" size={28} className="m-auto" />}
          checkedIcon={
            <CheckSquare color="green" size={28} className="m-auto" />
          }
          /*onChange={funcs.onSelectCheck(row)}*/
        />
      );
    },
  },
];

export const getColumnsPersonal = (funcs) => [
  {
    name: "NÚM. EMPLEADO",
    center: true,
    width: "5%",
    key: "employeeKey",
    cell: customCell("employeeKey", "center"),
  },
  {
    name: "NOMBRE",
    width: "15%",
    center: true,
    key: "name",
    cell: customCell("name"),
  },
  {
    name: "ÚLTIMO SERVICIO",
    width: "10%",
    center: true,
    key: "serviceName",
    cell: customCell("serviceName"),
  },
  {
    name: "SUPERVISOR",
    width: "10%",
    center: true,
    key: "supervisor",
    cell: customCell("supervisor"),
  },
  {
    name: "FECHA INGRESO",
    width: "10%",
    center: true,
    key: "admissionDate",
    cell: customCell("admissionDate", "center"),
  },
  {
    name: "FECHA BAJA",
    width: "11%",
    center: true,
    key: "inactiveDate",
    cell: customCell("inactiveDate", "center", (t) => t || "-"),
  },
  {
    name: "ROL",
    width: "10%",
    center: true,
    key: "role",
    cell: customCell("role", "center", (t) => t || "-"),
  },
  {
    name: "ESTATUS",
    width: "9%",
    center: true,
    key: "status",
    cell: (row) => {
      return row.isActive === 1 ? (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div className="activePerson"></div>
          Activo
        </div>
      ) : (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div className="inactivePerson"></div>
          Inactivo
        </div>
      );
    },
  },
  {
    name: "ACCIONES",
    width: "20%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        <Tooltip title="Asignar servicio">
          <ShieldCheck
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={() => {
              funcs.modalAssignService(row);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Registrar incidencias">
          <CalendarDays
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalIncidenceLog(row);
            }}
          />
        </Tooltip>
        {funcs.userPermissions.includes(10) ? (
          <Tooltip title="Registrar días de vacaciones">
            <Luggage
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.modalVacations(row);
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}

        <Tooltip title="Registro de compensaciones">
          <Gift
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalCompensations(row);
            }}
          />
        </Tooltip>
        {funcs.userPermissions.includes(11) ? (
          <Tooltip title="Registro de doblete">
            <FilePlus2
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.modalDoubletRegister(row);
              }}
            />
          </Tooltip>
        ) : (
          <></>
        )}

        <Tooltip title="Ver archivos adjuntos">
          <Paperclip
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalAttachedFiles(row);
            }}
          />
        </Tooltip>
        <Tooltip title="Ver detalle de empleado">
          <Info
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.openPersonDetail(row);
            }}
          />
        </Tooltip>
        {row.isActive === 1 ? (
          <Tooltip title="Dar de baja">
            <Slash
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.modalEgress(row);
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Reingresar al sistema">
            <CheckCircle2
              color="#2A2F99"
              size={20}
              style={{ cursor: "pointer" }}
              className="m-auto"
              onClick={() => {
                funcs.modalEntrance(row);
              }}
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const getColumnsServicesHistoric = (funcs) => [
  {
    name: "NÚM. EMPLEADO",
    center: true,
    width: "9%",
    key: "employeeKey",
    cell: customCell("employeeKey", "center"),
  },
  {
    name: "NOMBRE",
    width: "15%",
    center: true,
    key: "nameEmployee",
    cell: customCell("nameEmployee"),
  },
  {
    name: "SERVICIO",
    width: "15%",
    center: true,
    key: "serviceName",
    cell: customCell("serviceName"),
  },
  {
    name: "FECHA DE INICIO",
    width: "13%",
    center: true,
    key: "startDate",
    cell: customCell("startDate", "center"),
  },
  {
    name: "FECHA DE TÉRMINO",
    width: "13%",
    center: true,
    key: "endDate",
    cell: customCell("endDate", "center"),
  },
  {
    name: "TURNO",
    width: "13%",
    center: true,
    key: "shift",
    cell: customCell("shift", "center"),
  },
  {
    name: "ESTATUS",
    width: "13%",
    center: true,
    key: "isActive",
    cell: (row) => {
      return row.isActive === 1 ? (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div className="activePerson"></div>
          Activo
        </div>
      ) : (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div className="inactivePerson"></div>
          Inactivo
        </div>
      );
    },
  },
  {
    name: "DETALLE",
    width: "9%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        <Tooltip title="Ver detalle de servicio">
          <Info
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.openPersonDetail(row);
            }}
          />
        </Tooltip>
      </>
    ),
  },
];

export const getColumnsUsers = (funcs) => [
  {
    name: "NOMBRE DEL USUARIO",
    center: true,
    width: "16%",
    key: "name",
    cell: customCell("name"),
  },
  {
    name: "CORREO ELECTRÓNICO",
    width: "18%",
    center: true,
    key: "email",
    cell: customCell("email"),
  },
  {
    name: "ROL",
    width: "12%",
    center: true,
    key: "rol",
    cell: customCell("rol"),
  },
  {
    name: "ÚLTIMO INICIO DE SESIÓN",
    width: "14%",
    center: true,
    key: "lastSession",
    cell: customCell("lastSession", "center", (t) =>
      t ? parseObjectDate(new Date(t), 2) : "-"
    ),
  },
  {
    name: "ÚLTIMA ACCIÓN",
    width: "16%",
    center: true,
    key: "lastAction",
    cell: customCell("lastAction", "center", (t) => t || "-"),
  },
  {
    name: "ESTATUS",
    width: "12%",
    center: true,
    key: "status",
    cell: (row) => {
      const isActive = row.status === "Activo";
      const isBlocked = row.status === "Bloqueado";
      const color = isActive ? "success" : "error";
      return (
        <div style={{ textAlign: "center", width: "100%" }}>
          {/* <div
            className={
              row.status === "Activo" ? "activePerson" : "inactivePerson"
            }
          ></div>
          */}
          <Switch
            color={color}
            checked={isActive}
            disabled={isBlocked}
            onChange={funcs.activeChange(row)}
          />
          <br />
          <span className="text-xs">{row.status}</span>
        </div>
      );
    },
  },
  /*{
    name: "PERMISOS",
    width: "12%",
    center: true,
    key: "permissions",
    cell: (row) => {
      return (
        <div style={{ textAlign: "left", width: "100%" }}>
          <div>{row.permissions}</div>
          <div style={{ color: "blue", cursor: "pointer" }}>Ver detalle</div>
        </div>
      );
    },
  },*/
  {
    name: "ACCIONES",
    width: "12%",
    center: true,
    className: "containerAction",
    cell: (row) => {
      const isBlocked = row.status === "Bloqueado";
      return (
        <>
          <Tooltip title="Editar usuario">
            <Edit
              color="#2A2F99"
              size={20}
              className="m-auto"
              onClick={funcs.edit(row)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
          {!isBlocked && (
            <Tooltip title="Bloquear usuario">
              <Slash
                color="#2A2F99"
                size={20}
                className="m-auto"
                onClick={funcs.block(row)}
                style={{ cursor: "pointer" }}
              />
            </Tooltip>
          )}
          {/* <Tooltip title="Activar/Desactivar">
          <Switch defaultChecked={row.status == 1} />
    </Tooltip>*/}
        </>
      );
    },
  },
];

export const getColumnsPayments = (funcs) => [
  {
    name: "FECHA DE PAGO",
    center: true,
    width: "25%",
    key: "paymentDate",
    cell: customCell("paymentDate", "center", (t) =>
      parseObjectDate(new Date(t.split("T")[0] + "T12:00:00"))
    ),
  },
  {
    name: "MONTO",
    width: "25%",
    center: true,
    key: "amount",
    cell: customCell("amount", "center", (t) => t + " $"),
  },
  {
    name: "ESTATUS",
    width: "25%",
    center: true,
    key: "amount",
    cell: (row) => {
      return row.isActive ? "Activo" : "Cancelado";
    },
  },
  {
    name: "ACCIONES",
    width: "25%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        {row.isActive && !row.uuidPaymentComplementService && (
          <Tooltip title="Cancelar pago">
            <XCircle
              color="#2A2F99"
              size={20}
              className="m-auto"
              onClick={funcs.delete(row)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        )}
        {row.isActive && row.uuidPaymentComplementService && (
          <Tooltip title="Ver complemento de pago">
            <FileDigit
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.paymentDocument(row)} // funcs.generatePaymentComplement(row)
            />
          </Tooltip>
        )}
        {row.isActive && row.uuidPaymentComplementService && (
          <Tooltip title="Cancelar Complemento Pago">
            <FileX2
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.cancelPaymentComplement(row)}
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const getColumnsCreditNotes = (funcs) => [
  {
    name: "MONTO",
    width: "33%",
    center: true,
    key: "amount",
    cell: customCell("amount", "center", (t) => t + " $"),
  },
  {
    name: "ESTATUS",
    width: "34%",
    center: true,
    key: "amount",
    cell: (row) => {
      return row.isActive ? "Activo" : "Cancelado";
    },
  },
  {
    name: "ACCIONES",
    width: "33%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        {row.isActive && row.urlCreditNote && (
          <Tooltip title="Ver nota de crédito">
            <FileDigit
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.paymentDocument(row)}
            />
          </Tooltip>
        )}
        {row.isActive && row.uuidCreditNote && (
          <Tooltip title="Cancelar nota de crédito">
            <FileX2
              color="#2A2F99"
              size={20}
              className="m-auto action-table"
              style={{ cursor: "pointer" }}
              onClick={funcs.cancelCreditNote(row)}
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const getColumnsDeposits = (funcs) => [
  {
    name: "FECHA DE DEPÓSITO",
    center: true,
    width: "25%",
    key: "paymentDate",
    cell: customCell("paymentDate", "center", (t) =>
      parseObjectDate(new Date(t))
    ),
  },
  {
    name: "MONTO",
    width: "25%",
    center: true,
    key: "amount",
    cell: customCell("amount", "center", (t) => t + " $"),
  },
  {
    name: "ESTATUS",
    width: "25%",
    center: true,
    key: "amount",
    cell: (row) => {
      return row.isActive ? "Activo" : "Cancelado";
    },
  },
  {
    name: "ACCIONES",
    width: "25%",
    center: true,
    className: "containerAction",
    cell: (row) => (
      <>
        {row.isActive && (
          <Tooltip title="Cancelar pago">
            <XCircle
              color="#2A2F99"
              size={20}
              className="m-auto"
              onClick={funcs.delete(row)}
              style={{ cursor: "pointer" }}
            />
          </Tooltip>
        )}
      </>
    ),
  },
];

export const getColumnsPaySheet = (funcs) => [
  {
    name: "CAS",
    center: true,
    width: "6%",
    key: "employeeKey",
    cell: customCell("employeeKey", "center"),
  },
  {
    name: "NOMBRE",
    width: "10%",
    center: true,
    key: "employeeName",
    cell: customCell("employeeName"),
  },
  {
    name: "SERVICIO",
    width: "8%",
    center: true,
    key: "serviceName",
    cell: customCell("serviceName"),
  },
  {
    name: "ZONA",
    width: "5%",
    center: true,
    key: "zone",
    cell: customCell("zone"),
  },
  {
    name: "TURNOS",
    width: "10%",
    center: true,
    key: "shift",
    cell: customCell("shift", "center"),
  },
  {
    name: "SUELDO MENSUAL",
    width: "10%",
    center: true,
    key: "monthlyNetSalary",
    cell: customCell("monthlyNetSalary", "center"),
  },
  {
    name: "SUELDO QUINCENAL",
    width: "10%",
    center: true,
    key: "payrollAmount",
    cell: customCell("payrollAmount", "center"),
  },
  {
    name: "SUELDO DIARIO",
    width: "10%",
    center: true,
    className: "dailyNetSalary",
    cell: customCell("dailyNetSalary", "center"),
  },
  {
    name: "COMPENSACIÓN QUINCENAL",
    width: "12%",
    center: true,
    key: "biweeklyCompensation",
    cell: customCell("biweeklyCompensation", "center"),
  },
  {
    name: "AYUDA DE TRANSPORTE",
    width: "10%",
    center: true,
    key: "transportHelp",
    cell: customCell("transportHelp", "center"),
  },
  {
    name: "DIAS LABORADOS",
    width: "10%",
    center: true,
    key: "workedDays",
    cell: customCell("workedDays", "center"),
  },
  {
    name: "FALTAS/ INCAPACIDADES",
    width: "20",
    center: true,
    key: "absencesDisabilitiesNumber",
    cell: customCell("absencesDisabilitiesNumber", "center"),
  },
  {
    name: "IMPORTE POR FALTAS",
    width: "10%",
    center: true,
    key: "absencesDisabilities",
    cell: customCell("absencesDisabilities", "center"),
  },
  {
    name: "OTRO DESCUENTO",
    width: "10%",
    center: true,
    key: "otherDiscounts",
    cell: customCell("otherDiscounts", "center"),
  },
  {
    name: "VACACIONES",
    width: "10%",
    center: true,
    key: "holidays",
    cell: customCell("holidays", "center"),
  },
  {
    name: "PRIMA VACACIONAL",
    width: "10%",
    center: true,
    key: "holidayAmount",
    cell: customCell("holidayAmount", "center"),
  },
  {
    name: "AGUINALDO",
    width: "10%",
    center: true,
    key: "endYearBonus",
    cell: customCell("endYearBonus", "center"),
  },
  {
    name: "OBSERVACIONES",
    width: "10%",
    center: true,
    key: "comments",
    cell: customCell("comments", "center", (t) => t || "No hay observaciones"),
  },
  {
    name: "FECHA DE INGRESO",
    width: "12%",
    center: true,
    key: "entryDate",
    cell: customCell("entryDate", "center"),
  },
  {
    name: "TOTAL A PAGAR",
    width: "15%",
    center: true,
    key: "payrollAmount",
    cell: (row) => {
      return (
        <div>
          <div>{row.payrollAmount}</div>
          <div>{row.catStatusPaymentPayroll}</div>
        </div>
      );
    },
  },
  {
    name: "NÚMERO DE CUENTA",
    width: "10%",
    center: true,
    key: "accountNumber",
    cell: customCell("accountNumber", "center"),
  },
  {
    name: "ACCIONES",
    width: "8%",
    center: true,
    key: "actions",
    cell: (row) => (
      <>
        <Tooltip title="Registrar incidencias">
          <CalendarDays
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalIncidenceLog(row);
            }}
          />
        </Tooltip>
        <Tooltip title="Detalle de empleado">
          <Info
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={() => {
              funcs.openPersonDetail(row);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Cambiar estatus de pago">
          <CheckCircle2
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalPaysheetStatus(row);
            }}
          />
        </Tooltip>
      </>
    ),
  },
];

export const getColumnsDoubletView = (funcs) => [
  {
    name: "CAS",
    center: true,
    width: "8%",
    key: "employeeKey",
    cell: customCell("employeeKey", "center"),
  },
  {
    name: "NOMBRE",
    width: "15%",
    center: true,
    key: "employeeName",
    cell: customCell("employeeName"),
  },
  {
    name: "SERVICIO",
    width: "10%",
    center: true,
    key: "serviceName",
    cell: customCell("serviceName"),
  },
  {
    name: "ZONA",
    width: "7%",
    center: true,
    key: "zone",
    cell: customCell("zone", "center"),
  },
  {
    name: "24 HRS",
    width: "6%",
    center: true,
    key: "count24",
    cell: customCell("count24", "center"),
  },
  {
    name: "$550",
    width: "6%",
    center: true,
    key: "amount24",
    cell: customCell("amount24", "center", (r) => `${r} $`),
  },
  {
    name: "12 HRS",
    width: "6%",
    center: true,
    key: "count12",
    cell: customCell("count12", "center"),
  },
  {
    name: "$280",
    width: "6%",
    center: true,
    key: "amount12",
    cell: customCell("amount12", "center", (r) => `${r} $`),
  },
  {
    name: "FMF",
    width: "6%",
    center: true,
    key: "countFMF",
    cell: customCell("countFMF", "center"),
  },
  {
    name: "$350",
    width: "6%",
    center: true,
    key: "amountFMF",
    cell: customCell("amountFMF", "center", (r) => `${r} $`),
  },
  {
    name: "APOYO COMIDA",
    width: "10%",
    center: true,
    key: "foodHelpAmount",
    cell: customCell("foodHelpAmount", "center", (r) => `${r} $`),
  },
  {
    name: "TOTAL DE APOYOS DE COMIDA",
    width: "10%",
    center: true,
    key: "totalFoodHelpAmount",
    cell: customCell("totalFoodHelpAmount", "center", (r) => `${r}`),
  },
  {
    name: "ACCIONES",
    width: "8%",
    center: true,
    key: "actions",
    cell: (row) => (
      <>
        <Tooltip title="Registrar incidencias">
          <CalendarDays
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalIncidenceLog(row);
            }}
          />
        </Tooltip>
        <Tooltip title="Detalle de empleado">
          <Info
            color="#2A2F99"
            size={20}
            className="m-auto"
            onClick={() => {
              funcs.openPersonDetail(row);
            }}
            style={{ cursor: "pointer" }}
          />
        </Tooltip>
        <Tooltip title="Cambiar estatus de pago">
          <CheckCircle2
            color="#2A2F99"
            size={20}
            style={{ cursor: "pointer" }}
            className="m-auto"
            onClick={() => {
              funcs.modalPaysheetStatus(row);
            }}
          />
        </Tooltip>
      </>
    ),
  },
];
