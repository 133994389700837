import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { Request } from "../../../helpers/api";
import { enumFileTypes } from "../../../helpers/enums";
import iconoSubirArchivo from "../../../css/img/IconoSubirArchivo.svg";
import Button from "../../../components/Button";
import DocumentDetails from "../ModalDocumentDetails";
import Swal from "sweetalert2";
import DocumentCard from "../../../components/DocumentCard";

const Documents = ({ clientData, setClientAllData }) => {
  const { idClient, documents = [] } = clientData;
  const [file, setFile] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [singleData, setSingleData] = useState({});
  const handleChange = (file) => {
    setFile(file);
  };
  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await Request(
      "/client/" + idClient + "/document",
      formData,
      "POST",
      true,
      true
    );
    if (res.ok) {
      Swal.fire("Documento cargado exitosamente", "", "success").then(() => {
        const newDocuments = [...documents];
        newDocuments.push(res.data);
        setClientAllData({
          ...clientData,
          documents: newDocuments,
        });
        setFile();
      });
    }
  };
  const handleDetail = (d) => {
    setSingleData(d);
    setModalOpen(true);
  };
  const handleDelete = (d, ix) => {
    Swal.fire(
      {title: "¿Seguro que deseas eliminar éste documento?",
      text: d.name,
      icon: "warning", showCancelButton: true}
    ).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        const { ok } = await Request(
          "/client/document/" + d.idDocument,
          { isActive: false },
          "PATCH"
        );
        if (ok) {
          Swal.fire({title: "Documento eliminado con éxito", text: "", icon: "success"}).then(() => {
            const newDocuments = [...documents];
            newDocuments.splice(ix, 1);
            setClientAllData({ ...clientData, documents: newDocuments });
          });
        }
      }
    });
  };
  return (
    <>
      <div className="titulosDetalle">Gestionar Documentos del Cliente</div>

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div style={{ width: "50%" }}>
          <div
            style={{
              width: "90%",
              height: "15rem",
              boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
              border: "solid 0.5px lightgrey",
              borderRadius: "1vw",
              padding: "1vw",
            }}
          >
            <FileUploader
              classes="block cursor-pointer"
              style={{ display: "block" }}
              handleChange={handleChange}
              name="document"
              types={enumFileTypes}
            >
              <div
                style={{
                  color: "black",
                  fontWeight: "bolder",
                  fontStyle: "italic",
                  textAlign: "center",
                }}
              >
                {file 
                  ? file.name
                  : "No hay datos para mostrar, por favor cargue un archivo"}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "1rem",
                }}
              >
                <img src={iconoSubirArchivo} style={{ width: "7rem" }} />
              </div>
              <div style={{ textAlign: "center" }}>Arrastre su archivo</div>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "0.8rem",
                  opacity: 0.7,
                }}
              >
                o elija desde su despositivo
              </div>
            </FileUploader>
            <br/>
            <Button onClick={handleUpload}>Cargar</Button>
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <div
            style={{
              marginTop: "2rem",
              color: "#2A2F99",
              fontWeight: 600,
              margin: "1rem 0rem",
            }}
          >
            LISTA DE DOCUMENTOS
          </div>
          {documents
            .filter((d) => d.isActive)
            .map((d, ix) => (
              <DocumentCard
                document={d}
                handlers={{ handleDelete, handleDetail }}
                ix={ix}
              />
            ))}
        </div>
      </div>
      <DocumentDetails
        document={singleData}
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
      />
    </>
  );
};

export default Documents;
