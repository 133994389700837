import React, { useEffect, useState } from "react";
import { Request } from "../../helpers/api";
import { parseCat, parseInvoiceErrors } from "../../helpers/utils";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { enumPaymentMethods } from "../../helpers/enums";
import ClientCard from "../../components/ClientCard";
import DgInput from "../../components/DgInput";
import Button from "../../components/Button";
import Swal from "sweetalert2";
import ModalDetails from "./modals/ModalDetails";
import Loader from "../../components/Loader";

const InvoiceClients = ({ services = [] }) => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [singleData, setSingleData] = useState({});
  const [modalOpenInvoice, setModalOpenInvoice] = useState(false);
  const { serviceConcepts: catConcepts } = useSelector((s) => s.catalogues);
  const fetchData = useCallback(async (ix, ac) => {
    const newAc = [...ac];
    const s = services[ix];
    if (s) {
      let service = {};
      setIsLoading(true);
      const res = await Request("/service/" + s.idService);
      if (res.ok) {
        const { data } = res;
        const { serviceConcepts } = data;
        service = {
          ...data,
          serviceConcepts: serviceConcepts.map((sc) => ({
            ...sc,
            absenceDiscount: sc.absenceDiscount || 0,
          })),
        };
        const resC = await Request("/client/" + s.idClient);
        setIsLoading(false);
        if (resC.ok) {
          service.client = resC.data;
          service.paymentMethod = resC.data.paymentMethod;
          newAc.push(service);
          const newIx = ix + 1;
          if (newIx < services.length) {
            fetchData(newIx, newAc);
          } else {
            setData(newAc);
          }
        }
      } else {
        setIsLoading(false);
      }
    }
  }, []);
  const onNestedChange = (sIx, ix) => (e) => {
    const { value, name } = e.target;
    const newData = [...data];
    const service = newData[sIx];
    const item = service.serviceConcepts[ix];
    item[name] = value;
    newData[sIx] = service;
    setData(newData);
  };
  const onChange = (ix) => (e) => {
    const newData = [...data];
    const service = newData[ix];
    service[e.target.name] = e.target.value;
    setData(newData);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { objupdateafacturar } = await Request(
      "/invoice",
      {
        invoices: data.map(({ idService, serviceConcepts, paymentMethod }) => ({
          idService,
          serviceConcepts,
          paymentMethod,
        })),
      },
      "POST"
    );
    setIsLoading(false);
    const errorsList = [];
    objupdateafacturar.forEach(
      ({ createdPdf, invoiced, remitted, nameService, msgInvoiced }) => {
        const completedInvoice = createdPdf && (invoiced || remitted);
        if (!completedInvoice) {
          errorsList.push({ nameService, msgInvoiced });
        }
      }
    );
    let completedServices = data.length - errorsList.length;
    const allInvoiced = errorsList.length === 0;
    Swal.fire({
      title: allInvoiced ? "Facturación exitosa" : "Facturación incompleta",
      html:
        `${completedServices} / ${data.length} Servicios Facturados <br><br>` +
        (!allInvoiced
          ? `${errorsList.map(
              (e) =>
                "<b>" +
                e.nameService +
                "</b>" +
                ": " +
                parseInvoiceErrors(e.msgInvoiced) +
                "<br><br>"
            )}`
          : ""),
      icon: allInvoiced ? "success" : "warning",
    }).then(() => {
      if (completedServices === 1 && completedServices === data.length) {
        setCompleted(true);
        setSingleData(objupdateafacturar[0]);
        setModalOpenInvoice(true);
      } else {
        navigate("/facturacion");
      }
    });
  };
  const createSelector = (c, ix) => {
    return (
      <DgInput
        type="select"
        name="paymentMethod"
        containerClassName={"my-0"}
        value={c.paymentMethod}
        onChange={onChange(ix)}
        options={[
          { value: "", label: "Selecciona un elemento" },
          ...enumPaymentMethods,
        ]}
        iconName="FormInput"
      />
    );
  };
  useEffect(() => {
    fetchData(0, []);
  }, [fetchData]);
  return (
    <div>
      <form className="w-full" onSubmit={handleSubmit}>
        <div className="grid grid-cols-3">
          <div>
            <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
              CLIENTES A FACTURAR
            </h2>
          </div>
          <div className="col-span-2">
            <h2 className="text-sesBlue font-semibold text-lg mt-2 mb-4">
              CONCEPTOS
            </h2>
          </div>
        </div>
        {data.map((s, sIx) => (
          <div className="grid grid-cols-3">
            <ClientCard
              md="12"
              client={s.client}
              bottomComponent={createSelector(s, sIx)}
            />
            <div className="col-span-2">
              {s.serviceConcepts?.map((sc, ix) => (
                <div
                  className="grid gap-2"
                  style={{ gridTemplateColumns: "20% 15% 15% 20% 28%" }}
                >
                  <DgInput
                    {...{
                      label: "Concepto de factura",
                      placeholder: "Concepto de factura",
                      type: "select",
                      options: [
                        { value: "", label: "Elige un concepto" },
                        ...parseCat(catConcepts, "name", "idCatServiceConcept"),
                      ],
                      name: "idCatServiceConcept",
                      required: true,
                      iconName: "Files",
                      value: sc.idCatServiceConcept,
                      onInputChange: onNestedChange(sIx, ix),
                      errorMessage: "Éste concepto de factura no es válido",
                    }}
                  />
                  <DgInput
                    {...{
                      label: "Cantidad",
                      placeholder: "2",
                      minLength: "1",
                      maxLength: "2",
                      type: "number",
                      name: "quantity",
                      required: true,
                      iconName: "Hash",
                      value: sc.quantity,
                      onInputChange: onNestedChange(sIx, ix),
                      errorMessage: "Indique una cantidad en el rango 1 a 99",
                    }}
                  />
                  <DgInput
                    {...{
                      label: "Precio base",
                      type: "money",
                      name: "price",
                      required: true,
                      value: sc.price,
                      onInputChange: onNestedChange(sIx, ix),
                      iconName: "DollarSign",
                    }}
                  />
                  <DgInput
                    {...{
                      label: "Descuento faltas",
                      type: "number",
                      disabled: false, //!sc.applyAbsence,
                      name: "absenceDiscount",
                      step: 0.1,
                      min: 0,
                      max: parseFloat(sc.price * sc.quantity),
                      required: true,
                      onInputChange: onNestedChange(sIx, ix),
                      value: sc.absenceDiscount,
                      iconName: "UserMinus",
                      defaultValue: 0,
                      errorMessage:
                        "Descuento requerido y no puede ser mayor al total",
                    }}
                  />
                  <DgInput
                    {...{
                      label: "Observaciones",
                      type: "text",
                      disabled: false,
                      name: "description",
                      required: false,
                      onInputChange: onNestedChange(sIx, ix),
                      value: sc.description,
                      iconName: "FormInput",
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="grid grid-cols-3 gap-4">
          <div />
          <div />
          <Button disabled={isLoading || completed} type="submit">
            Facturar
          </Button>
        </div>
      </form>
      <ModalDetails
        isOpen={modalOpenInvoice}
        toggle={() => {
          setModalOpenInvoice(!modalOpenInvoice);
          navigate("/facturacion");
        }}
        data={{ ...singleData, urlInvoice: singleData.urlPdf }}
      />
      <Loader isLoading={isLoading} />
    </div>
  );
};

InvoiceClients.propTypes = {};

export default InvoiceClients;
