import React, { useCallback, useEffect, useState } from "react";
import Skinpage from "../../components/Skinpage";
import Grid from "@mui/material/Grid";
import { FileText, CalendarDays, Paperclip, Luggage } from "lucide-react";
import iconoDetalleEmpleado from "../../css/img/IconoDetalleEmpleado.svg";
import fotografiaEmpleado from "../../css/img/Empleado.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SesTabPanel from "../../components/SesTabPanel";
import { useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import PersonInfo from "./person-detail-tabs/PersonInfo";
import PersonDocuments from "./person-detail-tabs/PersonDocuments";
import PersonIncidences from "./person-detail-tabs/PersonIncidences";
import PersonCalendar from "./person-detail-tabs/PersonCalendar";
import { Request } from "../../helpers/api";
import { getAge, parseObjectDate, sanitizeDate } from "../../helpers/utils";
import { genders } from "../../helpers/enums";

const PersonDetail = () => {
  const navigate = useNavigate();
  const { id: idPerson } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchData = useCallback(async () => {
    if (idPerson) {
      const res = await Request("/employee/" + idPerson);
      if (res.ok) {
        setData(res.data);
      }
    } else {
      navigate.goBack();
    }
  }, []);

  const tabCommonProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  useEffect(() => {
    fetchData();
  }, [fetchData, isLoading]);

  return (
    <Skinpage pageTitle={"DETALLE DE EMPLEADO"}>
      <div
        style={{ color: "#2A2F99", fontWeight: 500 }}
        className="relative top-4 text-lg"
      >
        DETALLE DE EMPLEADO
      </div>
      <Grid container spacing={2} style={{ marginTop: "1vw" }}>
        <Grid item xs={6}>
          <div
            style={{
              width: "100%",
              height: "18rem",
              boxShadow: "2px 2px 2px 1px rgba(0, 0, 0, 0.2)",
              border: "solid 0.5px lightgrey",
              borderRadius: "1vw",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "2rem",
                backgroundColor: "#2A2F99",
                borderTopLeftRadius: "1vw",
                borderBottomLeftRadius: "1vw",
              }}
            />
            <Grid
              container
              spacing={2}
              style={{
                height: "fit-content",
                marginTop: "0.01rem",
                marginLeft: "0.01rem",
              }}
            >
              <Grid item xs={2} style={{ padding: "0rem", margin: "0.5rem" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    padding: "0.5vw",
                  }}
                >
                  {/* <Skeleton variant="circular" width={80} height={80} /> */}
                  <img src={iconoDetalleEmpleado} style={{ width: "5rem" }} />
                </div>
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  padding: "0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                }}
              >
                <div className="text-sesBlue py-2 text-left mt-4">
                  <h4 className="font-bold py-0" style={{ fontSize: "1.2rem" }}>
                    {[
                      data.name,
                      data.paternalLastName,
                      data.maternalLastName,
                    ].join(" ")}
                  </h4>
                  <p className="font-regular py-0">{data.employeeKey}</p>
                </div>
              </Grid>
              <Grid item xs={6} style={{ padding: "1rem" }}>
                <div className="font-semibold mb-4 ml-2 text-sesGray">
                  <span className="relative">RFC: {data.rfc}</span>
                  <br />
                  {/* <span className="relative top-3">Servicio actual: </span>
                  <br />
                  <span className="relative top-3">{"-"}</span> */}
                </div>
              </Grid>

              {/*--------LADO DERECHO DE LA CARD-------*/}
              <Grid
                item
                xs={6}
                style={{ padding: "1rem", borderLeft: "solid 2px lightgrey" }}
              >
                <div className="font-semibold mb-4 ml-2">
                  <span className="relative text-sesGray">
                    Fecha de ingreso
                  </span>
                  <br />
                  <span className="relative text-sesBlue font-bold">
                    {data.insertDate
                      ? parseObjectDate(sanitizeDate(data.insertDate))
                      : "-"}
                  </span>
                  <br />
                  <span
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "600",
                      backgroundColor: data.isActive ? "#0CBE13" : "#c82b2b",
                    }}
                    className="px-4 py-0.5 rounded-md relative top-2"
                  >
                    {data.isActive ? "ACTIVO" : "INACTIVO"}
                  </span>
                </div>
                <div style={{ fontWeight: 500, marginBottom: "0.2rem" }}></div>
                <div
                  style={{
                    fontWeight: 500,
                    marginBottom: "1.5rem",
                    opacity: 0.5,
                  }}
                ></div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    aria-label="Secciones de detalle de cliente"
                  >
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Datos de empleado">
                          <FileText
                            color="#565656"
                            size={26}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                      {...tabCommonProps(0)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Documentos adjuntos">
                          <Paperclip
                            color="#565656"
                            size={26}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                      {...tabCommonProps(1)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Calendario de incidencias">
                          <CalendarDays
                            color="#565656"
                            size={26}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                      {...tabCommonProps(2)}
                    />
                    <Tab
                      className="tabsDetalleC"
                      label={
                        <Tooltip title="Calendario de vacaciones">
                          <Luggage
                            color="#565656"
                            size={26}
                            className="m-auto"
                          />
                        </Tooltip>
                      }
                      {...tabCommonProps(3)}
                    />
                  </Tabs>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6} style={{ display: "flex" }}>
          <Grid item xs={6} style={{ padding: "1rem" }}>
            <img src={fotografiaEmpleado} className="w-1/3" />
            <div className="mb-2 text-sesBlue mt-6 font-semibold">
              CARACTERÍSTICAS
            </div>
            <div className="font-regular mb-2 text-sesGray">
              <span className="relative">Sexo: {genders[data.gender]}</span>
              <br />
              <span className="relative">
                Edad: {getAge(data.birthDate)} años
              </span>
              <br />
              <span className="relative">Peso: {data.weight} kg</span>
              <br />
              <span className="relative">Estatura: {data.height}cm</span>
              <br />
            </div>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ padding: "1rem", borderLeft: "solid 2px lightgrey" }}
          >
            <div className="mb-2 text-sesBlue font-semibold">
              DATOS DE CONTACTO
            </div>
            <div className="font-regular mb-2 text-sesGray">
              <span className="relative">
                {[data.name, data.paternalLastName, data.maternalLastName].join(
                  " "
                )}
              </span>
              <br />
              <span className="relative">55 0099 0099</span>
              <br />
            </div>
            <div className="mb-2 mt-6 text-sesBlue font-semibold">
              DATOS DE SUPERVISOR
            </div>
            <div className="font-regular mb-2 text-sesGray">
              <span className="relative">Jean Padilla Palencia</span>
              <br />
              <span className="relative">55 0099 0099</span>
              <br />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <SesTabPanel value={activeTab} index={0}>
        <PersonInfo
          data={data}
          setAllPersonData={setData}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
        />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={1}>
        <PersonDocuments data={data} setAllPersonData={setData} />
      </SesTabPanel>
      <SesTabPanel value={activeTab} index={2}>
        <PersonIncidences data={data} setAllPersonData={setData} />
      </SesTabPanel>
      {/* <SesTabPanel value={activeTab} index={3}>
        <PersonCalendar data={data} setAllPersonData={setData} />
      </SesTabPanel> */}
    </Skinpage>
  );
};

export default PersonDetail;
