import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { FileInput } from "lucide-react";
import { noop } from "../../../helpers/utils";
import Button from "../../../components/Button";
import "./ModalFilterClients.css";

const ModalFilterClients = ({
  isOpen,
  toggle,
  data = [],
  setClients = noop,
  setFilters = noop,
}) => {
  const [dataCopy, setDataCopy] = useState([]);
  const handleClientSelection = (ix) => () => {
    const newC = [...dataCopy];
    const client = newC[ix];
    client.selected = !client.selected;
    setDataCopy(newC);
  };
  const handleFilter = () => {
    const selected = dataCopy.filter((c) => c.selected).map((c) => c.idClient);
    setClients(dataCopy);
    setFilters({ target: { value: selected.join(","), name: "clients" } });
    toggle();
  };
  const handleSelectAll = () => {
    const newC = [...dataCopy].map((c) => ({ ...c, selected: false }));
    setDataCopy(newC);
  };
  useEffect(() => {
    setDataCopy(data);
  }, [data]);
  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader>
        <FileInput color={"#565656"} size={50} className="inline-block" />
        Seleccionar Clientes
      </ModalHeader>
      <ModalBody>
        <div className="grid grid-cols-2">
          {dataCopy.map((cc, ix) => (
            <div
              className={
                "client-row " + (cc.selected ? "client-row-selected" : "")
              }
              onClick={handleClientSelection(ix)}
            >
              <span>{cc.name}</span>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="w-full grid grid-cols-3 gap-4">
          <Button onClick={toggle} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSelectAll} innerClassName="secondaryButton">
            Limpiar Seleccion
          </Button>
          <Button onClick={handleFilter}>Filtrar</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ModalFilterClients.propTypes = {};

export default ModalFilterClients;
