import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "../../../helpers/useForm";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { clientTypes } from "../../../helpers/enums";
import { serviceSchema } from "../../../helpers/schemas";
import { parseCat, noop } from "../../../helpers/utils";
import { Request } from "../../../helpers/api";
import PropTypes from "prop-types";
import DgInput from "../../../components/DgInput";
import DgButton from "../../../components/Button";
import DgForm from "../../../components/DgForm";

const ModalCancel = ({
  isOpen,
  toggle = noop,
  data: serviceData = serviceSchema,
  onConfirm = noop,
}) => {
  const [data, onChange, clearData, setAllData] = useForm({
    ...serviceSchema,
    idCatReasonInvoiceCancellation: "2",
    cancellationComments: "",
  });
  const { serviceConcepts: catConcepts, CatReasonInvoiceCancellation } =
    useSelector((s) => s.catalogues);

  const getFields = () => {
    let fields = [];
    data.serviceConcepts.forEach((sc) => {
      fields = fields.concat([
        {
          label: "Concepto de factura",
          placeholder: "Concepto de factura",
          type: "select",
          options: [
            { value: "", label: "Elige un concepto" },
            ...parseCat(catConcepts, "name", "idCatServiceConcept"),
          ],
          name: "idCatServiceConcept",
          onInputChange: noop,
          required: true,
          disabled: true,
          iconName: "Files",
          containerClassName: "mt-0",
          value: sc.idCatServiceConcept,
          errorMessage: "Éste concepto de factura no es válido",
        },
        {
          label: "Cantidad",
          placeholder: "2",
          minLength: "1",
          maxLength: "2",
          type: "number",
          name: "quantity",
          onInputChange: noop,
          required: false,
          disabled: true,
          iconName: "Hash",
          containerClassName: "mt-0",
          value: sc.quantity,
          errorMessage: "Indique una cantidad en el rango 1 a 99",
        },
        {
          label: "Precio base individual",
          type: "number",
          name: "price",
          step: 0.1,
          required: false,
          disabled: true,
          onInputChange: noop,
          value: sc.price,
          iconName: "DollarSign",
          containerClassName: "mt-0",
        },
        {
          label: "Descuento por faltas",
          type: "number",
          name: "absenceDiscount",
          step: 0.1,
          min: 0,
          placeholder: "-",
          required: true,
          disabled: true,
          onInputChange: noop,
          value: sc.absenceDiscount,
          iconName: "UserMinus",
          containerClassName: "mt-0",
          defaultValue: 0,
        },
        {
          label: "Observaciones",
          type: "text",
          name: "description",
          onInputChange: noop,
          required: false,
          disabled: true,
          value: sc.description,
          iconName: "Files",
          containerClassName: "mt-0",
        },
      ]);
    });
    return [
      {
        groupClassNameGrid: "grid-cols-2",
        fields: [
          {
            label: "Razón social",
            placeholder: "Guardia de seguridad",
            type: "text",
            name: "name",
            value: data.client?.name,
            minLength: "5",
            required: true,
            disabled: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
            errorMessage: "Éste nombre comercial no es válido",
          },
          {
            label: "Nombre comercial del servicio",
            placeholder: "Guardia de seguridad",
            type: "text",
            name: "name",
            value: data.name,
            minLength: "5",
            required: true,
            disabled: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
            errorMessage: "Éste nombre comercial no es válido",
          },
          {
            label: "Tipo de cliente",
            type: "select",
            name: "name",
            options: [
              {
                label: "Todos",
                value: "",
              },
              ...clientTypes,
            ],
            value: data.client?.clientType,
            minLength: "5",
            required: true,
            disabled: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
          },
          {
            label: "Número de factura",
            type: "text",
            name: "invoice",
            value: data.invoice,
            minLength: "5",
            required: true,
            disabled: true,
            iconName: "ClipboardList",
            containerClassName: "mt-0",
          },
        ],
      },
      {
        title: "",
        groupClassNameGrid: "grid-cols-5",
        fields,
      },
    ];
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    onConfirm();
  };
  const fetchData = useCallback(async (idService) => {
    const { ok, data } = await Request("/service/" + idService);
    if (ok) {
      const fetchedService = {
        ...data,
        serviceConcepts: data.serviceConcepts.map((sc) => ({
          ...sc,
          absenceDiscount: sc.absenceDiscount ? sc.absenceDiscount : 0,
        })),
      };
      setAllData(fetchedService);
    }
  }, []);
  useEffect(() => {
    if (serviceData.idService) {
      fetchData(serviceData.idService);
    }
  }, [serviceData]);
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>CANCELAR FACTURA</ModalHeader>
      <ModalBody style={{ padding: "3rem" }}>
        <DgForm
          data={data}
          className="grid"
          classNameGrid="grid grid-cols-2 gap-4"
          onChange={onChange}
          onSubmit={handleSubmit}
          groups={getFields()}
        >
          <div className="grid grid-cols-2">
            <div>
              <DgInput
                type="select"
                label="Motivo de cancelación"
                options={[
                  { value: "", label: "Elige un Motivo" },
                  ...parseCat(
                    CatReasonInvoiceCancellation,
                    "name",
                    "idCatReasonInvoiceCancellation"
                  ),
                ]}
                name="idCatReasonInvoiceCancellation"
                value={data.idCatReasonInvoiceCancellation}
                onChange={onChange}
                required={true}
                iconName={"XCircle"}
              />
              <DgInput
                type="textarea"
                label="Comentarios u observaciones de cancelación"
                placeholder={"..."}
                name="cancellationComments"
                value={data.cancellationComments}
                onChange={onChange}
                iconName={"XCircle"}
              />
            </div>
          </div>
          <ModalFooter>
            <div className="w-full grid grid-cols-2">
              <div />
              <div className="grid grid-cols-2 gap-3">
                <DgButton type="submit" color="primary">
                  Cancelar Factura
                </DgButton>
                <DgButton onClick={toggle} color="secondary">
                  Cerrar
                </DgButton>
              </div>
            </div>
          </ModalFooter>
        </DgForm>
      </ModalBody>
    </Modal>
  );
};

ModalCancel.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  invice: PropTypes.object,
};

export default ModalCancel;
