import { enumPaymentMethods, rolesCat } from "./enums";
import municipalitiesCat from "./statesMunicipalities.json";
import { parseCat } from "./utils";

export const invoiceInputs = (data, handlers, catalogues) => {
  const isFiscal = data.clientType === "FISCAL";
  const { onInputChange = null } = handlers;
  const { CatCfdi, CatPaymentWay, CatRegime } = catalogues;
  return [
    {
      label: "Uso de CFDI",
      type: "select",
      name: "idCatCfdi",
      onInputChange,
      required: isFiscal,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatCfdi, "name", "idCatCfdi"),
      ],
      iconName: "FormInput",
    },
    {
      label: "Forma de pago",
      type: "select",
      name: "idCatPaymentWay",
      onInputChange,
      required: isFiscal,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatPaymentWay, "name", "idCatPaymentWay"),
      ],
      iconName: "FormInput",
    },
    {
      label: "Método de pago",
      type: "select",
      name: "paymentMethod",
      onInputChange,
      required: isFiscal,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...enumPaymentMethods,
      ],
      iconName: "FormInput",
    },
    {
      label: "Régimen",
      type: "select",
      name: "idCatRegime",
      onInputChange,
      required: isFiscal,
      options: [
        { value: "", label: "Selecciona un elemento" },
        ...parseCat(CatRegime, "name", "idCatRegime"),
      ],
      iconName: "FormInput",
    },
  ];
};

export const addressInputs = (data, suburbs, fieldPostfix = "") => {
  const isFiscal = data.clientType === "FISCAL";
  const municipalities = (
    municipalitiesCat[data["state" + fieldPostfix]] || []
  ).map((m) => ({
    value: m,
    label: m,
  }));
  return [
    {
      label: "Calle",
      placeholder: "Av General",
      type: "text",
      name: "street" + fieldPostfix,
      maxLength: "150",
      minLength: "1",
      required: isFiscal,
      iconName: "FormInput",
    },
    {
      label: "Código postal",
      placeholder: "00000",
      type: "text",
      maxLength: 6,
      minLength: 5,
      pattern: "^[0-9]{5-6}",
      name: "postalCode" + fieldPostfix,
      required: isFiscal,
      iconName: "FormInput",
    },
    {
      label: "Núm Ext",
      placeholder: "123",
      type: "text",
      name: "outdoorNumber" + fieldPostfix,
      maxLength: "20",
      minLength: "1",
      required: isFiscal,
      iconName: "FormInput",
    },
    {
      label: "Núm Int",
      placeholder: "",
      type: "text",
      name: "internalNumber" + fieldPostfix,
      maxLength: "20",
      minLength: "1",
      required: false,
      iconName: "FormInput",
    },
    {
      label: "Colonia",
      placeholder: "-",
      type: suburbs?.length > 0 ? "select" : "text",
      name: "suburb" + fieldPostfix,
      options: [{ label: "Elige una colonia", value: "" }, ...suburbs],
      required: isFiscal,
      disabled: data.postalCode === "",
      iconName: "FormInput",
    },
    {
      label: "Municipio",
      placeholder: "-",
      type: municipalities?.length > 0 ? "select" : "text",
      name: "municipality" + fieldPostfix,
      options: [{ label: "Elige un municipio", value: "" }, ...municipalities],
      required: isFiscal,
      disabled: data.postalCode === "",
      iconName: "FormInput",
    },
    {
      label: "Estado",
      placeholder: "-",
      type: "text",
      name: "state" + fieldPostfix,
      required: isFiscal,
      disabled: true,
      iconName: "FormInput",
    },
    {
      label: "País",
      type: "select",
      options: [
        {
          label: "México",
          value: "MX",
        },
      ],
      value: "MX",
      name: "country" + fieldPostfix,
      required: isFiscal,
      disabled: true,
      iconName: "FormInput",
    },
  ];
};

export const clientInputs = (data, handlers, catalogues) => {
  const { handlePhoneChange, onInputChange = null } = handlers;
  const { suburbs } = catalogues;
  const isFiscal = data.clientType === "FISCAL";
  return [
    {
      title: "DATOS DE CLIENTE",
      fields: [
        {
          type: "radio",
          name: "clientType",
          options: [
            { value: "FISCAL", label: "Cliente fiscal" },
            { value: "NONFISCAL", label: "Cliente no fiscal" },
          ],
          value: data.clientType,
          onInputChange,
          containerClassName: "col-span-2",
        },
        {
          label: "Razón social",
          placeholder: "Seguritas S.A. de C.V",
          type: "text",
          name: "name",
          onInputChange,
          minLength: "2",
          required: true,
          iconName: "FormInput",
          errorMessage: "Ésta razón social no es válida",
        },
        {
          label: "RFC",
          placeholder: "SRTAS2S1S",
          type: "text",
          maxLength: 13,
          minLength: 12,
          name: "rfc",
          onInputChange,
          required: isFiscal,
          iconName: "FormInput",
        },
        {
          label: "Teléfono",
          placeholder: "XX-XXXX-XXXX",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          minLength: "12",
          maxLength: "12",
          type: "tel",
          name: "phone",
          onInputChange: handlePhoneChange,
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato 55-5555-5555",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "email",
          onInputChange,
          required: false,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DATOS DEL ADMINISTRADOR",
      fields: [
        {
          label: "Nombre",
          placeholder: "Juan Pérez",
          type: "text",
          name: "administratorName",
          onInputChange,
          maxLength: "150",
          minLength: "2",
          //required: true,
          iconName: "FormInput",
        },
        {
          label: "Teléfono",
          placeholder: "XX-XXXX-XXXX",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          minLength: "12",
          maxLength: "12",
          type: "tel",
          name: "administratorPhone",
          onInputChange: handlePhoneChange,
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato 55-5555-5555",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          onInputChange,
          name: "administratorEmail",
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DATOS DEL CONTADOR O RESPONSABLE DE PAGO",
      fields: [
        {
          label: "Nombre",
          placeholder: "Juan Pérez",
          type: "text",
          name: "counterName",
          onInputChange,
          maxLength: "150",
          minLength: "2",
          //required: true,
          iconName: "FormInput",
        },
        {
          label: "Correo electrónico SES",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "counterEmail",
          onInputChange,
          //required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
      ],
    },
    {
      title: "DOMICILIO FISCAL",
      fields: addressInputs(data, suburbs),
    },
    {
      title: "FACTURACIÓN",
      fields: invoiceInputs(data, handlers, catalogues),
    },
  ];
};

export const userInputs = (data, handlers, catalogues) => {
  const { CatRole } = catalogues;
  const { onPasswordConfirmChange, onSelectFile, onRoleChange } = handlers;
  return [
    {
      title: "DATOS DEL USUARIO",
      fields: [
        {
          label: "Imagen de Perfil",
          type: "file",
          name: "selectedFile",
          onInputChange: onSelectFile,
          value: data.selectedFile,
          required: true,
          accept: "image/png, image/gif, image/jpeg",
          containerClassName: "col-span-2",
        },
        {
          label: "Nombre",
          placeholder: "Nombre",
          type: "text",
          name: "name",
          minLength: "10",
          required: true,
          iconName: "FormInput",
        },
        {
          label: "Rol",
          type: "select",
          name: "idCatRole",
          options: [
            { label: "Selecciona un rol", value: "" },
            ...parseCat(CatRole, "name", "idCatRole"),
          ],
          onInputChange: onRoleChange,
          required: true,
          iconName: "FormInput",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "email",
          required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
        {
          label: "Contraseña",
          placeholder: "Contraseña",
          type: "password",
          name: "password",
          pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,12}$",
          required: true,
          maxLength: 12,
          minLength: 6,
          iconName: "FormInput",
        },
        {
          label: "Confirmar contraseña",
          placeholder: "Confirmar contraseña",
          type: "password",
          name: "passwordConfirm",
          onInputChange: onPasswordConfirmChange,
          required: true,
          iconName: "FormInput",
          errorMessage: "Las contraseñas no coinciden",
        },
      ],
    },
  ];
};

export const editUserInputs = (data, handlers, catalogues) => {
  const onInputChange = handlers;
  return [
    {
      fields: [
        {
          label: "Nombre",
          placeholder: "Nombre",
          type: "text",
          name: "userName",
          onInputChange,
          minLength: "10",
          required: true,
          iconName: "FormInput",
        },
        {
          label: "Rol",
          type: "select",
          name: "userRole",
          onInputChange,
          options: [
            { label: "Selecciona un rol", value: 1 },
            { label: "Cobranza y CRM", value: 2 },
            { label: "Facturación", value: 3 },
            { label: "RH y Nómina", value: 4 },
            { label: "Administración", value: 5 },
            { label: "Personalizado", value: 6 },
          ],
          required: true,
          iconName: "FormInput",
        },
        {
          label: "Correo electrónico",
          placeholder: "nombre@ses.com",
          type: "email",
          name: "userEmail",
          onInputChange,
          required: true,
          iconName: "FormInput",
          errorMessage: "Campo requerido en formato correo@dominio.com",
        },
        {
          label: "Contraseña",
          placeholder: "Contraseña",
          type: "password",
          name: "userPassword",
          onInputChange,
          required: true,
          iconName: "FormInput",
        },
      ],
    },
  ];
};

export const personInputsGeneral = (data, handlers, catalogues) => {
  return [
    {
      title: "",
      fields: [
        {
          label: "Nombre(s)",
          placeholder: "Mateo",
          type: "text",
          name: "name",
          minLength: "2",
          required: true,
          iconName: "User",
        },
        {
          label: "Apellido Paterno",
          placeholder: "Villamil",
          type: "text",
          name: "paternalLastName",
          minLength: "2",
          required: true,
          iconName: "User",
        },
        {
          label: "Apellido Materno",
          placeholder: "Flores",
          type: "text",
          name: "maternalLastName",
          minLength: "2",
          required: true,
          iconName: "User",
        },
        {
          label: "Clave de empleado",
          placeholder: "0021193",
          type: "text",
          name: "employeeKey",
          minLength: "2",
          required: true,
          iconName: "CreditCard",
        },
        {
          label: "Fecha de nacimiento",
          placeholder: "25/07/1978",
          type: "date",
          name: "birthDate",
          required: true,
          iconName: "Calendar",
        },
        {
          label: "Sexo",
          type: "select",
          name: "gender",
          options: [
            { label: "Selecciona el género", value: "" },
            { label: "Masculino", value: "MALE" },
            { label: "Femenino", value: "FEMALE" },
            { label: "Otro", value: "OTHER" },
          ],
          required: true,
          iconName: "User",
        },
        {
          label: "Teléfono",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          placeholder: "55-0000-0000",
          type: "tel",
          name: "phone",
          minLength: "12",
          maxLength: "12",
          required: true,
          iconName: "Phone",
        },
        {
          label: "Celular o teléfono para recados",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          placeholder: "55-0000-0000",
          type: "tel",
          name: "secondaryPhone",
          minLength: "12",
          maxLength: "12",
          required: false,
          iconName: "Phone",
        },
        {
          label: "Rol de empleado",
          type: "select",
          name: "role",
          options: [{ label: "Selecciona el rol", value: "" }, ...rolesCat],
          required: true,
          iconName: "User",
        },
        {
          label: "Fecha de absorción",
          placeholder: "25/07/1978",
          type: "date",
          name: "absorptionDate",
          required: false,
          iconName: "Calendar",
        },
      ],
    },
  ];
};

export const personInputsSecondary = (data, handlers, catalogues) => {
  const { CatMaritalStatus, CatEducationalLevel } = catalogues;
  return [
    {
      title: "",
      fields: [
        {
          label: "Lugar de nacimiento",
          placeholder: "Ameca",
          type: "text",
          name: "birthPlace",
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Peso (kg)",
          placeholder: "82",
          type: "number",
          step: 0.1,
          name: "weight",
          min: 0.1,
          max: 250,
          required: true,
          iconName: "User",
        },
        {
          label: "Estatura (cm)",
          placeholder: "179",
          type: "number",
          name: "height",
          step: 1,
          min: "1",
          max: 250,
          required: true,
          iconName: "User",
        },
        {
          label: "Escolaridad",
          type: "select",
          name: "idCatEducationalLevel",
          options: [
            { label: "Elige un valor", value: "" },
            ...parseCat(CatEducationalLevel, "name", "idCatEducationalLevel"),
          ],
          required: true,
          iconName: "User",
        },
        {
          label: "Estado civil",
          type: "select",
          name: "idCatMaritalStatus",
          options: [
            { label: "Elige un valor", value: "" },
            ...parseCat(CatMaritalStatus, "name", "idCatMaritalStatus"),
          ],
          required: true,
          iconName: "User",
        },
        {
          label: "Fecha de anti-doping",
          placeholder: "25/09/2022",
          type: "date",
          name: "antidopingDate",
          required: true,
          iconName: "Calendar",
        },
        {
          label: "Fecha de ingreso",
          placeholder: "25/09/2022",
          type: "date",
          name: "admissionDate",
          required: true,
          iconName: "Calendar",
        },
      ],
    },
  ];
};

export const personInputsAddress = (data, handlers, catalogues) => {
  return [
    {
      title: "",
      fields: [
        {
          label: "Calle",
          placeholder: "Av General",
          type: "text",
          name: "street",
          maxLength: "150",
          minLength: "1",
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Código postal",
          placeholder: "00000",
          type: "text",
          maxLength: 6,
          minLength: 5,
          pattern: "^[0-9]{5-6}",
          name: "postalCode",
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Núm Ext",
          placeholder: "123",
          type: "text",
          name: "outdoorNumber",
          maxLength: "20",
          minLength: "1",
          iconName: "MapPin",
        },
        {
          label: "Núm Int",
          placeholder: "2-A",
          type: "text",
          name: "internalNumber",
          maxLength: "20",
          minLength: "1",
          required: false,
          iconName: "MapPin",
        },
        {
          label: "Colonia",
          placeholder: "Álamos",
          type: "text",
          name: "suburb",
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Municipio o Delegación",
          placeholder: "Elige un municipio",
          type: "text", //LUEGO PASA A SELECT, SOLUCIÓN PROVISORIA
          name: "municipality",
          options: [{ label: "Elige un municipio", value: "Ameca" }],
          required: true,
          iconName: "MapPin",
        },
        {
          label: "Estado",
          placeholder: "Elige un Estado",
          type: "text", //LUEGO PASA A SELECT, SOLUCIÓN PROVISORIA
          name: "state",
          options: [{ label: "Elige un Estado", value: "Jalisco" }],
          required: true,
          iconName: "MapPin",
        },
      ],
    },
  ];
};

export const personInputsDocuments = (data, handlers, catalogues) => {
  const { bank } = catalogues;
  return [
    {
      title: "",
      fields: [
        {
          label: "RFC",
          placeholder: "VIFM780725JK5",
          type: "text",
          name: "rfc",
          minLength: 10,
          maxLength: 13,
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "CURP",
          placeholder: "VIFM39388WKE91N01",
          type: "text",
          name: "curp",
          minLength: 16,
          maxLength: 18,
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Número de IMSS",
          placeholder: "928100930019",
          type: "text",
          name: "imssNumber",
          minLength: 10,
          maxLength: 11,
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Actualmente en observación de IMSS",
          type: "select",
          name: "imssComments",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "No", value: "No" },
            { label: "Sí", value: "Si" },
          ],
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "INFONAVIT",
          type: "select",
          name: "hasInfonavit",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "No", value: 1 },
            { label: "Sí", value: 2 },
          ],
          required: true,
          iconName: "CreditCard",
        },
        {
          label: "Pensión alimenticia",
          type: "select",
          name: "givesAlimony",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "No", value: 1 },
            { label: "Sí", value: 2 },
          ],
          required: true,
          iconName: "CreditCard",
        },
        {
          label: "Número de Servicio Militar",
          placeholder: "B-83910039",
          type: "text",
          name: "militarService",
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Número de Credencial de Elector",
          placeholder: "41393020019",
          type: "text",
          name: "votingLisenseNumber",
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Tipo de comprobante de domicilio",
          type: "select",
          name: "idCatProofResidence",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "Agua", value: 1 },
            { label: "Luz", value: 2 },
            { label: "Gas", value: 3 },
            { label: "Otro", value: 4 },
          ],
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Institución bancaria",
          type: "select",
          name: "idBank",
          options: [
            { label: "Seleccione institución bancaria", value: 1 },
            ...parseCat(bank, "name", "idBank"),
          ],
          required: false,
          iconName: "CreditCard",
        },
        {
          label: "Número de tarjeta de nómina",
          placeholder: "0000 0000 0000 0000",
          type: "text",
          name: "cardNumber",
          required: false,
          minLength: 16,
          maxLength: 16,
          iconName: "CreditCard",
        },
        {
          label: "CLABE interbancaria",
          placeholder: "0000 0000 0000 000000",
          type: "text",
          name: "interbankKey",
          required: false,
          minLength: 18,
          maxLength: 18,
          iconName: "CreditCard",
        },
        {
          label: "Entregó expediente físico",
          type: "select",
          name: "file",
          options: [
            { label: "Selecciona un elemento", value: "" },
            { label: "Sí", value: true },
            { label: "No", value: false },
          ],
          required: false,
          iconName: "FileCheck",
        },
        {
          label: "Sueldo neto quincenal",
          type: "number",
          name: "biweeklyNetSalary",
          min: 1,
          step: 1,
          required: true,
          iconName: "CreditCard",
        },
      ],
    },
  ];
};

export const personInputsEmergencyContact = (data, handlers, catalogues) => {
  return [
    {
      title: "",
      fields: [
        {
          label: "Nombre del contacto",
          placeholder: "Brandon",
          type: "text",
          name: "name",
          required: true,
          iconName: "User",
        },
        {
          label: "Apellido paterno del contacto",
          placeholder: "Flores",
          type: "text",
          name: "paternalLastName",
          required: true,
          iconName: "User",
        },
        {
          label: "Apellido materno del contacto",
          placeholder: "Martínez",
          type: "text",
          name: "maternalLastName",
          required: true,
          iconName: "User",
        },
        {
          label: "Teléfono del contacto",
          pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
          placeholder: "55-0000-0000",
          type: "tel",
          name: "phone",
          minLength: "12",
          maxLength: "12",
          required: true,
          iconName: "Phone",
        },
        {
          label: "Parentesco",
          type: "select",
          name: "relationship",
          options: [
            { label: "Selecciona parentesco", value: "" },
            { label: "Madre", value: "Madre" },
            { label: "Padre", value: "Padre" },
            { label: "Cónyuge", value: "Cónyuge" },
            { label: "Hijo", value: "Hijo" },
            { label: "Hija", value: "Hija" },
            { label: "Otro", value: "Otro" },
          ],
          required: true,
          iconName: "User",
        },
      ],
    },
  ];
};

{
  /*-------------READ ONLY----------------

Eliminar al conectar datos reales

*/
}

export const personInputsGeneralReadOnly = (data, handlers, catalogues) => {
  const onInputChange = handlers;

  return {
    title: "",
    fields: [
      {
        label: "Nombres",
        placeholder: "Mateo",
        type: "text",
        name: "personNames",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Apellido Paterno",
        placeholder: "Villamil",
        type: "text",
        name: "fatherLastName",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Apellido Materno",
        placeholder: "Flores",
        type: "text",
        name: "motherLastName",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Clave de empleado",
        placeholder: "0021193",
        type: "text",
        name: "employeeNumber",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Fecha de nacimiento",
        placeholder: "25/07/1978",
        type: "date",
        name: "dateOfBirth",
        onInputChange,
        required: true,
        iconName: "Calendar",
        disabled: "true",
      },
      {
        label: "Sexo",
        type: "select",
        name: "gender",
        onInputChange,
        options: [
          { label: "Masculino", value: 1 },
          { label: "Femenino", value: 2 },
          { label: "Otro", value: 3 },
        ],
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Teléfono",
        pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
        placeholder: "55-0000-0000",
        type: "tel",
        name: "phoneNumber",
        onInputChange,
        minLength: "12",
        maxLength: "12",
        required: true,
        iconName: "Phone",
        disabled: "true",
      },
      {
        label: "Celular o teléfono para recados",
        pattern: "[0-9]{2}-[0-9]{4}-[0-9]{4}",
        placeholder: "55-0000-0000",
        type: "tel",
        name: "secondPhoneNumber",
        onInputChange,
        minLength: "12",
        maxLength: "12",
        required: true,
        iconName: "Phone",
        disabled: "true",
      },
    ],
  };
};

export const personInputsSecondaryReadOnly = (data, handlers, catalogues) => {
  const onInputChange = handlers;

  return {
    title: "",
    fields: [
      {
        label: "Edad",
        placeholder: "44",
        type: "number",
        name: "age",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Lugar de nacimiento",
        placeholder: "Ameca",
        type: "text",
        name: "placeOfBirth",
        onInputChange,
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Peso (kg)",
        placeholder: "82",
        type: "number",
        name: "weight",
        onInputChange,
        minLength: "2",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Estatura (cm)",
        placeholder: "179",
        type: "number",
        name: "height",
        onInputChange,
        minLength: "3",
        required: true,
        iconName: "User",
        disabled: "true",
      },
      {
        label: "Escolaridad",
        type: "select",
        name: "education",
        onInputChange,
        options: [
          { label: "Primaria", value: 1 },
          { label: "Secundaria", value: 2 },
          { label: "Bachillerato", value: 3 },
          { label: "Técnico", value: 4 },
          { label: "Superior", value: 5 },
          { label: "Otro", value: 6 },
        ],
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Estado civil",
        type: "select",
        name: "maritalStatus",
        onInputChange,
        options: [
          { label: "Soltero", value: 1 },
          { label: "Casado", value: 2 },
          { label: "Divorciado", value: 3 },
          { label: "Viudo", value: 4 },
          { label: "Otro", value: 5 },
        ],
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Fecha de anti-doping",
        placeholder: "25/09/2022",
        type: "date",
        name: "antidopingTestDate",
        onInputChange,
        required: true,
        iconName: "Calendar",
        disabled: "true",
      },
    ],
  };
};

export const personInputsAddressReadOnly = (data, handlers, catalogues) => {
  const onInputChange = handlers;

  return {
    title: "",
    fields: [
      {
        label: "Calle",
        placeholder: "Av General",
        type: "text",
        name: "street",
        maxLength: "150",
        minLength: "1",
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Código postal",
        placeholder: "00000",
        type: "text",
        maxLength: 6,
        minLength: 5,
        pattern: "^[0-9]{5-6}",
        name: "postalCode",
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Núm Ext",
        placeholder: "123",
        type: "text",
        name: "outdoorNumber",
        maxLength: "20",
        minLength: "1",
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Núm Int",
        placeholder: "2-A",
        type: "text",
        name: "internalNumber",
        maxLength: "20",
        minLength: "1",
        required: false,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Colonia",
        placeholder: "Álamos",
        type: "text",
        name: "suburb",
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Municipio o Delegación",
        placeholder: "Elige un municipio",
        type: "select",
        name: "municipality",
        options: [{ label: "Elige un municipio", value: "Ameca" }],
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
      {
        label: "Estado",
        placeholder: "Elige un Estado",
        type: "select",
        name: "state",
        options: [{ label: "Elige un Estado", value: "Jalisco" }],
        required: true,
        iconName: "MapPin",
        disabled: "true",
      },
    ],
  };
};

export const personInputsDocumentsReadOnly = (data, handlers, catalogues) => {
  const onInputChange = handlers;

  return {
    title: "",
    fields: [
      {
        label: "RFC",
        placeholder: "VIFM780725JK5",
        type: "text",
        name: "rfc",
        onInputChange,
        minLength: "10",
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "CURP",
        placeholder: "VIFM39388WKE91N01",
        type: "text",
        name: "curp",
        onInputChange,
        minLength: "16",
        maxLength: "16",
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Número de IMSS",
        placeholder: "928100930019",
        type: "text",
        name: "imss",
        onInputChange,
        minLength: "12",
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Actualmente en observación de IMSS",
        type: "select",
        name: "medicalObservation",
        onInputChange,
        options: [
          { label: "No", value: 1 },
          { label: "Sí", value: 2 },
        ],
        required: false,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "INFONAVIT",
        type: "select",
        name: "infonavit",
        onInputChange,
        options: [
          { label: "No", value: 1 },
          { label: "Sí", value: 2 },
        ],
        required: false,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Pensión alimenticia",
        type: "select",
        name: "alimony",
        onInputChange,
        options: [
          { label: "No", value: 1 },
          { label: "Sí", value: 2 },
        ],
        required: false,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Número de Servicio Militar",
        placeholder: "B-83910039",
        type: "text",
        name: "militarService",
        onInputChange,
        required: false,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Número de Credencial de Elector",
        placeholder: "41393020019",
        type: "text",
        name: "officialIdentification",
        onInputChange,
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Tipo de comprobante de domicilio",
        type: "select",
        name: "proofOfResidency",
        onInputChange,
        options: [
          { label: "Agua", value: 1 },
          { label: "Luz", value: 2 },
          { label: "Gas", value: 3 },
          { label: "Otro", value: 4 },
        ],
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Institución bancaria",
        type: "select",
        name: "bank",
        onInputChange,
        options: [
          { label: "Seleccione institución bancaria", value: 1 },
          { label: "BBVA", value: 2 },
          { label: "Banbajío", value: 3 },
          { label: "Citibanamex", value: 4 },
        ],
        required: true,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Número de tarjeta de nómina",
        placeholder: "0000 0000 0000 0000",
        type: "text",
        name: "cardNumter",
        onInputChange,
        required: true,
        minLength: 16,
        maxLength: 16,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "CLABE interbancaria",
        placeholder: "0000 0000 0000 000000",
        type: "text",
        name: "clabe",
        onInputChange,
        required: true,
        minLength: 18,
        maxLength: 18,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Sueldo neto quincenal",
        placeholder: "5000",
        type: "number",
        name: "netIncome",
        onInputChange,
        required: true,
        minLength: 4,
        iconName: "CreditCard",
        disabled: "true",
      },
      {
        label: "Entregó expediente físico",
        type: "select",
        name: "file",
        onInputChange,
        options: [
          { label: "Sí", value: 1 },
          { label: "No", value: 2 },
        ],
        required: true,
        iconName: "FileCheck",
        disabled: "true",
      },
    ],
  };
};
