import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Plus } from "lucide-react";
import { pdfjs } from "react-pdf";
import { enumFileTypes } from "../../../helpers/enums";
import { Request } from "../../../helpers/api";
import Swal from "sweetalert2";
import iconoDetalleEmpleado from "../../../css/img/IconoDetalleEmpleado.svg";
import DgButton from "../../../components/Button";
import DgInput from "../../../components/DgInput";
import DocumentCard from "../../../components/DocumentCard";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ModalAttachedFiles = ({
  isOpen,
  toggle = {},
  data: personData,
  fetchPersonData = {},
}) => {
  const { idEmployee } = personData;
  const [search, setSearch] = useState("");
  const [data, setData] = useState({});
  const [singleData, setSingleData] = useState({});
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };
  const [file, setFile] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const docHandlers = {
    handleDetail: (doc) => {
      setSingleData(doc);
    },
    handleDelete: (doc) => {
      const { idEmployeeDocument } = doc;
      Swal.fire({
        title: "¿Desas cancelar éste documento?",
        text: "Ésta acción es irreversible",
        icon: "warning",
        showCancelButton: true,
      }).then(async ({ isConfirmed }) => {
        if (isConfirmed) {
          const res = await Request(
            `/employee/document/${idEmployeeDocument}`,
            {idEmployeeDocument},
            "PATCH"
          );
          if (res.ok) {
            Swal.fire("Documento cancelado", "", "success").then(() => {
              setFile(null);
              fetchData();
              setSingleData({});
            });
          }
        }
      });
    },
  };

  const handleFileSelected = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setFile(file);
    setSingleData({
      employeeDocumentType: "NUEVO DOCUMENTO",
      url,
      description: file.name,
    });
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("idCatEmployeeDocumentType", 4);
    const res = await Request(
      "/employee/" + idEmployee + "/document",
      formData,
      "POST",
      true,
      true
    );
    if (res.ok) {
      Swal.fire("Documento cargado exitosamente", "", "success").then(() => {
        fetchData();
        setFile(null);
      });
    }
  };

  const fetchData = async () => {
    if (idEmployee) {
      const res = await Request("/employee/" + idEmployee);
      if (res.ok) {
        setData(res.data);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen, personData]);

  let documents = data.employeeDocuments || [];

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} className="text-center justify-center">
        <div className="text-xl text-sesGray ml-2">ADJUNTAR ARCHIVOS</div>
      </ModalHeader>
      <ModalBody
        style={{ padding: "1.5rem", overflowY: "auto", maxHeight: "520px" }}
      >
        <div>
          <div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={iconoDetalleEmpleado} style={{ width: "3.5rem" }} />
              <div
                style={{
                  marginLeft: "1rem",
                  fontWeight: 600,
                  color: "#2A2F99",
                }}
              >
                {personData.name}
                <br />
                {personData.employeeKey}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-5 gap-y-0 mt-4">
            <div>
              <div className="ml-2 text-sesBlue font-semibold">
                LISTA DE ARCHIVOS
              </div>
              <DgInput
                type="text"
                value={search}
                placeholder="Buscar archivo"
                name="searchFile"
                onChange={handleSearch}
                iconName={"Search"}
              />
              {documents.length > 0 ? (
                documents.map((doc, ix) => (
                  <DocumentCard
                    document={{ ...doc, name: doc.employeeDocumentType }}
                    handlers={docHandlers}
                    ix={ix}
                  />
                ))
              ) : (
                <i>No hay documentos para mostrar</i>
              )}
              <div className="text-sesBlue text-left text-base p-2 font-semibold h-9 mt-2">
                <input
                  type="file"
                  className="fileLinkModal cursor-pointer"
                  onChange={handleFileSelected}
                  accept={enumFileTypes.reduce(
                    (prev, curr) => prev + "." + curr.toLowerCase() + ",",
                    ""
                  )}
                />
                <div className="textFileModal">
                  <div className="w-6 h-6 rounded-md bg-sesBlue relative float-left mr-2 cursor-pointer">
                    <Plus
                      color="#FFFFFF"
                      size={22}
                      style={{ cursor: "pointer" }}
                      className="m-auto"
                    />
                  </div>
                  Adjuntar otro archivo
                </div>
              </div>
            </div>
            <div>
              <div className="ml-2 text-sesBlue font-semibold mb-2">
                {singleData.employeeDocumentType}
                <p className="font-normal text-sesGray italic">
                  {singleData.name}
                </p>
              </div>
              <embed
                className="w-full"
                height={400}
                src={singleData.url}
                type="application/pdf"
              />
              {file && (
                <DgButton
                  onClick={handleUpload}
                  innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
                >
                  Subir Archivo
                </DgButton>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="flex space-between w-full">
          <DgButton
            type="submit"
            color="secondary"
            onClick={toggle}
            innerClassName="px-4 py-2 font-regular w-3/4 ml-2"
          >
            Cancelar
          </DgButton>
          <DgButton
            onClick={toggle}
            innerClassName="px-4 py-2 font-regular w-3/4 relative float-right"
          >
            Aceptar
          </DgButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

ModalAttachedFiles.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalAttachedFiles;
