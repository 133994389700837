import React from "react";
import PropTypes from "prop-types";
import { useForm } from "../../../helpers/useForm";
import { getColumnsDeposits } from "../../../helpers/datatableHelpers";
import { noop } from "../../../helpers/utils";
import { Request } from "../../../helpers/api";
import Swal from "sweetalert2";
import DgInput from "../../../components/DgInput";
import Button from "../../../components/Button";
import DgTable from "../../../components/DgTable";

const ClientDeposit = ({ clientData = {}, fetchData = noop }) => {
  const [data, setData, clearData, setAllData] = useForm({
    amount: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await Request(
      "/client/" + clientData.idClient + "/payment",
      data,
      "POST"
    );
    if (res.ok) {
      Swal.fire("Pago registrado", "", "success").then(() => {
        fetchData();
        setAllData({ amount: "" });
      });
    }
  };
  const dtFuncs = {
    delete: (row) => async () => {
      const { idClientPayment } = row;
      const { ok } = await Request(
        "/client/payment/" + idClientPayment,
        {},
        "PATCH"
      );
      if (ok) {
        Swal.fire({
          title: "Pago cancelado con éxito",
          text: "",
          icon: "success",
        }).then(() => {
          fetchData();
        });
      }
    },
  };
  return (
    <div className="w-full m-auto">
      <div className="titulosDetalle" style={{ marginBottom: "1rem" }}>
        NUEVO DEPÓSITO
      </div>
      <div className="grid grid-cols-2 gap-4">
        <form onSubmit={handleSubmit}>
          <DgInput
            type="number"
            min={0.01}
            step={0.01}
            name="amount"
            label="Monto del depósito"
            placeholder="$"
            required={true}
            iconName="Landmark"
            onChange={setData}
            value={data.amount}
          />
          <Button type="submit"> Enviar </Button>
        </form>
        <div>
          <div className="titulosDetalle" style={{ marginBottom: "1rem" }}>
            DEPÓSITOS
          </div>
          <DgTable
            columnsDef={getColumnsDeposits(dtFuncs)}
            data={clientData.clientPayments}
          />
        </div>
      </div>
    </div>
  );
};

ClientDeposit.propTypes = {};

export default ClientDeposit;
